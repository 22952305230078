<template>
  <page-layout class="contents_editor">
    <sub-page-layout>

      <!-- 에디터 소개 -->
      <div class="editor_info box_basic">
	      <p class="img img_wrap"><img :src="getFileExist(writer.prof_img)" class="fitimg"></p>
				<p class="name">{{writer.nick}}</p>
				<p class="slogan">{{writer.slogan}}</p>
				<p class="count"><b>{{writer.post_cnt}}</b> Articles</p>
      </div>

      <!-- 컨텐츠 리스트 -->
      <div class="lst_area">
        <!--<div class="lst_top">
          <p class="by">Articles by <b>{{writer.nick}}</b></p>
          <div class="search_area">
            <a class="sch_open" @click="setSearchVisible">검색창 열기</a>
            <div class="sch_box" v-if="searchVisible">
              <input type="text" placeholder="Search" v-model.trim="keyword" ref="keyword" @keyup.enter="setSearchVisible" @keydown.esc="setSearchVisible" @blur="setSearchVisible">
            </div>
          </div>
        </div>-->
        <ul>
          <li v-for="(post, index) in contentsData" :key="index">
            <post-container :post="post"></post-container>
          </li>
        </ul>
        <a class="lst_no" v-if="contentsData.length < 1" @click="$refs.keyword.focus(); $refs.keyword.select()">검색 결과를 찾을 수 없습니다.</a>
        <a class="lst_more" v-show="!finished" :disabled="loading" @click="getMoreContentsInCate()">콘텐츠 더 보기<i>&#8735;</i></a>
      </div>

    </sub-page-layout>
  </page-layout>
</template>

<script>
  import {Loading} from 'element-ui'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import PostContainer from '../components/PostContainer'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    name: "ContentsEditor",
    components: {
      PageLayout,
      SubPageLayout,
      PostContainer,
    },
    metaInfo () {
      return {
        title: `${this.getAppName}: ${this.getWriterNick} Editor, ${this.getArticleCnt} Articles`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: this.getWriterSlogan},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', propert: 'og:title', content: `${this.getWriterNick} Editor, ${this.getArticleCnt} Articles | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: this.getWriterSlogan},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        writer: {},
        contentsData: [{},{},{},{},{}],
        finished: false,
        loading: true,
        searchVisible: false,
        keyword: '',
        checksum: 0,
        p: 1
      }
    },
    created () {
      this.getPostWriter()
      this.getContentsInCate()
    },
    computed: {
      ...CommonComputed,
      getWriterNick () {
        return this.writer && this.writer.nick ? this.writer.nick : ''
      },
      getWriterSlogan () {
        return this.writer && this.writer.slogan ? this.writer.slogan : ''
      },
      getArticleCnt () {
        return this.writer && this.writer.post_cnt ? this.writer.post_cnt : ''
      }
    },
    methods: {
      ...CommonMethods,
      getPostWriter () {
        const idx = this.$route.params.idx
        this.$axios.get('/members/' + idx).then(rs => {
          this.writer = rs.data
        }).catch(() => {})
      },
      getContentsInCate () {
        let loading = false
        if (this.contentsData.length > 0) {
          loading = Loading.service({
            target: document.querySelector('#lst'),
            background: 'rgba(255, 255, 255, 0.5)'
          })
        }
        this.loading = true
        const params = {params: {p: this.p, writer: this.$route.params.idx}}
        if (this.keyword) params.params.keyword = this.keyword
        this.$axios.get('/posts/search', params).then(rs => {
          this.contentsData = rs.data
          if (rs.data.length < 10) this.finished = true
          this.loading = false
          if (this.keyword) this.checksum ++
          if (loading) loading.close()
        }).catch(() => {
          if (loading) loading.close()
        })
      },
      getMoreContentsInCate () {
        this.loading = true
        this.p++
        const params = {params: {p: this.p, writer: this.$route.params.idx}}
        if (this.keyword) params.params.keyword = this.keyword
        this.$axios.get('/posts/search', params).then(rs => {
          if (rs.data.length > 0) {
            this.contentsData = this.contentsData.concat(rs.data)
          } else {
            this.finished = true
          }
          this.loading = false
        }).catch(() => {})
      },
      setSearchVisible () {
        if (this.searchVisible) {
          this.p = 1
          if (this.keyword) {
            this.getContentsInCate()
          } else {
            if (this.checksum > 0) this.getContentsInCate()
            this.checksum = 0
            this.searchVisible = false
          }
        } else {
          this.searchVisible = true
          this.$nextTick(() => this.$refs.keyword.focus())
        }
      }
    },
  }
</script>

<style scoped>
	.editor_info {
		margin-top: 1.5px;
		text-align: center;
	}
	.editor_info .img {
		display: inline-block;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		overflow: hidden;
	}
	.editor_info .name {
		font-size: 18px;
		font-weight: 500;
		color: #222;
		margin-top: 22px;
	}
	.editor_info .slogan {
		font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.03em;
		color: #888;
		margin-top: 10px;
	}
	.editor_info .count {
		font-size: 13px;
		color: #888;
		font-style: italic;
		margin-top: 10px;
	}
	.editor_info .count b {
		color: #222;
		font-weight: 500;
	}
</style>
