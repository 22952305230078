export default {
  post_cate: {},
  title: '',
  writer: {},
  tags: [],
  seo_meta: [],
  post_img: [],
  post_seo_img: [],
  post_top_img: [],
  recommends: [],
  contents: '',
  is_visible: false,
}