<script>
  import SearchAll from 'src/pc/pages/SearchAll'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import PostContainer from '../components/PostContainer'
  import AddWishList from '../components/AddWishList'
  export default {
    extends: SearchAll,
    components: {
      PageLayout,
      SubPageLayout,
      PostContainer,
      AddWishList
    }
  }
</script>

<style scoped>
  .tag_count {
    margin-top: 1.5px;
    text-align: center;
    border-bottom: 0;
  }
  .tag_count .sch_word {
    font-size: 20px;
    letter-spacing: -0.05em;
    color: #222;
    line-height: 20px;
  }
  .tag_count .sch_word b {
    font-weight: 500;
  }
  .tag_count .sch_word i {
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 5px;
  }
  .tag_count .t_count {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
    line-height: 18px;
    text-transform: uppercase;
  }
  .tag_count .t_count b {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    line-height: 18px;
  }
  .tag_count .t_count i {
    margin: 0 10px 0 5px;
  }
  .box_basic.count {
    padding: 30px 32.5px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
    border-bottom: 0;
  }
  .box_basic.count p {
    border-bottom: 1.5px solid #222;
    padding-bottom: 10px;
  }
  .box_basic.count b {
    font-size: 18px;
    line-height: 14px;
  }
  .search_all .ctnr_chart {
    margin-bottom: 2px;
    padding: 25px 70px 25px 125px;
    height: 122.5px;
  }
  .search_all .ctnr_chart .chart_img {
    width: 72.5px;
    height: 72.5px;
    left: 32.5px;
  }
  .search_all .ctnr_chart .h_name_kr {
    margin-top: 0;
    line-height: 1.4em;
  }
  .search_all .ctnr_chart .loc {
    margin-top: 12px;
    width: 100%;
  }
  .search_all .ctnr_chart .loc i {
    bottom: 27px;
  }
  .search_all .lst_ctnr {
    padding: 25px 32.5px;
  }
  .search_all .lst_ctnr .img_wrap {
    height: 160px;
  }
  .search_all .lst_ctnr .cate {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 20px;
  }
  .search_all .lst_ctnr .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #bbb;
    line-height: 12px;
    display: inline-block;
  }
  .search_all .lst_ctnr .subj {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    margin-top: 15px;
    word-break: break-all;
  }
  .search_all .lst_ctnr .data {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    margin-top: 12px;
  }
  .search_all .lst_ctnr .data .date {
    color: #aaa;
    margin-right: 20px;
  }
  .search_all .lst_ctnr .data .writer i {
    color: #aaa;
  }
  .search_all .lst_ctnr .h_tags {
    margin-top: 12px;
    max-height: 46px;
    overflow: hidden;
  }
  .search_all .lst_ctnr .h_tags a {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: -0.03em;
    padding: 10px 14px;
    border: 1px solid #ddd;
    margin: 5px 5px 0 0;
    display: inline-block;
  }
  .lst_more_wrap.last {
    margin-bottom: -12px;
  }
  .res_wrap {
  }
</style>