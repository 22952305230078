<template>
  <transition name="el-fade-in-linear">
    <div class="hotel_ctnr" :class="{wrong : this.isWrongIdx}" v-if="hotel">
      <div class="photo">
        <a @click="goHotel()">
          <div class="slider bg-grey-2">
            <div class="ctnr">
              <img v-if="hotel.main_img" :src="hotel.main_img.url">
            </div>
            <div class="ctnr" v-for="(src, i) in getHotelImages" :key="i">
              <img :src="src" class="fitimg" />
            </div>
          </div>
        </a>
      </div>
      <div class="items box_basic">
        <a @click="goHotel()"></a>
        <div class="basic">
          <div class="valign">
            <p class="ht_class">{{hotel.star_rating}}성급</p>
            <p class="ht_name_kr">{{hotel.name}}</p>
            <p class="ht_name_en">{{hotel.name_eng}}</p>
          </div>
          <div class="flo_rt">
            <p class="loc"><i></i>{{hotel.region_txt}}</p>
            <!--{{getProviderName}}-->
<!--            <p class="price" v-if="hotel.ota_data_realtime && !hotel.ota_data_realtime.lowestRate">예약가능한 방 없음</p>-->
<!--            <p class="price" v-else-if="hotel.ota_data_realtime">{{getProviderName}} <b>{{getLowestRate}}</b></p>-->
<!--            <p class="price" v-else><q-skeleton type="rect" class="skeleton" >최저가 검색 중</q-skeleton></p>-->
          </div>
        </div>
        <div class="badges">
          <div class="badge badge_rank" v-if="getRanking">국내호텔<br><b>{{getRanking}}</b>위</div>
          <div class="badge badge_review" v-if="hotel.is_review"><p></p>체크인리뷰</div>
        </div>

        <!--<add-wish-list :hotel="hotel" />-->

        </div>
      </div>


    <!--<div class="hotel_img">-->
      <!--<p class="img" v-if="getHotelImage"><img :src="getHotelImage" width="auto" height="auto"></p>-->
    <!--</div>-->
    <!--<div class="hotel_data box1">-->
      <!--<div class="name">-->
        <!--<p class="hotel_tit">{{getHotelName}}</p>-->
        <!--<p class="hotel_crop">{{getCorpName}}</p>-->
        <!--<div class="benefit">-->
          <!--<p class="txt" v-if="hotel.only_online">온라인발급 전용 카드</p>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="hotel_data box2">-->
      <!--<div class="sale" v-if="hotel.top_benefit">-->
        <!--<p v-for="(bnf, index) in hotel.top_benefit" :key="index" v-if="bnf.idx" :class="getClassTopBenefit" class="full-height" >-->
          <!--<i class="store ellipsis">{{bnf.tags[0]}}</i>-->
          <!--<span class="num"><b>{{bnf.tags[1]}}</b> {{bnf.tags[2]}}</span>-->
        <!--</p>-->
      <!--</div>-->
      <!--<div class="ex">-->
        <!--<p class="in_for" v-html="getReplaceToHtml()"></p>-->
        <!--<p class="l_mth" v-if="hotel.pre_month_money">전월실적 <b>{{hotel.pre_month_money > 9999 ? hotel.pre_month_money/10000 : null}}</b>{{hotel.pre_month_money > 9999 ? '만원 이상' : '없음' }}</p>-->
      <!--</div>-->
    <!--</div>-->
    <!--<p class="new_hotel" v-if="hotel.pr_container" v-html="hotel.pr_container"></p>-->
    <!--<div class="bottom" v-if="hotel.is_visible">-->
      <!--<add-compare-slot class="compare" :hotel="hotel">-->
        <!--비교함 담기-->
      <!--</add-compare-slot>-->
      <!--<router-link :to="`/hotel/detail/${hotel.idx}`" class="b_view">자세히 보기</router-link>-->
    <!--</div>-->
    <!--</div>-->
  </transition>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import AddWishList from './AddWishList'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import { Loading } from 'element-ui'
  import { cancelSource } from "src/_mixins/CancelTocken"
  import { mapGetters } from 'vuex'
  import { date } from 'quasar'
  import forms from '../pages/hotel_forms'
  const otaAxios = axios.create({baseURL: ''})
  export default {
    name: "HotelContainer",
    components: {
      Loading,
      AddWishList,
    },
    props: {
      object: {
        type: Object,
        default: () => {}
      },
      idx: 0
    },
    data () {
      return {
        hotel: {},
        isWrongIdx: false,
        loading: true
      }
    },
    created () {

      this.hotel = this.object
      if (this.idx > 0) {
        this.getHotelDataFast(this.idx)
      } else {
        this.isWrongIdx = true
      }
    },
    computed: {
      ...mapGetters([
        'getSearchHotelQuery',
        'getRankingByHotelIdx'
      ]),
      getHotelImages () {
        const imgs = []
        const pgImg = this.hotel.imgs ? this.hotel.imgs : []
        const bsImg = this.hotel.bs_imgs ? this.hotel.bs_imgs : []
        pgImg.map(row => {
          if (this.hotel.main_img.url !== row.url) imgs.push(row.url)
        })
        if (imgs.length < 4) {
          bsImg.map(row => {
            if (this.hotel.main_img.url !== row.url) imgs.push(row.url)
          })
        }
        return imgs.slice(0, 4)
      },
      getRanking () {
        return this.getRankingByHotelIdx(this.hotel.idx)
      }
    },
    methods: {
      ...CommonMethods,
      isFileExist (obj) {
        return obj && obj.url ? true : false
      },
      getHotelDataFast (idx) {
        this.$axios.get('/hotels/fast/' + idx).then((rs) => {
          this.hotel = Object.assign({}, rs.data)
        }).catch(() => {})
      },
      getHotelDataAll (idx) {
        this.$axios.get('/hotels/' + idx).then((rs) => {
          this.hotel = Object.assign({}, rs.data)
        }).catch(() => {})
      },
      goHotel () {
        this.$router.push({
          name: 'HotelDetail',
          params: { idx: this.hotel.idx }
        })
      }
    }
  }
</script>

<style scoped>
  .flo_rt {
    float: right;
    text-align: right;
    position: absolute;
    right: 35px;
    bottom: 25px;
  }
  .hotel_ctnr {
    background: #fff;
    position: relative;
    min-height: 180px;
    border-bottom: 8px solid #f2f2f2;
  }
  .hotel_ctnr .photo {
    overflow: hidden;
    width: 300px;
    height: 100%;
    display: inline-block;
    position: absolute;
  }
  .hotel_ctnr .photo .slider {
    /*width: calc((66vw * 5) + (5px * 4));*/
    white-space: nowrap;
    position: relative;
    height: 100%;
  }
  .hotel_ctnr .photo .slider .ctnr {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .hotel_ctnr .photo .slider .ctnr img {
    visibility: hidden;
  }
  .hotel_ctnr .photo .slider .ctnr:first-child img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 180px;
    visibility: visible;
  }
  .hotel_ctnr .photo .slider .ctnr:last-child {
    margin-right: 0;
  }
  .hotel_ctnr .photo .slider .ctnr .img_wrap {
    width: 100%;
    height: 38vw;
  }
  .hotel_ctnr .items {
    position: absolute;
    left: 300px;
    padding: 25px 35px;
    margin: 0 auto;
    display: inline-block;
    width: calc(100% - 300px);
    height: 100%;
    box-sizing: border-box;
    border-bottom: 0;
  }
  .hotel_ctnr .items a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .hotel_ctnr .items .basic {
    display: flex;
    align-items: center;
    width: 95%;
    height: 100%;
  }
  .hotel_ctnr .items .basic .ht_class {
    font-size: 14px;
    color: #888;
    line-height: 1.0em;
    letter-spacing: -0.03em;
  }
  .hotel_ctnr .items .basic .ht_name_kr {
    font-size: 20px;
    color: #222;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    margin-top: 10px;
  }
  .hotel_ctnr .items .basic .ht_name_en {
    font-size: 14px;
    color: #222;
    font-weight: 300;
    margin-top: 6px;
  }
  .hotel_ctnr .items .basic .loc {
    font-size: 14px;
    color: #888;
    margin-top: 14px;
    line-height: 1.2em;
  }
  .hotel_ctnr .items .basic .loc i {
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url('/statics/img/ico_pin.png') no-repeat;
    background-size: 100% auto;
  }
  .hotel_ctnr .items .basic .price {
    font-size: 12px;
    color: #888;
    margin-top: 12px;
    text-align: right;
  }
  .hotel_ctnr .items .basic .price b {
    font-size: 20px;
    color: #222;
    font-weight: 600;
    line-height: 1.0em;
    margin-left: 15px;
    display: none;
  }
  .hotel_ctnr .items .basic .price b:before {
    content: "￦";
    font-size: 13px;
  }
  .hotel_ctnr .items .badges {
    display: inline-block;
    position: absolute;
    right: 35px;
    top: 25px;
  }
  .hotel_ctnr .items .badges .badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    padding: 8px 0 5px;
    text-align: center;
    border-radius: 4px;
    width: 55px;
  }
  .hotel_ctnr .items .badges .badge.badge_rank {
    color: #222;
    background: #ebebeb;
    margin-right: 5px;
  }
  .hotel_ctnr .items .badges .badge.badge_rank b {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: -0.5px;
  }
  .hotel_ctnr .items .badges .badge.badge_review {
    color: #00897a;
    background: #ccf9f4;
  }
  .hotel_ctnr .items .badges .badge.badge_review p {
    display: inline-block;
    width: 100%;
    height: 13px;
    background: url('/statics/img/ico_pin_badge.png') no-repeat;
    background-size: 10px auto;
    background-position: center;
  }
  .skeleton {
    float: right;
    text-align: center;
    text-color: white;
    font-size: 8px;
    padding: 5px 10px;
    width: 130px;
  }
</style>
