<script>
  import VHtmlWithHotelComponent from 'src/pc/components/VHtmlWithHotelComponent'
  import HotelContainer from './HotelContainer'
  export default {
    extends: VHtmlWithHotelComponent,
    components: {
      HotelContainer,
    }
  }
</script>
