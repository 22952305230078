<script>
	import PostContainer from 'src/pc/components/PostContainer'
	import HashTag from './HashTag'
  export default {
    extends: PostContainer,
		components: {
      HashTag
		}
  }
</script>

<style scoped>
  .post-container {
    padding: 25px 32.5px;
  }
  .post-container.box_basic {
    border-bottom: 8px solid #f2f2f2;
  }
  .post-container .img_wrap {
    height: 160px;
  }
  .post-container .cate {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 20px;
  }
  .post-container .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #bbb;
    line-height: 12px;
    display: inline-block;
  }
  .post-container .subj {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    margin-top: 15px;
    word-break: break-all;
  }
  .post-container .data {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    margin-top: 12px;
  }
  .post-container .data .date {
    color: #aaa;
    margin-right: 20px;
  }
  .post-container .data .writer i {
    color: #aaa;
  }
  .post-container .h_tags {
    max-height: 54px;
    overflow: hidden;
  }
  .post-container .h_tags a {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: -0.03em;
    padding: 8px 10px;
    border: 1px solid #ddd;
    margin: 17px 5px 0 0;
    display: inline-block;
  }
</style>