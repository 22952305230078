<script>
  import AirlineDetail from 'src/pc/pages/AirlineDetail'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import PostContainer from '../components/PostContainer'
  export default {
    extends: AirlineDetail,
    components: {
      PageLayout,
      SubPageLayout,
      DisplayContainer,
      PostContainer,
    }
  }
</script>

<style scoped>
  .airline_detail {
    position: relative;
    padding-top: 0;
    margin-top: -60px;
  }
  .img_air_top {
    height: 250px;
    position: relative;
    border-bottom: 8px solid #f2f2f2;
  }
  .img_air_top .img_air {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .img_air_top .img_air:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
  }
  .img_air_top .title {
    position: absolute;
    color: #fff;
    left: 32.5px;
    bottom: 30px;
  }
  .img_air_top .title .name_kr {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.05em;
  }
  .img_air_top .title .name_en {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 3px;
  }
  .img_air_top .copyright {
    position: absolute;
    right: 3.0%;
    bottom: 3.5%;
    font-size: 0.7em;
    color: #fff;
    opacity: 0.75;
  }
  .curtn_lst .box_tit {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  .curtn_lst .box_tit .tit_l {
    margin-bottom: 0;
  }
  .curtn_lst .lst_ctnr {
    padding: 25px 32.5px;
  }
  .curtn_lst .lst_ctnr .img_wrap {
    width: 100%;
    height: 42.5vw;
  }
  .curtn_lst .lst_ctnr .cate {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    letter-spacing: -0.02em;
    line-height: 12px;
    margin-top: 20px;
  }
  .curtn_lst .lst_ctnr .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #888;
    line-height: 12px;
    display: inline-block;
  }
  .curtn_lst .lst_ctnr .subj {
    font-size: 15px;
    font-weight: 500;
    color: #222;
    line-height: 1.4em;
    letter-spacing: -0.03em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .curtn_lst .lst_ctnr .data {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
  }
  .curtn_lst .lst_ctnr .data .date {
    color: #bbb;
  }
  .curtn_lst .lst_ctnr .data .writer {
    color: #888;
    margin-left: 20px;
  }
  .curtn_lst .lst_ctnr .h_tags {
    margin-top: 15px;
    height: 40px;
    overflow: hidden;
  }
  .curtn_lst .lst_ctnr .h_tags .tag {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: -0.03em;
    border: 0.5px solid #ccc;
    margin: 5px 5px 0 0;
    padding: 10px 14px;
    line-height: 1.0em;
    display: inline-block;
  }
</style>