<template>
  <page-layout class="search_all">
    <sub-page-layout>

      <!-- 검색 결과 -->
      <h3 class="hide">통합검색목록</h3>

      <div class="tag_count box_basic">
				<p class="sch_word"><b>'{{getKeyword}}'</b><i>검색 결과</i></p>
        <div class="t_count">
					<b>{{hotelDataCnt}}</b><i>Hotels,</i> <b>{{contentsDataCnt}}</b><i>Contents</i>
        </div>
      </div>

			<div class="res_wrap">
				<div class="count box_basic">
					<p><b>{{hotelDataCnt}}</b><i> Hotels</i></p>
				</div>
				<div class="hotel_lst_area">
					<ul class="lst">
						<li v-for="(hotel, index) in hotelData" :key="index">
							<div class="box_basic ctnr_chart">
								<router-link :to="`/hotel/detail/${hotel.idx}`" class="chart_img img_wrap" v-if="hotel.main_img"><img :src="hotel.main_img.url"></router-link>
								<div>
									<router-link :to="`/hotel/detail/${hotel.idx}`">
										<p class="h_name_kr">{{hotel.name}}</p>
										<p class="h_name_en">{{hotel.name_eng}}</p>
									</router-link>
									<p class="loc">{{hotel.region_txt}}<i>{{hotel.star_rating}}성급</i></p>
								</div>
								<add-wish-list :hotel="hotel" />
							</div>
							<!--<div class="card_img">
								<router-link :to="`/hotel/detail/${card.idx}`">
									<p class="img"><img v-if="isFileExist(card.card_img)" :src="card.card_img.url" width="auto" height="auto" style="max-width: 80px; max-height: 80px;"></p>
								</router-link>
							</div>
							<div class="data">
								<p class="name">
									<router-link :to="`/hotel/detail/${card.idx}`">
										{{card.name}}
									</router-link>
								</p>
								<p class="brand">{{card.corp_txt}}</p>
								<p class="type">{{card.cate_txt}}</p>
							</div>-->
						</li>
					</ul>
					<div class="lst_more_wrap box_basic">
						<p class="lst_no" v-show="hotelDataCnt < 1">검색 결과가 없습니다. 😭</p>
						<a class="btn_more drtn_down" v-show="!hotelFinished" :disabled="hotelLoading" @click="getMoreHotels()">호텔 더 보기</a>
					</div>
				</div>
			</div>


			<div class="res_wrap">
				<div class="count box_basic">
					<p><b>{{contentsDataCnt}}</b><i> Contents</i></p>
				</div>
				<div class="con_lst_area">
					<ul class="lst">
						<li v-for="(content, index) in contentsData" :key="index" class="lst_ctnr box_basic">
							<router-link :to="`/contents/detail/${content.idx}`">
								<div class="img_wrap"><img v-if="isFileExist(content.post_img)" :src="content.post_img.url" class="fitimg"></div>
								<p class="cate"><i>{{content.post_cate_txt}}</i>{{content.type}}</p>
								<p class="subj">{{content.title}}</p>
								<div class="data">
									<span class="date">{{content.reg_dt}}</span>
									<span class="writer" v-if="content.writer">{{content.writer.nick}}</span>
								</div>
							</router-link>
							<p class="h_tags" v-if="content.hash_tags.length > 0">
								<router-link :to="`/search/hotel?hashTags=${tag.idx}`" class="tag" v-for="tag in content.hash_tags" :key="tag.idx">#{{tag.name}}</router-link>
							</p>
							<!--<router-link :to="`/contents/detail/${content.idx}`">
								<p class="img"><img v-if="isFileExist(content.post_img)" :src="content.post_img.url" width="100" height="100"></p>
								<p class="category">{{content.post_cate_txt}}</p>
								<p class="subj">{{content.title}}</p>
								<div class="data">
									<p class="date">{{content.reg_dt}}</p>
									<p class="writer" v-if="content.writer">{{content.writer.nick}}</p>
								</div>
							</router-link>-->
						</li>
					</ul>
					<div class="lst_more_wrap box_basic last">
						<p class="lst_no" v-show="contentsFinished && contentsData.length < 1">검색 결과가 없습니다. 😭</p>
						<a class="btn_more drtn_down" v-show="!contentsFinished" :disabled="contentsLoading" @click="getMoreContents()">콘텐츠 더 보기</a>
					</div>
				</div>
			</div>

    </sub-page-layout>
  </page-layout>
</template>

<script>
  import Vue from 'vue'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import PostContainer from '../components/PostContainer'
  import AddWishList from '../components/AddWishList'
  import CommonComputed from "src/_mixins/CommonComputed";
  import CommonMethods from 'src/_mixins/CommonMethods'
  const baseAction = '/all/search'
  export default {
    name: "SearchAll",
    components: {
      PageLayout,
      SubPageLayout,
      PostContainer,
      AddWishList
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : [통합검색] 키워드 검색결과`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '키워드로 편하게 호텔, 콘텐츠 모두 한 번에 검색하기'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `[통합검색] 키워드 검색결과 | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '키워드로 편하게 호첼, 콘텐츠 모두 한 번에 검색하기'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        cate: {},
        hotelData: [],
        hotelDataCnt: 0,
        contentsData: [],
        contentsDataCnt: 0,
        hotelFinished: false,
        hotelLoading: true,
        contentsFinished: false,
        contentsLoading: true,
        cp: 1,
        pp: 1,
				cpSize: 30,
				ppSize: 10
      }
    },
    beforeRouteUpdate (to, from, next) {
      next()
      if (this.getKeyword) {
        this.getHotels()
        this.getContents()
      }
    },
    created () {
      this.getHotels()
      this.getContents()
    },
    computed: {
      ...CommonComputed,
      getKeyword () {
        return this.$route.query.keyword ? this.$route.query.keyword.trim() : ''
      }
    },
    methods: {
      ...CommonMethods,
      getHotels () {
        this.hotelLoading = true
        this.hotelFinished = false
        const params = {params: {p: this.cp, perPage: this.cpSize, keyword: this.getKeyword}}
        this.$axios.get('/hotels/search', params).then(rs => {
          this.hotelData = rs.data.data
          this.hotelDataCnt = rs.data.total //rs.data.data.length > 0 ? rs.data.data[0].key + 1 : 0
          if (rs.data.total < this.cpSize) this.hotelFinished = true
          this.hotelLoading = false
        }).catch(() => {})
      },
      getMoreHotels () {
        this.hotelLoading = true
        this.cp++
        const params = {params: {p: this.cp, perPage: this.cpSize, keyword: this.getKeyword}}
        this.$axios.get('/hotels/search', params).then(rs => {
          if (rs.data.total > 0) {
            this.hotelData = this.hotelData.concat(rs.data.data)
            if (rs.data.total < this.cpSize) this.hotelFinished = true
          } else {
            this.hotelFinished = true
          }
          this.hotelLoading = false
        }).catch(() => {})
      },
      getContents () {
        this.contentsLoading = true
        const params = {params: {p: this.pp, perPage: this.ppSize, keyword: this.getKeyword}}
        this.$axios.get('/posts/search', params).then(rs => {
          this.contentsData = rs.data
          this.contentsDataCnt = rs.data.length > 0 ? rs.data[0].key + 1 : 0
          if (rs.data.length < this.ppSize) this.contentsFinished = true
          this.contentsLoading = false
        }).catch(() => {})
      },
      getMoreContents () {
        this.contentsLoading = true
        this.contentsFinished = false
        this.pp++
        const params = {params: {p: this.pp, perPage: this.ppSize, keyword: this.getKeyword}}
        this.$axios.get('/posts/search', params).then(rs => {
          if (rs.data.length > 0) {
            this.contentsData = this.contentsData.concat(rs.data)
            if (rs.data.length < this.ppSize) this.contentsFinished = true
          } else {
            this.contentsFinished = true
          }
          this.contentsLoading = false
        }).catch(() => {})
      },
    },
  }
</script>

<style scoped>
	.tag_count {
		margin-top: 1.5px;
		text-align: center;
    border-bottom: 0;
	}
	.tag_count .sch_word {
		font-size: 20px;
		letter-spacing: -0.05em;
		color: #222;
		line-height: 20px;
	}
	.tag_count .sch_word b {
		font-weight: 500;
	}
	.tag_count .sch_word i {
		font-size: 20px;
		font-weight: 300;
		line-height: 20px;
		margin-left: 5px;
	}
	.tag_count .t_count {
		margin-top: 10px;
		font-size: 14px;
		color: #888;
		line-height: 18px;
		text-transform: uppercase;
	}
	.tag_count .t_count b {
		font-size: 16px;
		font-weight: 500;
		color: #222;
		line-height: 18px;
	}
	.tag_count .t_count i {
		margin: 0 10px 0 5px;
	}
	.box_basic.count {
		padding: 30px 32.5px 10px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 700;
		line-height: 14px;
		text-transform: uppercase;
    border-bottom: 0;
	}
	.box_basic.count p {
		border-bottom: 1.5px solid #222;
		padding-bottom: 10px;
	}
	.box_basic.count b {
		font-size: 18px;
		line-height: 14px;
	}
	.search_all .ctnr_chart {
		margin-bottom: 2px;
		padding: 25px 70px 25px 125px;
		height: 122.5px;
	}
	.search_all .ctnr_chart .chart_img {
		width: 72.5px;
		height: 72.5px;
		left: 32.5px;
	}
	.search_all .ctnr_chart .h_name_kr {
		margin-top: 0;
		line-height: 1.4em;
	}
	.search_all .ctnr_chart .loc {
		margin-top: 12px;
		width: 100%;
	}
	.search_all .ctnr_chart .loc i {
		bottom: 27px;
	}
	.search_all .lst_ctnr {
		padding: 25px 32.5px;
	}
	.search_all .lst_ctnr .img_wrap {
		height: 160px;
	}
	.search_all .lst_ctnr .cate {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: 0;
		line-height: 12px;
		margin-top: 20px;
	}
	.search_all .lst_ctnr .cate i {
		margin-right: 12px;
		padding-right: 12px;
		border-right: 1px solid #bbb;
		line-height: 12px;
		display: inline-block;
	}
	.search_all .lst_ctnr .subj {
		font-size: 16px;
		font-weight: 500;
		color: #222;
		letter-spacing: -0.03em;
		line-height: 1.4em;
		margin-top: 15px;
		word-break: break-all;
	}
	.search_all .lst_ctnr .data {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		margin-top: 12px;
	}
	.search_all .lst_ctnr .data .date {
		color: #aaa;
		margin-right: 20px;
	}
	.search_all .lst_ctnr .data .writer i {
		color: #aaa;
	}
	.search_all .lst_ctnr .h_tags {
		margin-top: 12px;
		max-height: 46px;
		overflow: hidden;
	}
	.search_all .lst_ctnr .h_tags a {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: -0.03em;
		padding: 10px 14px;
		border: 1px solid #ddd;
		margin: 5px 5px 0 0;
		display: inline-block;
	}
	.lst_more_wrap.last {
		margin-bottom: -12px;
	}
	.res_wrap {
	}
</style>
