export default {
  idx: 0,
  cate: 'DOMESTIC', // CRD: 신용카드, CHK: 체크카드
  name: '',
  ota_entity_key: '',
  ota_entity_id: 0,
  ota_datafeeds: [],
  main_img: {large: '', small: ''},
  imgs: [],
  hash_tags: [],
  related_review_videos: [],
  related_review_posts: [],
  related_hotels: [],
  related_contents: [],
  theme_ranking_contents: [],
  package_contents: [],
  is_review: true,
  is_visible: true,
}