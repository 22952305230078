<script>
  import HotelContainer from 'src/pc/components/HotelContainer'
  import AddWishList from './AddWishList'
  export default {
    extends: HotelContainer,
    components: {
      AddWishList
    }
  }
</script>


<style scoped>
  .flo_rt {
    float: right;
    text-align: right;
  }
  .hotel_ctnr {
    background: #fff;
    position: relative;
  }
  .hotel_ctnr .photo {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
  }
  .hotel_ctnr .photo .slider {
    /*width: calc((66vw * 5) + (5px * 4));*/
    white-space: nowrap;
  }
  .hotel_ctnr .photo .slider .ctnr {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 66vw;
    height: 38vw;
  }
  .hotel_ctnr .photo .slider .ctnr img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: unset;
    visibility: visible;
  }
  .hotel_ctnr .photo .slider .ctnr:last-child {
    margin-right: 0;
  }
  .hotel_ctnr .photo .slider .ctnr .img_wrap {
    width: 100%;
    height: 38vw;
  }
  .hotel_ctnr .items {
    position: relative;
    left: unset;
    padding: 25px 32.5px;
    margin: 0 auto;
    display: block;
    width: unset;
    height: unset;
  }
  .hotel_ctnr .items a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .hotel_ctnr .items .basic {
    display: inline-block;
    width: 100%;
  }
  .hotel_ctnr .items .basic .ht_class {
    font-size: 12px;
    color: #888;
    line-height: 1.0em;
    letter-spacing: -0.03em;
    position: absolute;
    margin-top: -45px;
    background: #fff;
    padding: 8px 12px 0;
    margin-left: -10px;
  }
  .hotel_ctnr .items .basic .ht_name_kr {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    width: 90%;
  }
  .hotel_ctnr .items .basic .ht_name_en {
    font-size: 14px;
    color: #222;
    font-weight: 400;
    margin-top: 6px;
    width: 90%;
  }
  .hotel_ctnr .items .basic .loc {
    font-size: 12px;
    color: #888;
    margin-top: 38px;
  }
  .hotel_ctnr .items .basic .loc i {
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url('/statics/img/ico_pin.png') no-repeat;
    background-size: 100% auto;
  }
  .hotel_ctnr .items .basic .price {
    font-size: 12px;
    color: #888;
    margin-top: 12px;
    text-align: right;
    display: none;
  }
  .hotel_ctnr .items .basic .price b {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    line-height: 1.2em;
    margin-left: 15px;
  }
  .hotel_ctnr .items .basic .price b:before {
    content: "￦";
    font-size: 13px;
  }
  .hotel_ctnr .items .badges {
    display: inline-block;
    position: absolute;
    left: 32.5px;
    bottom: 25px;
  }
  .hotel_ctnr .items .badges .badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    padding: 8px 0 5px;
    text-align: center;
    border-radius: 4px;
    width: 55px;
  }
  .hotel_ctnr .items .badges .badge.badge_rank {
    color: #222;
    background: #ebebeb;
    margin-right: 5px;
  }
  .hotel_ctnr .items .badges .badge.badge_rank b {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: -0.5px;
  }
  .hotel_ctnr .items .badges .badge.badge_review {
    color: #00897a;
    background: #ccf9f4;
  }
  .hotel_ctnr .items .badges .badge.badge_review p {
    display: inline-block;
    width: 100%;
    height: 13px;
    background: url('/statics/img/ico_pin_badge.png') no-repeat;
    background-size: 10px auto;
    background-position: center;
  }
  .skeleton {
    float: right;
    text-align: center;
    text-color: white;
    font-size: 8px;
    padding: 5px 10px;
    width: 130px;
  }
</style>
