<template>
	<page-layout class="contents">

		<!-- 콘텐츠 카테고리 -->
		<div class="conmain_cate" :key="getRouteCate">
			<a v-for="cate in popularCate" :key="cate.idx" :class="{on: cate.idx === currentCate.idx}" @click="setCurrentCate(cate)">{{cate.name}}</a>
		</div>

		<!-- 상단 롤링 -->
		<div class="box_basic conmain">
			<div class="conmain_top">
    		<div class="scroller">
    			<div class="inner inner_l num-3" :key="currentCate.idx">

						<display-container :section="getCurrentMainDpSections[0]" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate"><i>{{component.getCate(row)}}</i>{{component.getType(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</div>
						</display-container>

						<display-container :section="getCurrentMainDpSections[1]" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate"><i>{{component.getCate(row)}}</i>{{component.getType(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</div>
						</display-container>

						<display-container :section="getCurrentMainDpSections[2]" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate"><i>{{component.getCate(row)}}</i>{{component.getType(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</div>
						</display-container>

					</div>
    		</div>
    	</div>
		</div>
		<!-- //상단 롤링 -->

		<!-- 중간 큐레이션 -->
		<div class="box_basic conmain_mid">
			<p class="tit_l">에디터의 남다른 추천 들어갑니다 👇</p>

			<div class="layout_wrap" :key="currentCate.idx">

				<a class="container ctnr_l ctnr_dark">
					<display-container :section="getCurrentContentsDpSections[0]" align="left">
						<div slot-scope="{ component, row }">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit"><i><em>{{component.getCate(row)}}</em>{{component.getType(row)}}</i>{{component.getTitle(row)}}</p>
						</div>
					</display-container>
				</a>
				<a class="container ctnr_s ctnr_dark">
					<display-container :section="getCurrentContentsDpSections[1]" align="left">
						<div slot-scope="{ component, row }">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
				</a>
				<a class="container ctnr_s ctnr_dark">
					<display-container :section="getCurrentContentsDpSections[2]" align="left">
						<div slot-scope="{ component, row }">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
				</a>
				<a class="container ctnr_s ctnr_dark">
					<display-container :section="getCurrentContentsDpSections[3]" align="left">
						<div slot-scope="{ component, row }">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
				</a>
				<a class="container ctnr_s ctnr_dark">
					<display-container :section="getCurrentContentsDpSections[4]" align="left">
						<div slot-scope="{ component, row }">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
				</a>

			</div>

		</div>

		<article class="post_lst">

			<!-- 콘텐츠 리스트 -->
			<div class="lst_cate box_basic">
				<a :class="{on : !type}" @click="setType('')">ALL</a>
				<a :class="{on : type === 'HOTEL'}" @click="setType('HOTEL')">HOTEL</a>
				<a :class="{on : type === 'FLIGHT'}" @click="setType('FLIGHT')">FLIGHT</a>
			</div>

			<!-- 검색창 -->
			<div class="search_area">
				<div class="sch_box box_basic">
					<input type="text" placeholder="CONTENTS 검색" v-model.trim="keyword" ref="keyword" @keyup.enter="setSearchVisible" @keydown.esc="setSearchVisible" @blur="setSearchVisible">
				</div>
			</div>

			<!-- 리스트 컨테이너 -->
			<ul style="min-height: 100%">
				<li v-for="(post, index) in recentContentsData" :key="index">
					<post-container :post="post"></post-container>
					<!--<router-link :to="`/contents/detail/${content.idx}`">-->
						<!--<div class="img_wrap"><img v-if="isFileExist(content.post_img)" :src="content.post_img.url" class="fitimg"></div>-->
						<!--<p class="cate"><i>{{content.post_cate_txt}}</i>HOTEL</p>-->
						<!--<p class="subj">{{content.title}}</p>-->
						<!--<div class="data">-->
							<!--<span class="date">{{content.reg_dt}}</span>-->
							<!--<span class="writer" v-if="content.writer">{{content.writer.nick}}</span>-->
						<!--</div>-->
					<!--</router-link>-->
					<!--<p class="h_tags">-->
						<!--<router-link to="/" class="tag">#인피니티풀</router-link>-->
						<!--<router-link to="/" class="tag">#라운지바</router-link>-->
						<!--<router-link to="/" class="tag">#오션뷰</router-link>-->
						<!--<router-link to="/" class="tag">#루프탑</router-link>-->
						<!--<router-link to="/" class="tag">#가족여행</router-link>-->
					<!--</p>-->
				</li>
			</ul>

			<div class="lst_more_wrap box_basic">
				<a class="lst_no" v-if="!loading && recentContentsData.length < 1" @click="$refs.keyword.focus(); $refs.keyword.select()">검색 결과를 찾을 수 없습니다.</a>
				<a class="btn_more drtn_down" :disabled="loading" v-if="!finish && recentContentsData.length > 0" @click="getMoreRecentContents()">콘텐츠 더 보기</a>
			</div>
		</article>


	</page-layout>
</template>

<script>
  import {Loading} from 'element-ui'
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import PostContainer from '../components/PostContainer'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  const baseAction = '/posts'
  export default {
    name: "Contents",
    components: {
      PageLayout,
      DisplayContainer,
      PostContainer
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : [콘텐츠] 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `[콘텐츠] 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰 | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        popularCate: [],
        recentContentsData: [],
        currentCate: {idx: 1, name: 'CHECK-IN'},
        loading: true,
        searchVisible: false,
        keyword: '',
        checksum: 0,
        type: '',
        p: 1,
				perPage: 10,
        mainDpSectionData: [
          {idx: 1, sections: [92, 93, 94]},
          {idx: 2, sections: [103, 104, 105]},
          {idx: 3, sections: [114, 115, 116]},
          {idx: 4, sections: [125, 126, 127]},
        ],
        contentsDpSectionData: [
          {idx: 1, sections: [96, 97, 98, 99, 100]},
          {idx: 2, sections: [107, 108, 109, 110, 111]},
          {idx: 3, sections: [118, 119, 120, 121, 122]},
          {idx: 4, sections: [129, 130, 131, 132, 133]},
        ]
      }
    },
		beforeRouteUpdate (to, from, next) {
      next()
      this.getRecentContents()
    },
    beforeMount () {
      this.getPostCate()
    },
    // activated() {
    //   this.getPostCate()
    // },
    computed: {
			...CommonComputed,
      getRouteCate () {
        return this.$route.params.cate.toLowerCase()
			},
      finish () {
        return this.loading === false && this.recentContentsData.length < 10
      },
			getCurrentMainDpSections () {
        return this.mainDpSectionData.find(row => row.idx === this.currentCate.idx).sections
			},
      getCurrentContentsDpSections () {
        return this.contentsDpSectionData.find(row => row.idx === this.currentCate.idx).sections
      }
    },
    methods: {
      ...CommonMethods,
      getPostCate () {
        if (this.popularCate.length < 1) {
          this.$axios.get('/post_cates/popular').then(rs => {
            this.popularCate = rs.data
            this.currentCate = this.popularCate.find(row => row.name.toLowerCase() === this.getRouteCate)
            this.getRecentContents()
          }).catch(() => {
          })
        } else {
          this.getRecentContents()
        }
      },
      getCurrentCate () {
        if (this.getRouteCate && this.popularCate.length > 0) {
          this.currentCate = this.popularCate.find(row => row.name.toLowerCase() === this.getRouteCate)
        }
      },
      getRecentContents () {
        this.getCurrentCate()

        let loading = false
        if (this.recentContentsData.length > 0) {
          loading = Loading.service({
            target: document.querySelector('#lst'),
            background: 'rgba(255, 255, 255, 0.5)'
          })
        }
        this.p = 1
        this.loading = true
        let action = '/recent'
        const params = {params: {
          p: this.p,
          perPage: this.perPage,
          post_cate: this.currentCate.idx,
          type: this.type
        }}
        if (this.keyword) {
          params.params.keyword = this.keyword
          action = '/search'
        }
        this.$axios.get(baseAction + action, params).then(rs => {
          this.recentContentsData = action === '/recent' ? rs.data.data : rs.data
          this.loading = false
          if (this.keyword) this.checksum ++
          if (loading) loading.close()
        }).catch(() => {
          if (loading) loading.close()
        })
      },
      getMoreRecentContents () {
        this.loading = true
        this.p++
        let action = '/recent'
        const params = {params: {p: this.p, perPage: this.perPage, post_cate: this.currentCate.idx, type: this.type}}
        if (this.keyword) {
          params.params.keyword = this.keyword
          action = '/search'
        }
        this.$axios.get(baseAction + action, params).then(rs => {
          this.recentContentsData = this.recentContentsData.concat(action === '/recent' ? rs.data.data : rs.data)
          this.loading = false
        }).catch(() => {})
      },
			setCurrentCate (cate) {
        this.currentCate = cate
        this.$router.replace({ params: { cate: this.currentCate.name.toLowerCase() } })
        // this.getRecentContents()
      },
      setSearchVisible () {
        if (this.searchVisible) {
          this.p = 1
          if (this.keyword) {
            this.getRecentContents()
          } else {
            if (this.checksum > 0) this.getRecentContents()
            this.checksum = 0
            this.searchVisible = false
          }
        } else {
          this.searchVisible = true
          this.$nextTick(() => this.$refs.keyword.focus())
        }
      },
      setType (type) {
        this.type = type
        this.p = 1
				this.getRecentContents()
      },
    },
  }
</script>

<style scoped>
	.conmain_cate {
		background: #fff;
		padding: 50px 40px;
    text-align: center;
	}
	.conmain_cate a {
		font-size: 18px;
		font-weight: 500;
		color: #888;
		margin: 0 20px 0 0;
    padding: 12px 32px;
    border-radius: 2.0em;
	}
	.conmain_cate a.on {
		font-weight: 700;
		color: #fff;
    background: #01e1c9;
	}
	.box_basic.conmain {
		padding-top: 0;
	}
	.conmain .container .img_wrap {
		height: 360px;
	}
	.conmain .scroller .inner .container .con_tit {
		top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: auto;
    bottom: auto;
    width: 80%;
    text-align: center;
	}
	.conmain .display-container {
		width: calc((100% - 20px) / 3);
	}
	.conmain .container .con_tit .title {
		font-size: 26px;
		font-weight: 300;
		line-height: 1.4em;
		letter-spacing: -0.05em !important;
		overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
	}
	.conmain .container .con_tit .cate {
		font-size: 13px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 12px;
		margin-bottom: 14px;
    display: none;
	}
	.conmain .container .con_tit .cate i {
		margin-right: 12px;
		padding-right: 12px;
		border-right: 1px solid rgba(255,255,255,0.5);
		line-height: 12px;
		display: none;
	}
	.box_basic.conmain_mid {

	}
	.conmain_mid .layout_wrap {

	}
	.conmain_mid .layout_wrap .container {
		display: inline-block;
		position: relative;
	}
	.conmain_mid .layout_wrap .container.ctnr_l {
		width: 100%;
	}
	.conmain_mid .layout_wrap .container.ctnr_s {
		width: calc((100% - 10px) / 2);
		margin-top: 10px;
	}
	.conmain_mid .layout_wrap .container.ctnr_s:nth-child(odd) {
		margin-left: 10px;
	}
	.conmain_mid .layout_wrap .container.ctnr_s:nth-child(even) {
		margin-right: 0;
	}
	.conmain_mid .layout_wrap .container .img_wrap {
		width: 100%;
		overflow: hidden;
		position: relative;
	}
	.conmain_mid .layout_wrap .container.ctnr_l .img_wrap {
		height: 44vmin;
	}
	.conmain_mid .layout_wrap .container.ctnr_s .img_wrap {
		height: 28vmin;
	}
	.conmain_mid .layout_wrap .container.ctnr_dark .img_wrap:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
	}
	.conmain_mid .layout_wrap .container.ctnr_dark .con_tit {
		font-size: 18px;
		font-weight: 400;
		color: #fff;
		letter-spacing: -0.02em;
		line-height: 1.2em;
		position: absolute;
		left: 25px;
		right: 25px;
		bottom: 25px;
		z-index: 1;
		overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
	}
	.conmain_mid .layout_wrap .container.ctnr_l .con_tit {
		font-size: 34px;
    font-weight: 300;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
	}
  .conmain_mid .layout_wrap .container.ctnr_l .con_tit i {
    display: block;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.0em;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-transform: uppercase;
  }
  .conmain_mid .layout_wrap .container.ctnr_l .con_tit i em {
    font-style: normal;
    /*padding-right: 18px;
    margin-right: 18px;
    border-right: 1px solid rgba(255,255,255,0.5);*/
  }
	.post_lst .lst_cate {
		margin-bottom: 0;
    padding: 35px 40px 20px;
	}
	.post_lst .search_area .sch_box {
		padding: 0 40px 30px;
		margin-bottom: 1.5px;
    border-bottom: 8px solid #f2f2f2;
	}
	.post_lst .search_area .sch_box input[type="text"] {
		width: 100%;
		height: 36px;
		border: 1px solid #ccc;
		padding-left: 10px;
	}
	.post_lst .lst_ctnr {
		padding: 25px 32.5px;
	}
	.post_lst .lst_ctnr .img_wrap {
		height: 160px;
	}
	.post_lst .lst_ctnr .cate {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: 0;
		line-height: 12px;
		margin-top: 20px;
	}
	.post_lst .lst_ctnr .cate i {
		margin-right: 12px;
		padding-right: 12px;
		border-right: 1px solid #888;
		line-height: 12px;
		display: inline-block;
	}
	.post_lst .lst_ctnr .subj {
		font-size: 16px;
		font-weight: 500;
		color: #222;
		letter-spacing: -0.03em;
		line-height: 1.4em;
		margin-top: 15px;
		word-break: break-all;
	}
	.post_lst .lst_ctnr .data {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		margin-top: 12px;
	}
	.post_lst .lst_ctnr .data .date {
		color: #aaa;
		margin-right: 20px;
	}
	.post_lst .lst_ctnr .data .writer i {
		color: #aaa;
	}
	.post_lst .lst_ctnr .h_tags {
		margin-top: 12px;
		height: 44px;
		overflow: hidden;
	}
	.post_lst .lst_ctnr .h_tags a {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: -0.03em;
		padding: 10px 14px;
		border: 1px solid #ddd;
		margin: 5px 5px 0 0;
		display: inline-block;
	}
  .contents .btn_more {
    border: 0;
  }
</style>
