<template>
  <div v-if="post.idx" class="post-container box_basic">
  	<router-link :to="`/contents/detail/${post.idx}`">
			<div class="img_wrap"><img :src="getFileExist(post.post_img)" class="fitimg"></div>
			<p class="cate"><i>{{post.post_cate_txt}}</i>{{post.type}}</p>
			<p class="subj">{{post.title}}</p>
			<!--<div class="data">
				<span class="date">{{post.reg_dt}}</span>
				<span class="writer">{{getWriterNick}}</span>
			</div>-->
		</router-link>
		<p class="h_tags">
			<hash-tag v-for="tag in post.hash_tags" :key="tag.idx" :tag-name="tag.name">
				<a>#{{tag.name}}</a>
			</hash-tag>
		</p>
    <!--<router-link :to="`/contents/detail/${post.idx}`" class="img">
      <img :src="getFileExist(post.post_img)" width="240" height="240">
    </router-link>
    <div class="data">
      <router-link :to="`/contents/cate/${post.post_cate}`" class="category">{{post.post_cate_txt}}</router-link>
      <router-link :to="`/contents/detail/${post.idx}`" class="subj">
        {{post.title}}
      </router-link>
      <p class="date_area">
        <i class="date">{{post.reg_dt}}</i>
        <i class="writer">{{getWriterNick}}</i>
        <i class="writer_img"><img v-if="post.writer" :src="getFileExist(post.writer.prof_img)" width="30" height="30"></i>
      </p>
      <p class="con">
        {{getTextCroped(removeFroalaAlertText(post.contents), 90, '...')}}
      </p>
      <div class="hv">
        <p class="h_tags" v-if="post.tags && post.tags.length > 0">
          <router-link :to="`/contents/tag/${tag}`" v-for="(tag, index) in post.tags" :key="index">{{tag}}</router-link>
        </p>
      </div>
    </div>-->
  </div>
</template>

<script>
	import HashTag from './HashTag'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    name: "PostContainer",
		components: {
      HashTag
		},
    props: {
      post: {
        default: {}
      }
    },
    computed: {
      getWriterNick () {
        return this.post.writer ? this.post.writer.nick : this.post.writer_nick ? this.post.writer_nick : 'Prestige GORILLA'
      }
    },
    methods: {
      ...CommonMethods,
    }
  }
</script>

<style scoped>
  .post-container {
		padding: 25px 50px 25px 335px;
    height: 210px;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 1220px;
	}
  .post-container.box_basic {
		border-bottom: 8px solid #f2f2f2;
	}
	.post-container .img_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
		height: 100%;
	}
	.post-container .cate {
		font-size: 13px;
		font-weight: 500;
		color: #888;
		letter-spacing: 0;
		line-height: 13px;
		margin-top: 10px;
	}
	.post-container .cate i {
		margin-right: 13px;
		padding-right: 13px;
		border-right: 1px solid #bbb;
		line-height: 13px;
		display: inline-block;
	}
	.post-container .subj {
		font-size: 18px;
		font-weight: 500;
		color: #222;
		letter-spacing: -0.03em;
		line-height: 1.4em;
		margin-top: 15px;
		word-break: keep-all;
	}
	.post-container .data {
		font-size: 13px;
		font-weight: 500;
		color: #888;
		margin-top: 15px;
	}
	.post-container .data .date {
		color: #aaa;
		margin-right: 20px;
	}
	.post-container .data .writer i {
		color: #aaa;
	}
	.post-container .h_tags {
		max-height: 54px;
		overflow: hidden;
	}
	.post-container .h_tags a {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: -0.03em;
		padding: 8px 10px;
		border: 1px solid #ddd;
		margin: 17px 5px 0 0;
		display: inline-block;
	}
</style>