var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{staticClass:"home_con"},[_c('div',{staticClass:"box_main home_top"},[_c('display-container',{attrs:{"section":6,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"image"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}}),_c('p',{staticClass:"date"},[_vm._v(_vm._s(component.getText2(row)))]),_c('div',{staticClass:"title_wrap"},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))]),_c('p',{staticClass:"s_txt"},[_vm._v(_vm._s(component.getText1(row)))])])])}}])})],1),_c('div',{staticClass:"box_main main_sch"},[_c('p',{staticClass:"tit_l"},[_vm._v("어디로 떠나세요?")]),_c('input',{staticClass:"ipt_sch",attrs:{"type":"text","readonly":"","placeholder":"호텔 검색하기"},on:{"click":function($event){_vm.searchHotelDialog.main = true}}})]),_c('div',{staticClass:"box_basic box_main con_circle"},[_c('p',{staticClass:"tit_l"},[_vm._v("#프레스티지고릴라 #추천테마")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_xs num-5"},[_c('display-container',{attrs:{"section":8,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_xs"},[_c('hash-tag',{attrs:{"tag-name":component.getTitle(row)}},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}}),_vm._v(" ==>"+_vm._s(component.getImage(row))+"<== ")]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])],1)}}])}),_c('display-container',{attrs:{"section":9,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_xs"},[_c('hash-tag',{attrs:{"tag-name":component.getTitle(row)}},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])],1)}}])}),_c('display-container',{attrs:{"section":10,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_xs"},[_c('hash-tag',{attrs:{"tag-name":component.getTitle(row)}},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])],1)}}])}),_c('display-container',{attrs:{"section":11,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_xs"},[_c('hash-tag',{attrs:{"tag-name":component.getTitle(row)}},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])],1)}}])}),_c('display-container',{attrs:{"section":12,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_xs"},[_c('hash-tag',{attrs:{"tag-name":component.getTitle(row)}},[_c('p',{staticClass:"img_wrap bdr_circle"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(component.getTitle(row)))])])],1)}}])})],1)])]),_c('div',{staticClass:"banner_gosty"},[_c('div',{staticClass:"pc"},[_c('a',{attrs:{"href":"https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web","target":"_blank"}},[_c('img',{attrs:{"src":"https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg"}})])]),_c('div',{staticClass:"mo"},[_c('a',{attrs:{"href":"https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web","target":"_blank"}},[_c('img',{attrs:{"src":"https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg"}})])])]),_c('div',{staticClass:"box_basic box_main"},[_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_s num-3"},[_c('display-container',{attrs:{"section":14,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})])])}}])}),_c('display-container',{attrs:{"section":15,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})])])}}])}),_c('display-container',{attrs:{"section":16,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})])])}}])})],1)]),_c('div',{staticClass:"curtn_lst curtn_hot"},[_c('a',{staticClass:"curtn"},[_c('display-container',{attrs:{"section":18,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"name"},[_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])])}}])})],1),_c('a',{staticClass:"curtn"},[_c('display-container',{attrs:{"section":19,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"name"},[_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])])}}])})],1),_c('a',{staticClass:"curtn"},[_c('display-container',{attrs:{"section":20,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('p',{staticClass:"img"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"name"},[_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])])}}])})],1)])]),_c('div',{staticClass:"box_basic box_main section-2"},[_c('p',{staticClass:"tit_l"},[_vm._v("호캉스 CHECK-IN")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner"},[_c('display-container',{attrs:{"section":22,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])])}}])}),_c('display-container',{attrs:{"section":23,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])])}}])}),_c('display-container',{attrs:{"section":24,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{},[_c('a',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])])}}])})],1)]),_c('router-link',{staticClass:"btn_more drtn_right",attrs:{"to":"/contents/check-in"}},[_vm._v("CONTENTS 더 보기")])],1),_c('div',{staticClass:"box_basic box_main"},[_c('p',{staticClass:"tit_l"},[_vm._v("프레스티지고릴라 TV")]),_c('div',{staticClass:"scroller"},[_c('display-container',{attrs:{"section":25,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return (row.model_data)?_c('div',{staticClass:"video_player"},[_c('iframe',{attrs:{"src":_vm.getVideo(row.model_data.url),"title":component.getTitle(row),"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()}}],null,true)})],1),_c('router-link',{staticClass:"btn_more drtn_right",attrs:{"to":"/video"}},[_vm._v("VIDEO 더 보기")])],1),_c('div',{staticClass:"box_basic"},[_c('p',{staticClass:"tit_l"},[_vm._v("항공사별 모아보기")]),_c('ul',{staticClass:"lst_company"},_vm._l((_vm.getAirlineCorpDataTop6),function(airline,i){return _c('li',{key:airline.idx},[_c('router-link',{attrs:{"to":("/airline/detail/" + (airline.idx))}},[_c('p',{staticClass:"img_logo"},[(airline.logo_img)?_c('img',{attrs:{"src":airline.logo_img.url}}):_vm._e()]),_c('p',{staticClass:"com_name"},[_vm._v(_vm._s(airline.name))])])],1)}),0),_c('router-link',{staticClass:"btn_more drtn_right",attrs:{"to":"/airline"}},[_vm._v("항공사 더 보기")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }