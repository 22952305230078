<template>
  <page-layout class="home_con">

    <!-- 상단 큐레이션 -->
    <div class="box_main home_top">
			<display-container :section="6" align="left">
				<div slot-scope="{ component, row }" class="image">
					<img :src="component.getImage(row)" class="fitimg">
					<p class="date">{{component.getText2(row)}}</p>
					<div class="title_wrap">
						<p class="tit">{{component.getTitle(row)}}</p>
						<p class="s_txt">{{component.getText1(row)}}</p>
					</div>
				</div>
			</display-container>
    </div>

    <!-- 검색 -->
    <div class="box_main main_sch">
			<p class="tit_l">어디로 떠나세요?</p>
			<input type="text" class="ipt_sch" readonly placeholder="호텔 검색하기" @click="searchHotelDialog.main = true">
		</div>

  	<!-- #tag -->
    <div class="box_basic box_main con_circle">
    	<p class="tit_l">#프레스티지고릴라 #추천테마</p>
    	<div class="scroller">
				<div class="inner inner_xs num-5">
					<display-container :section="8" align="left" :no-auto-link="true">
						<div slot-scope="{ component, row }" class="container ctnr_xs">
							<hash-tag :tag-name="component.getTitle(row)">
								<p class="img_wrap bdr_circle">
									<img :src="component.getImage(row)" />
									==>{{component.getImage(row)}}<==
								</p>
								<p class="tit">{{component.getTitle(row)}}</p>
							</hash-tag>
						</div>
					</display-container>
					<display-container :section="9" align="left" :no-auto-link="true">
						<div slot-scope="{ component, row }" class="container ctnr_xs">
							<hash-tag :tag-name="component.getTitle(row)">
								<p class="img_wrap bdr_circle">
									<img :src="component.getImage(row)" />
								</p>
								<p class="tit">{{component.getTitle(row)}}</p>
							</hash-tag>
						</div>
					</display-container>
					<display-container :section="10" align="left" :no-auto-link="true">
						<div slot-scope="{ component, row }" class="container ctnr_xs">
							<hash-tag :tag-name="component.getTitle(row)">
								<p class="img_wrap bdr_circle">
									<img :src="component.getImage(row)" />
								</p>
								<p class="tit">{{component.getTitle(row)}}</p>
							</hash-tag>
						</div>
					</display-container>
					<display-container :section="11" align="left" :no-auto-link="true">
						<div slot-scope="{ component, row }" class="container ctnr_xs">
							<hash-tag :tag-name="component.getTitle(row)">
								<p class="img_wrap bdr_circle">
									<img :src="component.getImage(row)" />
								</p>
								<p class="tit">{{component.getTitle(row)}}</p>
							</hash-tag>
						</div>
					</display-container>
					<display-container :section="12" align="left" :no-auto-link="true">
						<div slot-scope="{ component, row }" class="container ctnr_xs">
							<hash-tag :tag-name="component.getTitle(row)">
								<p class="img_wrap bdr_circle">
									<img :src="component.getImage(row)" />
								</p>
								<p class="tit">{{component.getTitle(row)}}</p>
							</hash-tag>
						</div>
					</display-container>
				</div>
			</div>
    </div>
    
    <!-- 고스티 배너 -->
    <div class="banner_gosty">
      <div class="pc">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg">
        </a>
      </div>
      <div class="mo">
        <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
          <img src="https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg">
        </a>
      </div>
    </div>

    <!-- caution HOT -->
    <div class="box_basic box_main">
    	<!--<p class="tit_l">⚠️ Caution very HOT</p>-->
    	<div class="scroller">
				<div class="inner inner_s num-3">
					<display-container :section="14" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
						</div>
					</display-container>
					<display-container :section="15" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
						</div>
					</display-container>
					<display-container :section="16" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
						</div>
					</display-container>
				</div>
			</div>
			<!-- 콘텐츠 리스트 -->
			<div class="curtn_lst curtn_hot">
				<a class="curtn">
					<display-container :section="18" align="left">
						<div slot-scope="{ component, row }">
							<p class="img"><img :src="component.getImage(row)"></p>
							<div class="name">
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</div>
					</display-container>
				</a>
				<a class="curtn">
					<display-container :section="19" align="left">
						<div slot-scope="{ component, row }">
							<p class="img"><img :src="component.getImage(row)"></p>
							<div class="name">
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</div>
					</display-container>
				</a>
				<a class="curtn">
					<display-container :section="20" align="left">
						<div slot-scope="{ component, row }">
							<p class="img"><img :src="component.getImage(row)"></p>
							<div class="name">
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</div>
					</display-container>
				</a>
			</div>
    </div>


    <!-- 섹션2 - 체크인하러 왔습니다 -->
    <div class="box_basic box_main section-2">
    	<p class="tit_l">호캉스 CHECK-IN</p>
    	<div class="scroller">
				<div class="inner">
					<display-container :section="22" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate">{{component.getCate(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</a>
						</div>
					</display-container>
					<display-container :section="23" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate">{{component.getCate(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</a>
						</div>
					</display-container>
					<display-container :section="24" align="left">
						<div slot-scope="{ component, row }">
							<a class="container ctnr_l ctnr_dark">
								<p class="img_wrap">
									<img :src="component.getImage(row)" class="fitimg">
								</p>
								<div class="con_tit">
									<p class="cate">{{component.getCate(row)}}</p>
									<p class="title">{{component.getTitle(row)}}</p>
								</div>
							</a>
						</div>
					</display-container>
				</div>
			</div>
			<router-link to="/contents/check-in" class="btn_more drtn_right">CONTENTS 더 보기</router-link>
    </div>
    <!--// 섹션2 -->

    <!-- 섹션3 -프레스티지고릴라 TV  -->
    <div class="box_basic box_main">
    	<p class="tit_l">프레스티지고릴라 TV</p>
    	<div class="scroller">
				<display-container :section="25" align="left" :no-auto-link="true">
					<div slot-scope="{ component, row }" class="video_player" v-if="row.model_data">
						<iframe :src="getVideo(row.model_data.url)" :title="component.getTitle(row)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				</display-container>
			</div>
			<router-link to="/video" class="btn_more drtn_right">VIDEO 더 보기</router-link>
    </div>
    <!--// 섹션3 -->

    <!-- 섹션4 - 항공사 -->
		<div class="box_basic">
			<p class="tit_l">항공사별 모아보기</p>
			<ul class="lst_company">
				<li v-for="(airline, i) in getAirlineCorpDataTop6" :key="airline.idx">
					<router-link :to="`/airline/detail/${airline.idx}`">
						<p class="img_logo"><img :src="airline.logo_img.url" v-if="airline.logo_img"></p>
						<p class="com_name">{{airline.name}}</p>
					</router-link>
				</li>
			</ul>
			<router-link to="/airline" class="btn_more drtn_right">항공사 더 보기</router-link>
		</div>
		<!--// 섹션4 -->

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import HashTag from '../components/HashTag'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
	import { mapGetters } from 'vuex'
  export default {
    name: 'Home',
    components: {
      PageLayout,
      DisplayContainer,
      HashTag
    },
    metaInfo () {
      return {
        title: `${this.getAppName} : 럭셔리 항공·호텔 리뷰`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `럭셔리 항공·호텔 리뷰 | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        chartData: {
          'top100': [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
        },
        loadedCnt: 0,
      }
    },
		beforeMount () {
      this.$store.dispatch('setAirlineCorpData')
		},
    computed: {
      ...CommonComputed,
			...mapGetters([
        'getAirlineCorpData',
        'getAirlineCorpDataCnt',
        'searchHotelDialog'
			]),
      getAccess () {
        return 'app'
      },
      isMobile () {
        return this.getAccess === 'mobile'
      },
      getAirlineCorpDataTop6 () {
        return this.getAirlineCorpData.filter((row, i) => i < 12)
			}
    },
    methods: {
      ...CommonMethods,
      toggleTermsDialog (flag) {
        this.$store.dispatch('setOpenTermsDialog', flag)
      },
			togglePolicyDialog (flag) {
        this.$store.dispatch('setOpenPolicyDialog', flag)
      }
    }
  }
</script>

<style scoped>
	.box_main {
		background: #fff;
    padding: 40px;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    color: #222;
	}
	.box_main.home_top {

	}
	.home_top .image {
		width: 100%;
		height: 420px;
		position: relative;
		padding: 25px;
	}
	.home_top .image:after {
		content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9));
	}
	.home_top .image img {

	}
	.home_top .image .date {
		font-size: 14px;
		color: #fff;
		text-transform: uppercase;
		position: absolute;
		top: 30px;
		left: 30px;
		z-index: 1;
	}
	.home_top .image .title_wrap {
		color: #fff;
		text-transform: uppercase;
		position: absolute;
    left: 30px;
    bottom: 30px;
		z-index: 1;
	}
	.home_top .image .title_wrap .tit {
		font-size: 54px;
		font-weight: 700;
	}
	.home_top .image .title_wrap .s_txt {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: -0.02em;
	}
  .main_sch {
    padding: 30px 40px;
  }
	.main_sch input[type=text] {
		width: 100%;
		height: 50px;
		background: #f1f1f1;
		border: 0;
		line-height: 50px;
		padding-left: 45px;
		color: #888;
		background-size: 18px 18px;
		background-position: 16px center;
		background-repeat: no-repeat;
	}
	.main_sch input[type=text]::placeholder {
		font-size: 17px;
	}
	.main_sch input[type=text].ipt_sch {
		background-image: url('/statics/img/ico_sch_sch.png');
	}
	.main_sch .btn_confirm {
		font-size: 16px;
		font-weight: 700;
		color: #fff;
		background: #0e6057;
		display: block;
		text-align: center;
		width: 100%;
		height: 50px;
		line-height: 50px;
	}
  .home_con .ctnr_s {
    width: calc((960px - 100px) / 3);
  }
  .home_con .ctnr_s .img_wrap {
    height: 295px;
  }
	.home_con .curtn_lst.curtn_hot {
		margin-top: 25px;
	}
	.home_con .curtn_lst.curtn_hot a {
		margin-right: 10px;
	}
	.home_con .curtn_lst.curtn_hot a:last-child {
		margin-right: 0;
	}
	.home_con .curtn_lst .curtn {
		display: inline-block;
		padding: 7.5px 0;
    width: calc((960px - 100px) / 3);
	}
	.home_con .curtn_lst .curtn .full-width > div > div {
		position: relative;
	}
	.home_con .curtn_lst .curtn .full-width > div > div:after {
		content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
	}
	.home_con .curtn_lst .curtn .img {
		height: 360px;
	}
	.home_con .curtn_lst .curtn .img img {
		width: 100%;
    height: 100%;
    object-fit: cover;
	}
	.home_con .curtn_lst .curtn .name {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
		font-size: 26px;
		font-weight: 300;
    color: #fff;
		line-height: 1.35em;
		letter-spacing: -0.05em;
    text-align: center;
    z-index: 2;
	}
	.home_con .curtn_lst .curtn .name_en {
		font-size: 16px;
		font-weight: 400;
    color: rgba(255,255,255,0.8);
    line-height: 1.6em;
		letter-spacing: 0;
    margin-top: 10px;
	}
	.box_main.section-2 .inner {
		display: inline-block;
		position: relative;
		width: 100%;
	}
	.box_main.section-2 .inner .display-container {
		margin: 0;
	}
	.box_main.section-2 .inner .display-container:first-child {
		width: 100%;
	}
	.box_main.section-2 .inner .display-container:nth-child(2) {
		width: calc(50% - 5px);
		margin-right: 5px;
		float: left;
	}
	.box_main.section-2 .inner .display-container:nth-child(3) {
		width: calc(50% - 5px);
		margin-left: 5px;
		float: right;
	}
	.box_main.section-2 .inner .display-container .container {
		width: 100%;
	}
	.box_main.section-2 .inner .display-container {
		margin-top: 10px;
	}
	.box_main.section-2 .inner .display-container .img_wrap {
		height: 28vmin;
	}
	.box_main.section-2 .inner .display-container:first-child .img_wrap {
		margin-top: 0;
    height: 44vmin;
	}
	.box_main.section-2 .display-container:nth-child(2) .con_tit .title,
	.box_main.section-2 .display-container:nth-child(3) .con_tit .title {
		font-size: 18px;
    font-weight: 400;
    line-height: 1.4em;
		word-break: break-all;
	}
	.box_main.section-2 .inner .display-container:first-child .con_tit  {
		top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
	}
	.box_main.section-2 .inner .display-container:first-child .con_tit .cate  {
		font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 14px;
    display: inline-block;
	}
  .box_main.section-2 .inner .display-container:first-child .con_tit .title {
    font-size: 34px;
    font-weight: 300;
    line-height: 1.2em;
	}
	.box_main.section-2 .con_tit .cate {
		font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 14px;
    display: inline-block;
	}
	.box_main.section-2 .inner .con_tit .title {
		overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	}
  .box_main.con_circle .scroller .inner .display-container {
    margin-right: 20px;
  }
  .box_main.con_circle .scroller .inner .display-container:last-child {
    margin-right: 0;
  }
  .box_main.con_circle .scroller .inner .container{
    cursor: pointer;
  }
  .box_main.con_circle .scroller .inner .container .img_wrap {
    width: calc((960px - 160px) / 5);
    height: calc((960px - 160px) / 5);
  }
	.box_main .scroller {

	}
	.box_main .video_player {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
	}
	.box_main .video_player iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.box_main .btn_more {
		/*margin-top: 10px;*/
	}
	.home_con .lst_company li {
		width: calc((960px - 180px) / 6);
		display: inline-block;
		margin: 0 20px 30px 0;
		text-align: center;
	}
	.home_con .lst_company li:nth-child(6n) {
		margin-right: 0;
	}
	.home_con .lst_company li .img_logo {
		width: 100%;
		height: calc((960px - 180px) / 6);
		border: 1px solid #e5e5e5;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.home_con .lst_company li .img_logo img {
		width: 86%;
	}
	.home_con .lst_company li .com_name {
		font-size: 15px;
		color: #222;
		letter-spacing: -0.05em;
		margin-top: 8px;
	}
</style>
