<template>
  <page-layout class="chart_view fr-view">

		<!-- 1위 영역 -->
		<div class="box_basic box_1st">
			<div class="img_1st"><img :src="getHotelMainImage(currentTopChartData)" class="fitimg" v-if="getHotelMainImage(currentTopChartData)"></div>
			<div class="title">
				<h4>{{getTitle}}</h4>
				<!--<ul class="tab">
          <li><a>Monthly</a></li>
				</ul>-->
				<!--<ul class="tab" v-show="getConfigData.term">
					<li :class="{on : term === 'weekly'}"><a @click="changeTerm('weekly')">Weekly</a></li>
					<li :class="{on : term === 'monthly'}"><a @click="changeTerm('monthly')">Monthly</a></li>
				</ul>-->
				<p @click="toggleCalendarDialog(true)"><i v-show="calendar"><!--Week of January 24, 2021--> {{ getCurrentCalendarLabel }}</i></p>
			</div>
			<div class="hotel_info" @click="gotoHotel(currentTopChartData)">
				<span class="num"><b>1</b>위</span>
				<p class="h_name_kr">{{currentTopChartData.name}}</p>
				<p class="h_name_en">{{currentTopChartData.name_eng}}</p>
			</div>
			<div class="info">
				<p @click="isOpenInfoDialog = true">i</p>
			</div>
		</div>
		<!-- //1위 영역 -->

		<!-- 구글광고 320*50 -->
		<!--<div class="lst_ad_wrap">
			<div class="ad_google ad_320x50">ad 320*50</div>
		</div>-->


		<!-- 순위 컨테이너 -->
		<div v-for="(hotel, index) in currentChartData" :key="index">

			<!-- 콘텐츠[1] -->
			<div class="con_add box_basic" v-if="index > 0 && index%5 === 0">
        <p class="tit_l">소중한 사람과 🌹 따뜻+힐링 홀리데이 🌹</p>
				<div class="scroller">
					<div class="inner inner_s">
						<display-container :section="53" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="54" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="55" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="56" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="57" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
					</div>
				</div>
			</div>
			<!--// 콘텐츠[1] -->
			
			<!-- 고스티 배너 -->
      <div class="banner_gosty" v-if="index > 0 && index%5 === 0">
        <div class="pc">
          <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
            <img src="https://cdn.prestigegorilla.net/post/2272/contents/19775/gosty_pc.jpg">
          </a>
        </div>
        <div class="mo">
          <a href="https://www.gosty.co.kr/boardingpass?utm_source=prestigegorilla&utm_medium=banner&utm_campaign=web" target="_blank">
            <img src="https://cdn.prestigegorilla.net/post/2272/contents/19774/gosty_m.jpg">
          </a>
        </div>
      </div>

			<!-- 콘텐츠[2] -->
			<div class="con_add box_basic" v-if="index > 0 && index%15 === 0">
				<p class="tit_l">🏢 도심에서의 호캉스 어때요?</p>
				<div class="scroller">
					<div class="inner inner_s">
						<display-container :section="59" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="60" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="61" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="62" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
						<display-container :section="63" align="left">
							<div slot-scope="{ component, row }" class="container ctnr_s">
								<p class="img_wrap">
									<img :src="component.getImage(row)">
								</p>
								<p class="name_kr">{{component.getName(row)}}</p>
								<p class="name_en">{{component.getNameEng(row)}}</p>
							</div>
						</display-container>
					</div>
				</div>
			</div>
			<!--// 콘텐츠[2] -->

			<div class="box_basic ctnr_chart">
				<div v-if="hotel.name" @click="gotoHotel(hotel)" class="chart_img img_wrap">
          <router-link :to="`/hotel/detail/${hotel.idx}`" v-if="hotel.is_visible">
            <img :src="getHotelMainImage(hotel)" />
          </router-link>
          <img :src="getHotelMainImage(hotel)" v-else />
        </div>
				<p class="rank">
					<b>{{hotel.ranking}}</b>위
					<i v-if="hotel.compared > 0"><em>▲</em> {{Math.abs(hotel.compared)}}</i>
					<i v-else-if="hotel.compared < 0"><em>▼</em> {{Math.abs(hotel.compared)}}</i>
					<i v-else-if="!hotel.compared"><em>new</em></i>
					<i v-else><em>&#8212;</em></i>
					<span class="chkin_rev" v-if="hotel.is_review">체크인리뷰</span>
				</p>
				<a @click="gotoHotel(hotel)" class="contents" v-if="hotel.name && hotel.is_visible">
					<p class="h_name_kr">{{hotel.name}}</p>
					<p class="h_name_en">{{hotel.name_eng}}</p>
					<p class="loc">{{hotel.region_txt}}<i>{{hotel.star_rating}}성급</i></p>
				</a>
        <a class="contents" v-else-if="hotel.name && !hotel.is_visible" style="cursor: default">
          <p class="h_name_kr">{{hotel.name}}</p>
          <p class="h_name_en">{{hotel.name_eng}}</p>
          <p class="loc">{{hotel.region_txt}}<i>{{hotel.star_rating}}성급</i></p>
        </a>
        <a v-else>
          <div class="name_area">
            <p class="no_cmt">{{hotel.no_cmt}}</p>
          </div>
        </a>
				<add-wish-list :hotel="hotel" />
			</div>
		</div>

		<!-- 기간선택 modal -->
		<q-dialog v-model="isOpenCalendarDialog"
							transition-show="slide-up"
							transition-hide="slide-down"
		>
			<q-layout class="calendar_modal">
				<q-header class="header">
					<q-toolbar>
						<q-toolbar-title class="q-ml-sm">
							<span>기간 선택</span>
						</q-toolbar-title>
						<q-btn dense flat icon="close" @click="toggleCalendarDialog(false)" />
					</q-toolbar>
					<q-card-section v-if="calendarNotice" class="bg-grey-3">
						{{ calendarNotice }}
					</q-card-section>
				</q-header>
				<q-page-container class="calendar_modal_lst scroll">
					<q-list>
						<div v-for="(item, index) in getCalendarList" :key="index">
							<q-separator v-if="index > 0" color="grey-3" />
							<q-item class="q-pa-none" v-if="isVisibleFirstRow(index)">
								<q-item-section>
									<div>
										<a @click="changeCalendar(item)" :class="{'on text-secondary': item.date === getCurrentCalendarDate}">{{item.label}}</a>
									</div>
								</q-item-section>
							</q-item>
						</div>
					</q-list>
				</q-page-container>
			</q-layout>

		</q-dialog>

		<!-- i버튼 modal -->
		<q-dialog v-model="isOpenInfoDialog">
			<div class="info_pop">
				<p class="txt-1">주간 동안의 호텔상세페이지뷰+예약전환수를<br>반영한 랭킹입니다.</p>
				<p class="txt-2">HOTEL HOT100은 실시간으로 업데이트 됩니다.<br>(단, 매주 월요일 00시~06시는 업데이트 되지 않습니다.)</p>
			</div>
		</q-dialog>

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import AddWishList from '../components/AddWishList'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import {Dialog, Popover} from 'element-ui'
  import {QDialog, QBtn, QBtnGroup, Loading, QSlideTransition, QDate, QCard, QSeparator} from 'quasar'
  import { date, scroll } from 'quasar'
  const qDate = date
  export default {
    name: "ChartView",
    components: {
      SubPageLayout,
      PageLayout,
      DisplayContainer,
      AddWishList,
      QDialog, QBtn, QBtnGroup, QCard, QDate, QSeparator,
      [Dialog.name]: Dialog,
      [Popover.name]: Popover,
      Loading,
      QSlideTransition,
    },
    metaInfo () {
      return {
        title: `${this.getSeoTitle} | ${this.getAppName}`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'image', name: 'image', content: '/statics/seo/seo.jpg'},
          {vmid: 'og:title', property: 'og:title', content: `${this.getSeoTitle} | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '프레스티지고릴라 에디터가 직접 경험한 럭셔리 항공·호텔 리뷰, 여행팁, 다양한 뉴스와 프로모션 소식 등을 확인해보세요.'},
          {vmid: 'og:image', property: 'og:image', content: '/statics/seo/seo.jpg'}
        ]
      }
    },
    data () {
      return {
        loading: false,
        loadded: false,
        currentChartData: [],
        currentTopChartData: {
          name: '',
          main_img: ''
        },
        term: 'monthly',
        configs: {
          hot100:   { title: 'HOTEL HOT 100', hotel_gb: 'DOMESTIC', limit: 100, dropdown: false, term: true, diaolog: false, update: true }
        },
        submenus: {
          hot100: { menus: [] }
        },
        dialogs: {
          hot100: { open: false, title: '', idx: '', idx2: '', seo_title: '[실시간차트]', seo_subtitle: 'HOT100', seo_desc: '실시간 호텔 인기차트', seo_img: 'seo_chart_hot100.jpg' },
          alert: { open: false },
          chart: { open: false },
          date: { open: false }
        },
        dropdowns: {
          hot100: { open: false }
        },
        dialogChartKey: 'realtime',
        calendarValue: '',
        calendarValueBack: '',
        calendarNotice: '',
        calendarStartDate: '2021-06-01',
        calendarList: {
          weekly: [],
          monthly: []
        },
        calendarLocale: {
          days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
          daysShort: ['일', '월', '화', '수', '목', '금', '토'],
          months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
          monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
        },
        calendarListOpen: false,
        calendar: {},
        temps: [],
        isOpenInfoDialog: false
      }
    },

    watch: {
      'getChartMenu' (to, from) {
        this.applyRouteQuery
        this.getCalendarListForDB()
        this.calendarListOpen = false
      },
      // 'getCalendarList' (val) {
      //   if (val.length > 0 && this.loading === false) {
      //     this.getInitHotelData()
      //   }
      // }
    },
    created () {
      this.applyRouteQuery
      this.getCalendarListForDB()

      document.body.classList.add('clear-ios')
    },
		destroyed () {
      document.body.classList.remove('clear-ios')
		},
		mounted () {
      this.$nextTick(() => {
        this.getInitHotelData()
      })
		},
    computed: {
      ...CommonComputed,
      getAccess () {
        return 'app'
      },
      applyRouteQuery () {
        const query = this.$route.query
        this.calendarValue = this.$route.query.date ? this.$route.query.date : this.getCurrentDateForChart

        if (this.$route.query.term) this.term = this.$route.query.term
        if (query.sub) {
          this.getDialogData.idx = query.sub
          const subItem = this.getSubmenuData.menus.find(row => row.idx == query.sub)
          if (subItem) this.selectDialog(subItem)
        }
      },
      getDateKr () {
        const rtn = new Date(this.calendarValue)
        return this.term === 'monthly' ? qDate.formatDate(rtn, 'YYYY년 M월') : qDate.formatDate(rtn, 'YYYY년 M월 D일')
      },
      getDatePeriodKr () {
        const s = qDate.formatDate(this.getFirstDate, 'YYYY.M.D')
        const e = qDate.formatDate(this.getLastDate, 'YYYY.M.D')
        return `${s} ~ ${e}`
      },
      getCalendarMode () {
        return this.term === 'monthly' ? 'Months' : 'Calendar'
      },
      getFirstDate () {
        this.calendarNotice = ''
        const dt = new Date(this.calendarValue)
        let rtn
        if (this.term === 'monthly') {
          rtn = qDate.startOfDate(dt, 'month')
        } else {
					const w = dt.getDay()
					rtn = date.addToDate(dt, {days: w === 0 ? -6 : (w - 1) * -1})
        }
        return rtn
      },
      getLastDate () {
        const today = new Date()
        const dt = new Date(this.calendarValue)
        let rtn
        if (this.term === 'monthly') {
          const endday = qDate.endOfDate(dt, 'month')
          rtn = qDate.getDateDiff(endday, today, 'days') < 0 ? endday : today
        } else {
					const w = dt.getDay()
					const endday = qDate.addToDate(dt, {days: w === 0 ? w : ((w - 1) * -1) + 6})
					rtn = qDate.getDateDiff(endday, today, 'days') < 0 ? endday : today
        }
        return rtn
      },
      getSeoTitle () {
        return this.getDialogData ? this.getDialogData.seo_title + '' + this.getDialogData.seo_subtitle : ''
      },
      getSeoDescription () {
        return this.getDialogData ? this.getDialogData.seo_desc : ''
      },
      getSeoUrl () {
        return window.location.protocol + '//' + window.location.hostname + window.location.pathname + window.location.search
      },
      getSeoImage () {
        return this.getDialogData ? window.location.protocol + '//' + window.location.hostname + '/statics/seo/' + this.getDialogData.seo_img : ''
      },
      getChartMenu () {
        return this.$route.params.view
      },
      getConfigData () {
        return this.configs[this.getChartMenu]
      },
      getSubmenuData () {
        return this.submenus[this.getChartMenu]
      },
      getDialogData () {
        return this.dialogs[this.getChartMenu]
      },
      getDropdownData () {
        return this.dropdowns[this.getChartMenu]
      },
      getTitle () {
        const rtn = this.getCurrentSubmenuData && this.getCurrentSubmenuData.title ? this.getCurrentSubmenuData.title : this.getConfigData.title
        this.$store.dispatch('setCurrentChartTitle', rtn)
        return rtn
      },
      getCalendarList () {
        return this.calendarList[this.getCurrentTerm]
      },
      getThisChartKey () {
        const key = this.getChartMenu + '_' + this.getCurrentCalendarDate + this.getCurrentSubmenuValue + this.getCurrentTerm
        return key
      },
      getCurrentTerm () {
        let term = this.getConfigData && this.getConfigData.term ? this.term : 'monthly'
        return term
      },
      getCurrentCalendar () {
        let rtn, arr, s, ss, e, ee
        if (this.$route.query.date) {
          const t = Number(this.$route.query.date.split('-').join(''))
          let acc = []
          this.getCalendarList.map(cur => {
            arr = cur.label.split('~')
            ss = arr[0].trim().split('.')
            s = Number(ss[0] + (Number(ss[1])+100).toString().substr(-2) + (Number(ss[2])+100).toString().substr(-2))
            ee = arr[1].trim().split('.')
            e = Number(ee[0] + (Number(ee[1])+100).toString().substr(-2) + (Number(ee[2])+100).toString().substr(-2))
            // console.log(s,'<=',t,'&&',t,'<=',e)
            if (s <= t && t <= e) {
              acc.push(cur)
            }
          })
          rtn = acc.length > 0 ? acc[0] : this.getFirstRowByCalendarList
        } else {
          rtn = Object.keys(this.calendar).length > 0 ? this.calendar : this.getFirstRowByCalendarList
        }
        return this.calendar = rtn
      },
      getCurrentCalendarDate () {
        return this.getCurrentCalendar.date ? this.getCurrentCalendar.date : ''
      },
      getCurrentCalendarLabel () {
        const rtn = this.getCurrentCalendar.label ? this.getCurrentCalendar.label : ''
        this.$store.dispatch('setCurrentCalendarLabel', rtn)
        return rtn
      },
      getCurrentSubmenuData () {
        return this.getCurrentSubmenuValue ? this.getSubmenuData.menus.find(row => row.idx == this.getCurrentSubmenuValue) : this.getSubmenuData.menus[0]
      },
      getCurrentSubmenuValue () {
        return this.$route.query.sub ? this.$route.query.sub : this.getDialogData.idx
      },
      getFirstRowByCalendarList () {
        if (this.getCalendarList.length > 0) {
          return this.getCalendarList[0]
        } else {
          return []
        }
      },
      isSeletedSubmenu () {

        // 예외처리 서브메뉴를 선택하지 않은경우 모달창이 아닌 해당서브차트의 첫번째 차트 노출 (2019-10-20)
        if (!this.$route.query.sub) {
          if (this.getCurrentSubmenuData) this.selectDialog(this.getCurrentSubmenuData)
          return true
        }

        const rtn = this.$route.query.sub !== undefined
        // console.log(rtn)
        return rtn
      },
      isOpenAllChartDialog: {
        get () {
          return this.$store.getters.isOpenAllChartDialog
        },
        set (flag) {
          this.$store.dispatch('setOpenAllChartDialog', flag)
        }
      },
      isOpenCalendarDialog: {
        get () {
          return this.$store.getters.isOpenCalendarDialog
        },
        set (flag) {
          this.$store.dispatch('setOpenCalendarDialog', flag)
        }
      }
    },
    methods: {
      ...CommonMethods,
      getInitHotelData () {
        if (Object.keys(this.calendar).length > 0) {
          if (this.getChartMenu === 'hot100') {
            this.getChartData()
            this.dialogChartKey = 'realtime'
          } else {
            this.dialogChartKey = this.getChartMenu
            // if (!this.getDialogData.idx) {
            //   this.getDialogData.open = true
            // } else {
              this.getChartData()
            // }
          }
        }
      },
      getChartData () {

        // 예외처리
        if (this.getChartMenu !== 'hot100') {
          this.term = 'monthly'

          const query = this.cloneData(this.$route.query)
          const dt = new Date(this.calendarValue)
          const today = new Date()
          const diff = qDate.getDateDiff(dt, today, 'months')

          if (diff === 0) query.date = this.calendarValue = qDate.formatDate(qDate.addToDate(dt, {month: -1}), 'YYYY-MM-DD')
          if (!query.sub) {
            const subItem = this.getSubmenuData.menus[0]
            if (this.getChartMenu === 'corp') {
              query.sub = this.getDialogData.idx = 1
            } else {
              query.sub = this.getDialogData.idx = subItem.idx
              if (subItem.idx2) this.getDialogData.idx2 = subItem.idx2
            }
          }

          if (query !== this.$route.query) {
            this.$router.replace({ path: this.$route.path, query: query })
          }
        }

        if (this.loading) return

        let chartData = this.$store.getters.getChartData
        if (chartData[this.getThisChartKey] && chartData[this.getThisChartKey].length > 0) {
          this.setCurrentChartData(chartData[this.getThisChartKey])
          return
        }

        this.loading = true
        // setTimeout(() => {
          Loading.show({
            spinnerColor: 'white',
            spinnerSize: 40,
            delay: 300,
          })
        // })

        const params = {
          params: {
            date: this.calendar.date,
            term: this.getCurrentTerm,
            hotel_gb: this.getConfigData.hotel_gb,
            limit: this.getConfigData.limit,
            chart: this.getChartMenu,
            idx: this.getDialogData.idx,
            idx2: this.getDialogData.idx2,
          }
        }

        this.$axios.get('/charts/ranking', params).then(rs => {
          if (rs.data.length > 0) {
            this.setCurrentChartData(rs.data)
            this.$store.dispatch('setChartData', {key: this.getThisChartKey, data: rs.data})
            this.loading = false
            this.loadded = true
            Loading.hide()
          } else {
            this.dialogs.alert.open = true
            this.loading = false
            this.loadded = true
            Loading.hide()
            // this.changeCalendar(this.getCalendarList[0])
          }
        })
      },
      setCurrentChartData (data) {
        this.currentChartData = this.cloneData(data)
        this.currentTopChartData = this.cloneData(data)[0]
      },
      getCalendarListForDB () {
        const action = '/charts/calendar'
        const data = { params: { chart: this.getChartMenu } }
        this.$axios.get(action, data).then(rs => {
          this.calendarList = rs.data
          this.calendar = this.getFirstRowByCalendarList

          if (this.getChartMenu === 'hot100') {
            if (this.loading === false) {
              this.getChartData()
            }
          } else if (!this.getDialogData.idx) {
            // this.getDialogData.open = true // 선택된 서브메뉴가 없으면 서브모달창 오픈 (자동선택으로 변경)
            // this.loading = false
            // this.loadded = true
            this.getChartData()
          }
        }).catch(() => {
          this.calendar = this.getFirstRowByCalendarList
        })
      },
      getHotelMainImage (path) {
        // return path && path.main_img ? path.main_img.url : ''
        return path && path.main_img ? JSON.parse(path.main_img).url : ''
      },
      selectDialog (item, key) {
        this.getDialogData.title = item.name
        this.getDialogData.idx = item.idx
        this.getDialogData.idx2 = item.idx2
        this.getDialogData.open = false
        this.getDialogData.seo_subtitle = item.title
        this.getDialogData.seo_desc = item.desc
        this.getDialogData.seo_img = item.img
        this.getDropdownData.open = false
        let query = this.cloneData(this.$route.query)
        query.sub = item.idx
        // delete query.date
        const path = key ? `/chart/${key}` : this.$route.path
        this.$router.replace({path: path, query: query})
        setTimeout(() => {
          this.getChartData()
        }, 200)
      },
      selectChart (item) {
        // this.$router.replace(`/chart/${item.key}`)
        this.$router.replace(`/chart/${item}`)
        this.getCalendarListForDB()
      },
      changeCalendar (item) {
        // const el = document.getElementById('q-app')
        // const target = getScrollTarget(el)
        // setScrollPosition(target, 0, 100)

        // console.log(this.$router)
        // this.$router.options.scrollBehavior = function () {
        //   return { x: 0, y: 0 }
        // }
        // return

        this.calendar = this.cloneData(item)
        let query = this.cloneData(this.$route.query)
        query.date = item.date
        this.$router.replace({path: this.$route.path, query: query})
        this.calendarListOpen = false
        this.getChartData()
      },
      changeTerm (term) {
        if (this.term !== term) {
          this.term = term
          let query = this.cloneData(this.$route.query)
          query.term = term
          this.$router.replace({path: this.$route.path, query: query})

          this.changeCalendar(this.getFirstRowByCalendarList)

          this.getChartData()
        }
      },
      getUpDownClass (obj) {
        return obj && obj.compared > 0 ? 'up' : obj && obj.compared < 0 ? 'down' : 'default'
      },
      isVisibleFirstRow (i) {
        return true
        // return this.getCurrentTerm === 'monthly'
        //   && i === 0
        //   && (this.getChartMenu !== 'hot100' &&  this.getChartMenu !== 'check100')
        //
        //   ? false : true
      },
      gotoHotel (hotel) {
        if (hotel.name && hotel.is_visible) {
          this.$router.push({name: 'HotelDetail', params: {idx: hotel.hotel_idx}})
        }
      },
      toggleDropdown () {
        this.getDialogData.open = true
        this.calendarListOpen = false
      },
      getAbleToSelectDate (dt) {
        dt = qDate.formatDate(dt, 'YYYY-MM-DD')
        const s = this.calendarStartDate
        const e = qDate.formatDate(new Date(), 'YYYY-MM-DD')
        return dt >= s && dt <= e
      },
      getEventToSelectDate (dt) {
        dt = qDate.formatDate(dt, 'YYYY-MM-DD')
        const s = qDate.formatDate(this.getFirstDate, 'YYYY-MM-DD')
        const e = qDate.formatDate(this.getLastDate, 'YYYY-MM-DD')
        return dt >= s && dt <= e
      },
      selectCalendarDate (value, reason, details) {
        const startdate = this.calendarStartDate
        const enddate = qDate.formatDate(this.getLastDate, 'YYYY-MM-DD')
        if (startdate > value || enddate < value) {
          setTimeout(() => {
            this.calendarValue = this.cloneData(this.calendarValueBack)
            return false
          })
          alert('선택할 수 없는 날짜입니다')
          return false
        }
      },
      setCalendarDate () {
        const startdate = this.calendarStartDate
        const enddate = qDate.formatDate(this.getLastDate, 'YYYY-MM-DD')
        if (startdate > this.calendarValue || enddate < this.calendarValue) {
          setTimeout(() => {
            this.calendarValue = this.cloneData(this.calendarValueBack)
            return false
          })
          alert('선택할 수 없는 날짜입니다')
          return false
        }
        let query = this.cloneData(this.$route.query)
        query.date = this.cloneData(this.calendarValue)
        this.$router.replace({path: this.$route.path, query: query})
        this.getChartData()
        this.dialogs.date.open = false
      },
      cancelCalendarDate () {
        this.dialogs.date.open = false
        this.calendarValue = this.cloneData(this.calendarValueBack)
      },
      isOnMain (key, term) {
        return this.getChartMenu === key && this.getCurrentTerm === term
      },
      isOnSub (item, key) {
        return this.getChartMenu === key && (item && item.idx == this.$route.query.sub )
      },
      toggleAllChartDialog (flag) {
        this.$store.dispatch('setOpenAllChartDialog', flag)
      },
      toggleCalendarDialog (flag) {
        this.$store.dispatch('setOpenCalendarDialog', flag)
        this.getDropdownData.open = false
      }
    }
  }
</script>

<style scoped>
	.box_1st {
		height: 420px;
		overflow: hidden;
		position: relative;
	}
	.box_1st .img_1st {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.box_1st .img_1st:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.25);
	}
	.box_1st .title {
		position: relative;
		top: 40px;
		z-index: 2;
		color: #fff;
		text-align: center;
	}
	.box_1st .title h4 {
		font-size: 38px;
		font-weight: 700;
	}
	.box_1st .title .tab {
		display: inline-block;
		font-size: 13px;
		margin: 20px 10px 0 0;
	}
	.box_1st .title .tab li {
		display: inline-block;
		margin-right: 12px;
	}
	.box_1st .title .tab li a {
		color: rgba(255,255,255,0.5);
	}
	.box_1st .title .tab li.on a {
		color: rgba(255,255,255,1.0);
		border-bottom: 1px solid rgba(255,255,255,0.5);
		padding-bottom: 2px;
	}
	.box_1st .title p {
		display: inline-block;
		font-size: 13px;
		margin-top: 20px;
    cursor: pointer;
	}
	.box_1st .title p i:after {
		content: "∟";
		transform: rotate(-45deg) translate(-0%, -15%);
		font-size: 10px;
		font-weight: 900;
		margin-left: 10px;
		display: inline-block;
	}
	.box_1st .hotel_info {
		position: absolute;
		left: 100px;
		right: 100px;
		bottom: 50px;
		z-index: 2;
		color: #fff;
		box-sizing: border-box;
    cursor: pointer;
    text-align: center;
	}
	.box_1st .hotel_info .num {
		position: absolute;
		left: 50%;
    transform: translate(-50%, -130%);
		display: block;
		width: 60px;
		height: 60px;
		background: rgba(0,0,0,0.4);
    border: 1px solid rgba(255,255,255,0.35);
		font-size: 14px;
		padding-top: 12px;
		text-align: center;
		box-sizing: border-box;
    line-height: 2.95em;
	}
	.box_1st .hotel_info .num b {
		font-size: 28px;
		font-weight: 500;
    line-height: 1.2em;
	}
	.box_1st .hotel_info .h_name_kr {
		font-size: 28px;
		font-weight: 400;
		line-height: 1.2em;
	}
	.box_1st .hotel_info .h_name_en {
		font-size: 15px;
		font-weight: 300;
		margin-top: 6px;
		overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
		word-break: break-all;
	}
	.box_1st .info {
		position: absolute;
		right: 32.5px;
		bottom: 30px;
    cursor: pointer;
	}
	.box_1st .info p {
		font-size: 15px;
		color: #fff;
		display: block;
		width: 20px;
		height: 20px;
		border: 1px solid rgba(255,255,255,0.4);
		border-radius: 100%;
		text-align: center;
	}
  .box_1st .info:hover {
    opacity: 1.0;
  }
  .box_1st .info:hover ~ .info_pop {
    opacity: 1.0;
    visibility: visible;
  }
  .info_pop {
    position: absolute;
    top: 50%;
		left: 50%;
    transform: translate(-50%, -150%);
    background: rgba(0,0,0,0.8);
    border-radius: 10px;
    text-align: center;
    letter-spacing: -0.03em;
    padding: 20px;
  }
  .info_pop .txt-1 {
    color: #fff;
		font-size: 0.95em;
		margin-bottom: 8px;
  }
  .info_pop .txt-2 {
    color: #aaa;
		font-size: 0.85em;
  }
	.lst_ad_wrap {
    /*margin-top: 12px;*/
	}
	.calendar_modal {
		position: absolute;
    bottom: 0;
    max-height: calc(32px * 10);
    min-height: calc(32px * 6) !important;
    background-color: #fff;
		border-radius: 0;
  }
	.calendar_modal .header {
		background: #fff;
		color: #222;
		border-bottom: 1px solid #ddd;
  }
	.calendar_modal .header span {
		position: absolute;
		top: 18px;
		left: 0;
		right: 0;
		text-align: center;
		height: 60px;
		font-size: 0.75em;
		font-weight: 500;
		letter-spacing: -0.05em;
  }
  .calendar_modal .calendar_modal_lst {
    max-height: calc(32px * 10);
    min-height: calc(32px * 6);
  }
  .calendar_modal .calendar_modal_lst a {
    display: block;
    width: 100%;
		padding: 15px 0;
    cursor: pointer;
    color: #999;
    text-align: center;
  }
  .calendar_modal .calendar_modal_lst a:hover {
		color: #222;
    background-color: #f6f6f6;
  }
  .calendar_modal .calendar_modal_lst .on {
    font-weight: 500;
		color: #00897a !important;
  }
  .chart_view .box_basic .scroller .inner_s .display-container {
    width: calc((100% - 40px) / 5);
  }
</style>
