<script>
  import ContentsEditor from 'src/pc/pages/ContentsEditor'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import PostContainer from '../components/PostContainer'
  export default {
    extends: ContentsEditor,
    components: {
      PageLayout,
      SubPageLayout,
      PostContainer,
    }
  }
</script>

<style scoped>
  .editor_info {
    margin-top: 1.5px;
    text-align: center;
  }
  .editor_info .img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
  }
  .editor_info .img img {
    
  }
  .editor_info .name {
    font-size: 18px;
    font-weight: 500;
    color: #222;
    margin-top: 22px;
  }
  .editor_info .slogan {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.03em;
    color: #888;
    margin-top: 10px;
  }
  .editor_info .count {
    font-size: 13px;
    color: #888;
    font-style: italic;
    margin-top: 10px;
  }
  .editor_info .count b {
    color: #222;
    font-weight: 500;
  }
</style>