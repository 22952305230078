<template>
  <!--<div @click="putWishList()" class="like" :class="{ on: isOn }">-->
    <!--<slot />-->
  <!--</div>-->
</template>

<script>
  import CommoMethods from 'src/_mixins/CommonMethods'
  import { mapGetters } from 'vuex'
  export default {
    name: "AddWishList",
    props: {
      hotel: {
        default: null
      }
    },
    data () {
      return {
        loading: false,
        on: false
      }
    },
    computed: {
      ...mapGetters([
        'getLoginUserData',
        'getExistLoginUserData',
        'getLoginUserWishHotels',
        'getLoginUserWishHotelsCnt'
      ]),
      idx () {
        return this.hotel ? this.hotel.idx ? this.hotel.idx : this.hotel.hotel_idx : 0
      },
      isOn () {
        return this.hotel && this.getExistLoginUserData && this.getLoginUserData.wish_hotels && this.getLoginUserData.wish_hotels.includes(this.idx)
      }
    },
    methods: {
      ...CommoMethods,
      putWishList () {
        if (!this.hotel || this.idx < 1 || !this.getExistLoginUserData) {
          alert('로그인이 필요합니다.')
        } else {

          if (this.loading) return;

          let wish_hotels = this.cloneData(this.getLoginUserWishHotels)
          if (!wish_hotels) wish_hotels = []
          this.isOn ? wish_hotels = wish_hotels.filter(item => item !== this.idx) : wish_hotels.push(this.idx)

          this.loading = true
          let formData = new FormData()
          formData.append('wish_hotels', JSON.stringify(wish_hotels))
          this.axios.post('/user/likeit', formData).then((rs) => {
            this.getLoginUserData.wish_hotels = wish_hotels
            this.getLoginUserData.wish_hotels_data = rs.data.data.wish_hotels_data
            setTimeout(() => {
              this.loading = false
            }, 500)
          })
        }
      }
    }
  }
</script>

<style scoped>
  .like {
    position: absolute;
    margin-top: -5px;
    right: 25px;
    display: inline-block;
    width: 35px;
    height: 35px;
    visibility: hidden;
  }
  .like.on:before {
    content: "♥";
    font-size: 25px;
    line-height: 1.0em;
    color: #01e1c9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .like:before {
    content: "♡";
    font-size: 25px;
    line-height: 1.0em;
    color: #ccc;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>