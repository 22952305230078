<template>
  <div>
    <span v-for="(chunk, i) in chunks" :key="i">
      <span v-html="chunk" v-if="i % 2 === 0" :key="chunk" />
      <hotel-container v-else-if="idx(chunk)" :idx="idx(chunk)" :key="idx(chunk)"></hotel-container>
    </span>
  </div>
</template>

<script>
  import HotelContainer from './HotelContainer'
  export default {
    name: 'VHtmlWithHotelComponent',
    components: {
      HotelContainer,
    },
    props: ['contents'],
    computed: {
      chunks() {
        const arr = this.contents.split('<hotel>')
        return arr.reduce((acc, cur) => acc.concat(cur.split('</hotel>')), [])
      },
    },
    methods: {
      idx(chunk) {
        return chunk > 0 ? chunk : 0
      },
    },
  }
</script>
