<script>
	import ChartView from 'src/pc/pages/ChartView'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import AddWishList from '../components/AddWishList'
  export default {
    extends: ChartView,
    components: {
      SubPageLayout,
      PageLayout,
      DisplayContainer,
      AddWishList,
    }
  }
</script>

<style scoped>
  .chart_view {
    margin-top: -60px;
  }
  .box_1st {
    height: 420px;
    overflow: hidden;
    position: relative;
  }
  .box_1st .img_1st {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .box_1st .img_1st:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.25);
  }
  .box_1st .title {
    position: relative;
    top: 40px;
    z-index: 2;
    color: #fff;
    text-align: center;
  }
  .box_1st .title h4 {
    font-size: 28px;
    font-weight: 700;
  }
  .box_1st .title .tab {
    display: inline-block;
    font-size: 12px;
    margin: 10px 10px 0 0;
  }
  .box_1st .title .tab li {
    display: inline-block;
    margin-right: 12px;
  }
  .box_1st .title .tab li a {
    color: rgba(255,255,255,0.5);
  }
  .box_1st .title .tab li.on a {
    color: rgba(255,255,255,1.0);
    border-bottom: 1px solid rgba(255,255,255,0.5);
    padding-bottom: 2px;
  }
  .box_1st .title p {
    display: inline-block;
    font-size: 12px;
    margin-top: 10px;
  }
  .box_1st .title p i:after {
    content: "∟";
    transform: rotate(-45deg) translate(-0%, -15%);
    font-size: 10px;
    font-weight: 900;
    margin-left: 10px;
    display: inline-block;
  }
  .box_1st .hotel_info {
    position: absolute;
    left: 32.5px;
    right: 100px;
    bottom: 30px;
    z-index: 2;
    color: #fff;
    padding-left: 65px;
    box-sizing: border-box;
  }
  .box_1st .hotel_info .num {
    position: absolute;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.35);
    border: 1px solid rgba(255,255,255,0.3);
    font-size: 12px;
    line-height: 30px;
    padding-top: 12px;
    text-align: center;
    box-sizing: border-box;
  }
  .box_1st .hotel_info .num b {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
  .box_1st .hotel_info .h_name_kr {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3em;
  }
  .box_1st .hotel_info .h_name_en {
    font-size: 14px;
    font-weight: 300;
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .box_1st .info {
    position: absolute;
    right: 32.5px;
    bottom: 30px;
  }
  .box_1st .info p {
    font-size: 15px;
    color: #fff;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 100%;
    text-align: center;
  }
  .box_1st .info:hover {
    opacity: 1.0;
  }
  .box_1st .info:hover ~ .info_pop {
    opacity: 1.0;
    visibility: visible;
  }
  .info_pop {
    position: absolute;
    top: 180px;
    left: 20px;
    right: 20px;
    background: rgba(0,0,0,0.8);
    border-radius: 10px;
    text-align: center;
    letter-spacing: -0.03em;
    padding: 20px;
  }
  .info_pop .txt-1 {
    color: #fff;
    font-size: 0.95em;
    margin-bottom: 8px;
  }
  .info_pop .txt-2 {
    color: #aaa;
    font-size: 0.85em;
  }
  .lst_ad_wrap {
    /*margin-top: 12px;*/
  }
  .calendar_modal {
    position: absolute;
    bottom: 0;
    max-height: calc(32px * 10);
    min-height: calc(32px * 6) !important;
    background-color: #fff;
    border-radius: 0;
  }
  .calendar_modal .header {
    background: #fff;
    color: #222;
    border-bottom: 1px solid #ddd;
  }
  .calendar_modal .header span {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    text-align: center;
    height: 60px;
    font-size: 0.75em;
    font-weight: 500;
    letter-spacing: -0.05em;
  }
  .calendar_modal .calendar_modal_lst {
    max-height: calc(32px * 10);
    min-height: calc(32px * 6);
  }
  .calendar_modal .calendar_modal_lst a {
    display: block;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
    color: #999;
    text-align: center;
  }
  .calendar_modal .calendar_modal_lst a:hover {
    color: #222;
    background-color: #f6f6f6;
  }
  .calendar_modal .calendar_modal_lst .on {
    font-weight: 500;
    color: #00897a !important;
  }  
  .chart_view .box_basic .scroller .inner_s .display-container {
    width: unset;
  }
</style>