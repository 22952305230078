<template>
  <span @click="goSearchHotel()">
    <slot />
  </span>
</template>

<script>
  import CommoMethods from 'src/_mixins/CommonMethods'
  import { mapGetters } from 'vuex'
  export default {
    name: "HashTag",
    props: {
      tagName: null
    },
    computed: {
      ...mapGetters([
        'getSearchHotelQuery',
        'getSearchHotelQueryOrigin'
      ])
    },
    methods: {
      ...CommoMethods,
      goSearchHotel () {
        if (this.tagName) {
          this.$store.dispatch('resetSearchHotelQuery')
          this.$router.push({
            name: 'SearchHotel',
            query: { hashTags: this.tagName.replace('#', '') }
          })
        } else {
          alert('오류가 있습니다. 관리자에게 문의해주세요')
        }
      }
    }
  }
</script>

<style scoped>

</style>
