<template>
  <page-layout class="airline_detail">

    <!-- 상단 이미지 -->
    <div class="img_air_top">
    	<div class="img_air img_wrap"><img :src="getAirlineTopImg" class="fitimg" v-show="getAirlineTopImg"></div>
			<div class="title">
				<p class="name_kr">{{getAirlineName}}</p>
				<p class="name_en">{{getAirlineNameEng}}</p>
			</div>
			<p class="copyright">ⓒ {{getAirlineNameEng}}</p>
    </div>

    <!-- 콘텐츠 리스트 -->
    <div class="curtn_lst">
    	<div class="box_basic box_tit">
    		<p class="tit_l">관련 콘텐츠</p>
			</div>
    	<ul class="c_lst">
    		<li v-for="post in postData" :key="post.idx">
					<post-container :post="post"></post-container>
				</li>
				<!--<li class="lst_ctnr box_basic">-->
					<!--<router-link to="/">-->
						<!--<div class="img_wrap"><img src="https://cdn.prestigegorilla.net/old/site/2020/2/cd0deb80-57ae-11ea-9db5-9f6d43137e9a.jpg" class="fitimg"></div>-->
						<!--<p class="cate"><i>CHECK-IN</i>FLIGHT</p>-->
						<!--<p class="subj">[피지항공 'A350-900'] 시드니-피지 이코노미석 탑승기 (FJ910)</p>-->
						<!--<div class="data">-->
							<!--<span class="date">2020-02-15</span>-->
							<!--<span class="writer">ROSE</span>-->
						<!--</div>-->
					<!--</router-link>-->
					<!--<p class="h_tags">-->
						<!--<router-link to="/" class="tag">#인피니티풀</router-link>-->
						<!--<router-link to="/" class="tag">#라운지바</router-link>-->
						<!--<router-link to="/" class="tag">#오션뷰</router-link>-->
						<!--<router-link to="/" class="tag">#루프탑</router-link>-->
						<!--<router-link to="/" class="tag">#가족여행</router-link>-->
					<!--</p>-->
				<!--</li>-->
    	</ul>

    	<!-- 리스트 더보기 -->
      <div class="lst_more_wrap box_basic">
				<a v-if="postData.length < 1" class="lst_no">검색 결과를 찾을 수 없습니다.</a>
				<a v-else v-show="getIsMorePossible" class="btn_more drtn_down" :disabled="loading"  @click="getMoreRelatedPostData()">콘텐츠 더 보기</a>
			</div>

    </div>

  </page-layout>
</template>

<script>
  import Vue from 'vue'
  import {Dialog, Popover} from 'element-ui'
	import {QDialog, QBtn, QBtnGroup, Loading, QSlideTransition, QDate, QCard, QSeparator, date, scroll} from 'quasar'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import PostContainer from '../components/PostContainer'
  import { mapGetters } from 'vuex'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    name: 'AirlineDetail',
    components: {
      PageLayout,
      SubPageLayout,
      DisplayContainer,
      PostContainer,
			QDialog, QBtn, QBtnGroup, QCard, QDate, QSeparator,
      [Dialog.name]: Dialog,
      [Popover.name]: Popover,
			Loading,
      QSlideTransition,
    },
    metaInfo () {
      return {
        title: `[항공사] ${this.getAirlineName} 콘텐츠 | ${this.getAppName}`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: '항공사별 놓치지 말아야 할 사용팁부터 인기항공까지 한번에 모아보기'},
          {vmid: 'image', name: 'image', content: this.getSeoImage},
          {vmid: 'og:title', property: 'og:title', content: `[항공사] ${this.getAirlineName} 콘텐츠 | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: '항공사별 놓치지 말아야 할 사용팁부터 인기항공까지 한번에 모아보기'},
          {vmid: 'og:image', property: 'og:image', content: this.getSeoImage}
        ]
      }
    },
    data () {
      return {
        airlineData: [],
        postData: [],
        loading: true,
        p: 1,
				perPage: 10,
				total: 0,
				type: '',
        corpLoaded: false
      }
    },
    beforeMount () {
      this.getAirlineData()
			this.getRelatedPostData()
    },
    computed: {
      ...CommonComputed,
      getAirlineName () {
        return this.airlineData.name
      },
      getAirlineNameEng () {
        return this.airlineData.name_eng
      },
      getAirlineTopImg () {
        return this.airlineData.top_img ? this.airlineData.top_img.url : ''
			},
      getAirlineIdx () {
        return Number(this.$route.params.idx)
      },
			getIsMorePossible () {
        return Number(this.total) > this.postData.length
			},
      getSeoImage () {
        const rtn = this.airlineData ? this.airlineData : ''
        return this.corpLoaded && rtn ? `seo_airline_${rtn.name_eng}.jpg` : ''
      },
    },
    methods: {
      ...CommonMethods,
			getAirlineData () {
        this.$axios.get(`/airline_corps/${this.getAirlineIdx}`).then(rs => {
          if (Object.keys(rs.data).length > 0) {
            this.airlineData = rs.data
					}
        })
      },
			// getRelatedPostData () {
       //  const params = {params: {p: this.p, type: 'FLIGHT', related_airlines: this.getAirlineIdx}}
       //  this.$axios.get('/posts/recent', params).then(rs => {
       //    if (rs.data.length > 0) {
       //      this.postData = this.postData.concat(rs.data)
       //    } else {
       //      this.finished = true
       //    }
       //    this.loading = false
       //  }).catch(() => {})
			// },
      getRelatedPostData () {
        this.loading = true
        const params = {params: {p: this.p, type: this.type, related_airlines: this.getAirlineIdx, perPage: this.perPage}}
        this.$axios.get('/posts/recent', params).then(rs => {
          this.postData = rs.data.data
          this.total = rs.data.total
          this.loading = false
        }).catch(() => {})
      },
      getMoreRelatedPostData () {
        this.loading = true
        this.p++
        const params = {params: {p: this.p, type: this.type, related_airlines: this.getAirlineIdx, perPage: this.perPage}}
        this.$axios.get('/posts/recent', params).then(rs => {
					this.postData = this.postData.concat(rs.data.data)
          this.loading = false
        }).catch(() => {})
      },
      gotoPost (post) {
        // if (card.is_visible) {
          this.$router.push({name: 'PostDetail', params: {idx: post.idx}})
        // }
      },
    }
  }
</script>

<style scoped>
  .airline_detail {
		position: relative;
    padding-top: 0;
  }
  .airline_detail .btn_more {
		border: 0;
  }
	.img_air_top {
		height: 450px;
		position: relative;
		border-bottom: 8px solid #f2f2f2;
	}
	.img_air_top .img_air {
		position: absolute;
    width: 100%;
    height: 100%;
	}
	.img_air_top .img_air:after {
		content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
	}
	.img_air_top .title {
		position: absolute;
		color: #fff;
		left: 50px;
		bottom: 50px;
	}
	.img_air_top .title .name_kr {
		font-size: 34px;
		font-weight: 500;
		letter-spacing: -0.05em;
	}
	.img_air_top .title .name_en {
		font-size: 16px;
		text-transform: uppercase;
		margin-top: 5px;
	}
	.img_air_top .copyright {
		position: absolute;
		right: 20px;
		bottom: 15px;
		font-size: 0.8em;
		color: #fff;
		opacity: 0.75;
    letter-spacing: 0.025em;
	}
	.curtn_lst .box_tit {
		padding: 40px 0 5px;
		margin-bottom: 0;
    border-bottom: 0;
    background: none;
	}
	.curtn_lst .box_tit .tit_l {
		margin-bottom: 15px;
	}
	.curtn_lst .lst_ctnr {
		padding: 25px 32.5px;
	}
	.curtn_lst .lst_ctnr .img_wrap {
		width: 100%;
		height: 42.5vw;
	}
	.curtn_lst .lst_ctnr .cate {
		font-size: 12px;
		font-weight: 400;
		color: #888;
		letter-spacing: -0.02em;
		line-height: 12px;
		margin-top: 20px;
	}
	.curtn_lst .lst_ctnr .cate i {
		margin-right: 12px;
		padding-right: 12px;
		border-right: 1px solid #888;
		line-height: 12px;
		display: inline-block;
	}
	.curtn_lst .lst_ctnr .subj {
		font-size: 15px;
		font-weight: 500;
		color: #222;
		line-height: 1.4em;
		letter-spacing: -0.03em;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.curtn_lst .lst_ctnr .data {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 12px;
	}
	.curtn_lst .lst_ctnr .data .date {
		color: #bbb;
	}
	.curtn_lst .lst_ctnr .data .writer {
		color: #888;
		margin-left: 20px;
	}
	.curtn_lst .lst_ctnr .h_tags {
		margin-top: 15px;
		height: 40px;
		overflow: hidden;
	}
	.curtn_lst .lst_ctnr .h_tags .tag {
		font-size: 12px;
		font-weight: 500;
		color: #888;
		letter-spacing: -0.03em;
		border: 0.5px solid #ccc;
		margin: 5px 5px 0 0;
		padding: 10px 14px;
		line-height: 1.0em;
		display: inline-block;
	}
</style>
