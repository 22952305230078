<template>
  <transition name="el-fade-in">

    <page-layout class="hotel_detail fr-view" v-if="getCurrentHotel.idx > 0">

      <!-- img caching -->
      <img :src="src" v-for="(src, i) in getHotelImages" :key="i" style="width: 0; height: 0; position: absolute;" />
      <!-- img caching -->

      <!-- 기본 정보 -->
      <div class="basic_info">
        <div class="photo">
          <q-carousel
            swipeable
            animated
            infinite
            arrows
            :padding="false"
            transition-prev="slide-right"
            transition-next="slide-left"
            v-model="slide"
            class="q-pa-none q-ma-none bg-grey-3"
          >
            <q-carousel-slide :name="0" v-if="getCurrentHotel.main_img">
              <q-img
                :src="getCurrentHotel.main_img.url"
                transition="fade"
                :no-default-spinner="true"
                spinner-color="white"
                class="fitimg"
              />
            </q-carousel-slide>
            <q-carousel-slide v-for="(src, i) in getHotelImages" :key="i" :name="i+1">
              <q-img
                :src="src"
                transition="fade"
                :no-default-spinner="true"
                spinner-color="white"
                class="fitimg"
              />
            </q-carousel-slide>
          </q-carousel>
          <span class="num">{{slide+1}}/{{getHotelImages.length+1}}</span>
          <i class="ico_photo" @click="toggleGalleryDialog"></i>
        </div>
        <div class="items box_basic">
          <div class="basic">
            <p class="ht_class">{{getCurrentHotel.star_rating}}성급</p>
            <p class="ht_name_kr">{{getCurrentHotel.name}}</p>
            <p class="ht_name_en">{{getCurrentHotel.name_eng}}</p>
            <p class="ht_name_brand">{{getCurrentHotel.brand_name}}</p>
            <p class="loc"><i></i>{{getCurrentHotel.region_txt}}</p>
            <!--<p class="price" v-if="getOtaData && !getRowestResult">예약가능한 방 없음</p>
            <p class="price" v-else-if="getRowestResult">
              <a @click="bookingHotel(getRowestResult)">{{getProviderName(getRowestResult.providerIndex)}} <b>{{getOtaData.lowestRate.toLocaleString()}}</b></a>
            </p>
            <p class="price" v-else><q-skeleton type="rect" class="skeleton" >최저가 검색 중</q-skeleton></p>-->
          </div>
          <div class="badges">
            <div class="badge badge_rank" v-if="getRanking">국내호텔<br><b>{{getRanking}}</b>위</div>
            <div class="badge badge_review" v-if="getCurrentHotel.is_review"><p></p>체크인리뷰</div>
          </div>

          <router-link :to="`/hotel/bridge/${getCurrentHotel.idx}`" class="btn_reserv" target="_blank" v-if="isRequest">최저가 예약</router-link>

          <!--<div class="icons">
            <add-wish-list :hotel="getCurrentHotel" />
            <span class="share" @click="toggleShareDialog(true);"><img src="/statics/img/ico_share.png"></span>
          </div>-->
        </div>
      </div>
      <!-- //기본 정보 -->

      <div class="sub_nav_bar_wrapper">

				<!-- sub nav bar -->
				<!--<div class="sub_nav_bar">
					<span :class="{ on: currentSection === 0 }" v-scroll-to="{ el: '#section-1', offset: getOffset}">소개</span>
					<span :class="{ on: currentSection === 1 }" v-scroll-to="{ el: '#section-2', offset: getOffset}">후기</span>
					<span :class="{ on: currentSection === 2 }" v-scroll-to="{ el: '#section-3', offset: getOffset}">예약</span>
					<span :class="{ on: currentSection === 3 }" v-scroll-to="{ el: '#section-4', offset: getOffset}">정보</span>
				</div>-->
				<!-- //sub nav bar -->

				<!-- 소개 -->
				<!--<section id="section-1">
					<div class="con_intro box_basic" ref="section_1">
						<ul class="tag">
							<hash-tag v-for="tag in getCurrentHotel.hash_tags" :key="tag.idx" :tag-name="tag.name">
								<li @click="goSearchHotel(tag)">#{{tag.name}}</li>
							</hash-tag>
						</ul>
						<div class="con_recomm" v-if="getThemeRankingContents.length > 0">
							<p class="tit_s">여기에 뽑혔던 호텔이었어!</p>
							<div class="scroller">
								<div class="inner inner_l num-2">
									<a class="container ctnr_l ctnr_dark" v-for="cont in getThemeRankingContents" :key="cont.idx" @click="goRoutePost(cont.idx)">
										<p class="img_wrap">
											<img :src="cont.post_img.url" class="fitimg">
										</p>
										<p class="con_tit">{{cont.title}}</p>
									</a>
								</div>
							</div>
						</div>
						<div class="con_recomm" v-if="getPackageContents.length > 0">
							<p class="tit_s">패키지로 가성비 UP! 해볼래요?</p>
							<div class="scroller">
								<div class="inner inner_l num-2">
									<a class="container ctnr_l ctnr_dark" v-for="cont in getPackageContents" :key="cont.idx" @click="goRoutePost(cont.idx)">
										<p class="img_wrap">
											<img :src="cont.post_img.url" class="fitimg">
										</p>
										<p class="con_tit">{{cont.title}}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>-->
				<!-- //소개 -->

				<!-- 후기 -->
				<section id="section-2">
					<div class="con_review box_basic box_rev" v-if="getRelatedReviewVidoes.length + getRelatedReviewPosts.length > 0">
						<p class="tit_l tit_rev">체크인리뷰</p>
						<div class="con_recomm" v-if="getRelatedReviewVidoes.length > 0">
							<p class="tit_s">영상으로 분위기까지 담아왔어요!</p>
							<div class="scroller">
								<div class="inner inner_l num-2">
									<a class="container ctnr_l" v-for="cont in getRelatedReviewVidoes" :key="cont.idx" @click="goRouteVideo(cont.idx)">
										<p class="img_wrap">
											<img :src="cont.thumbnail" class="fitimg">
										</p>
										<p class="con_tit con_tit_rev">{{cont.title}}</p>
									</a>
								</div>
							</div>
						</div>
						<div class="con_recomm" v-if="getRelatedReviewPosts.length > 0">
							<p class="tit_s">좀더 꼼꼼하게 살펴보고 싶어요?</p>
							<div class="scroller">
								<div class="inner inner_l num-2">
									<a class="container ctnr_l ctnr_rev" v-for="cont in getRelatedReviewPosts" :key="cont.idx" @click="goRoutePost(cont.idx)">
										<p class="img_wrap">
											<img :src="cont.post_img.url" class="fitimg">
										</p>
										<p class="con_tit con_tit_rev">{{cont.title}}</p>
										<p class="sub_txt" v-html="cont.contents.substr(0, 80) + '...'"></p>
										<p class="editor"><img :src="cont.writer.prof_img.url">{{cont.writer.nick}}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
					<!--<div class="con_review box_basic">
						<div class="rating">
							<p class="tit_s">투숙객 평점</p>
							<ul>
                <li class="star">
                  <i :class="{ full : getReviewsSentiment > n*2,
                               half: getReviewsSentiment > n*2-1 && getReviewsSentiment < n*2,
                               empty: getReviewsSentiment < n*2-1
                             }"
                     v-for="n in 5" :key="n" />
                </li>
								<li class="rat_num">{{getReviewsSentiment}}</li>
								<li>{{getNumberOfReviews}} Reviews</li>
							</ul>
						</div>
					</div>-->
				</section>
				<!--// 후기 -->

				<!-- 예약 -->
				<!--<section id="section-3">
					<div class="con_reserv box_basic">
						<p class="tit_l">예약</p>
						<div class="status">
							<span>{{getSearchHotelQueryTextShort}}</span>
							<span class="btn" @click="$refs.modal.setSearchDialog(true)">변경하기</span>
						</div>
						<div class="ota_list">
							<p>최저가순</p>
							<div v-if="getResults">
								<ul class="ota_con" v-for="(row, i) in getResults" :key="i" v-show="i < showResultsLimit" @click="bookingHotel(row)">
									<li>{{getProviderName(row.providerIndex)}}</li>
									<li>{{row.roomName}}</li>
									<li>{{row.totalRate.toLocaleString()}}</li>
								</ul>
								<a class="btn_more drtn_down" @click="showResultsLimit += 5" v-if="getResults.length > showResultsLimit">더 보기</a>
							</div>
							<div v-else>
								<ul class="ota_con">
									해당 기간에 예약가능한 객실이 없습니다. 조건을 다시 설정하세요
								</ul>
							</div>
						</div>
						<p class="info_txt">예약 전 상품정보 및 규정을 확인하세요.<i>&#43;</i></p>
						<p class="info_con">프레스티지고릴라는 예약사이트에서 받은 정보만을 제공하며, 실제 예약사이트에서 정보와 최종가격을 반드시 확인하시기 바랍니다.</p>
					</div>
				</section>-->
				<!--// 예약 -->

				<!-- 정보 -->
				<section id="section-4">
					<div class="con_info box_basic">
						<!--<dl>
							<dt class="tit_s">소개</dt>
							<dd>
								<div style="overflow: hidden" :style="isDescriptionFolded ? 'max-height: 80px' : ''">
									{{getDescription}}
								</div>
							</dd>
							<dd class="more" @click="isDescriptionFolded = !isDescriptionFolded">{{isDescriptionFolded ? '더 보기' : '접기'}}</dd>
						</dl>-->
						<dl>
							<dt class="tit_s">정책</dt>
							<dd>
								<i>체크인: {{getCurrentHotel.checkin}}</i>
								<i>체크아웃: {{getCurrentHotel.checkout}}</i>
							</dd>
						</dl>
						<dl>
							<dt class="tit_s">편의시설</dt>
							<dd v-if="getCurrentHotel.amenities">
								<i v-for="item in getCurrentHotel.amenities.split(',')" :key="item">{{item}}</i>
							</dd>
							<!--<dd class="more">더 보기</dd>-->
						</dl>
						<dl>
							<dt class="tit_s">위치</dt>
							<dd><img :src="getNaverMapStatic" width="100%" height="auto" @click="showMapDialog()" v-if="getNaverMapStatic" class="map"/></dd>
							<dd class="location">{{getAddress}}</dd>
						</dl>
					</div>
				</section>
				<!--// 정보 -->

			</div>

      <!-- 공유 -->
      <!--<div class="share box_basic">
        <router-link to=""><img src="/statics/img/share_kakao.png"></router-link>
        <router-link to=""><img src="/statics/img/share_url.png"></router-link>
        <router-link to=""><img src="/statics/img/share_like_off.png"></router-link>
      </div>-->
      <!-- //공유 -->

      <!-- 추가콘텐츠1 -->
      <div class="box_basic box_con5" v-if="getRelatedHotels.length > 0">
        <p class="tit_l">이런 호텔은 어때요?</p>

        <div class="scroller">
          <div class="inner inner_s num-6">
            <a class="container ctnr_s" v-for="hotel in getRelatedHotels" :key="hotel.idx" @click="goRouteHotel(hotel.idx)">
              <p class="img_wrap">
                <img :src="hotel.main_img.url" class="fitimg">
              </p>
              <p class="name_kr">{{hotel.name}}</p>
              <p class="name_en">{{hotel.name_eng}}</p>
            </a>
          </div>
        </div>

      </div>
      <!--// 추가콘텐츠1 -->

      <!-- 추가콘텐츠2 -->
      <!--<div class="box_basic"> // 찜하기 운영하지 않으므로 블라인드
        <p class="tit_l">좋아요♥많은 호텔</p>

        <div class="scroller">
          <div class="inner inner_s">
            <router-link :to="`/hotel/detail/${hotel.idx}`" class="container ctnr_s" v-for="hotel in wishHotelTop3" :key="hotel.idx">
              <p class="img_wrap">
                <img :src="hotel.main_img.large" class="fitimg">
              </p>
              <p class="name_kr">{{hotel.name}}</p>
              <p class="name_en">{{hotel.name_eng}}</p>
            </router-link>
          </div>
        </div>
      </div>-->
      <!--// 추가콘텐츠2 -->

      <!-- 추가콘텐츠3 -->
      <div class="box_basic box_rev last" v-if="getRelatedContents.length > 0">
        <p class="tit_l">취향맞춤 콘텐츠</p>
        <div class="scroller">
          <div class="inner inner_m">
            <a class="container ctnr_m ctnr_dark" v-for="cont in getRelatedContents" :key="cont.idx" @click="goRoutePost(cont.idx)">
              <p class="img_wrap">
                <img :src="cont.post_img.url" class="fitimg">
              </p>
              <p class="con_tit">{{cont.title}}</p>
            </a>
          </div>
        </div>
      </div>
      <!--// 추가콘텐츠3 -->

      <q-dialog v-model="isOpenGalleryDialog"
								maximized
								transition-show="slide-up"
								transition-hide="slide-down"
			>
        <div class="modal">
          <div>
            <div class="header">{{getCurrentHotel.name}}<div class="btn_back btn_close" @click="toggleGalleryDialog()"><i>&#10005;</i></div></div>
            <ul class="tab">
            	<li :class="{ on: galleryTab === 1 }" @click="galleryTab = 1">기본</li>
            	<li :class="{ on: galleryTab === 2 }" @click="galleryTab = 2">프고촬영</li>
            </ul>
            <div class="">
              <!--<img-->
                <!--:src="getCurrentHotel.main_img.large"-->
                <!--class="gallery_img"-->
              <!--/>-->
							<div v-if="getCurrentHotel">
								<img
									v-show="galleryTab === 1"
									:src="img.url" v-for="(img, i) in getCurrentHotel.bs_imgs" :key="i"
									transition="none"
									spinner-color="white"
									class="gallery_img"
								/>
							</div>
							<div v-if="getCurrentHotel">
								<img
									v-show="galleryTab === 2"
									:src="img.url" v-for="(img, i) in getCurrentHotel.imgs" :key="i"
									transition="none"
									spinner-color="white"
									class="gallery_img"
								/>
							</div>
						</div>
          </div>
        </div>
      </q-dialog>

			<q-dialog v-model="isOpenMapDialog" maximized>
				<div>
					<div class="modal_btn_close" @click="isOpenMapDialog = false"><i>&#10005;</i></div>
					<div id="MAP" class="map" />
				</div>
			</q-dialog>

			<q-scroll-observer @scroll="onScroll" />

    </page-layout>
  </transition>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import AddWishList from '../components/AddWishList'
	import HashTag from '../components/HashTag'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  import { Dialog, Loading, Popover } from 'element-ui'
  import { QDialog, QBtn, QScrollArea, QScrollObserver } from 'quasar'
  import { mapGetters } from 'vuex'
  import forms from './hotel_forms'
  import Consts from 'src/_mixins/Consts'
  export default {
    name: "HotelDetail",
    components: {
      PageLayout,
      DisplayContainer,
      AddWishList,
			HashTag,
      [Dialog.name]: Dialog,
      [Popover.name]: Popover,
      QDialog, QBtn, QScrollArea, QScrollObserver
    },
    metaInfo () {
      return {
        title: ` ${this.getHotelName} | ${this.getAppName}`,
        titleTemplate: '%s',
        meta: [
          {vmid: 'description', name: 'description', content: this.getHotelName},
          {vmid: 'image', name: 'image', content:  this.getHotelPlateImage},
          {vmid: 'og:title', property: 'og:title', content: `${this.getHotelName} | ${this.getAppName}`},
          {vmid: 'og:description', property: 'og:description', content: this.getHotelName},
          {vmid: 'og:image', property: 'og:image', content: this.getHotelPlateImage}
        ]
      }
    },
    props: {
      hotelIdx: {
        type: Number,
        default: 0
      },
      isPlayer: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        chart: [],
        idx: 0,
        slide: 0,
        tab: 'introduce',
        persistent: true,
        isOpenGatewayLayer: false,
        showKeyBenefitInfo: [],
        dialogVisible: false,
        popoverVisible: true,
        isOpenGalleryDialog: false,
        isOpenMapDialog: false,
        isDescriptionFolded: true,
        showResultsLimit: 3,
				galleryTab: 1,
				section: [0, 0, 0, 0],
        currentSection: null,
        wishHotelTop3: []
      }
    },
    // beforeRouteEnter (to, from, next) {
    //   console.log(to.getRouteQuery)
    //
    // },
    created () {
      // this.$store.dispatch('setLatestChartData')
    },
    destroyed () {
      this.$store.dispatch('setCurrentHotel', this.cloneData(forms))
    },
    mounted () {
      this.getHotelData()
      // this.getWishHotelTop3()

			// let recaptchaScript = document.createElement('script')
			// recaptchaScript.setAttribute('src', '/statics/event.js')
			// document.head.appendChild(recaptchaScript)
    },
    watch: {
      'getRouteParams' (val) {
        this.getHotelData()
      },
      'hotelIdx' (val) {
        this.getHotelData()
      }
    },
    computed: {
      ...CommonComputed,
      ...mapGetters([
        'getCurrentHotel',
        'getSearchHotelQuery',
        'getRankingByHotelIdx'
      ]),
      getAccess () {
        return 'app'
      },
			getOffset () {
        return -117
			},
      getRouteParams () {
        return this.$route.params.idx
      },
      getRouteQuery () {
        return this.$route.query
      },
      getHotelName () {
        return this.getCurrentHotel && this.getCurrentHotel.name ? this.getCurrentHotel.name : ''
      },
      getHotelPlateImage () {
        return this.getFileExist(this.getCurrentHotel.main_img)
      },
      getHotelImages () {
        const imgs = []
        if (this.getCurrentHotel.imgs.length > 0) {
          this.getCurrentHotel.imgs.map(row => {
            if (this.getCurrentHotel.main_img.url !== row.url) imgs.push(row.url)
          })
        }
        if (this.getCurrentHotel.bs_imgs.length > 0) {
          this.getCurrentHotel.bs_imgs.map(row => {
            if (this.getCurrentHotel.main_img.url !== row.url) imgs.push(row.url)
          })
        }
        return imgs
      },
      getAddress () {
        return `${this.getCurrentHotel.region_txt} ${this.getCurrentHotel.address}`
      },
      getThemeRankingContents () {
        return this.getCurrentHotel.theme_ranking_contents.filter(row => row.idx)
      },
      getPackageContents () {
        return this.getCurrentHotel.package_contents.filter(row => row.idx)
      },
      getRelatedHotels () {
        return this.getCurrentHotel.related_hotels.filter(row => row.idx)
      },
      getRelatedContents () {
        return this.getCurrentHotel.related_contents.filter(row => row.idx)
      },
      getRelatedReviewVidoes () {
        return this.getCurrentHotel.related_review_videos.filter(row => row.idx)
      },
      getRelatedReviewPosts () {
        return this.getCurrentHotel.related_review_posts.filter(row => row.idx)
      },
			getNaverMapStatic () {
        return this.getCurrentHotel
          ? `https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=1280&h=720&center=${this.getCurrentHotel.longitude},${this.getCurrentHotel.latitude}&level=17&scale=2&markers=type:a|size:mid|label:h|pos:${this.getCurrentHotel.longitude}%20${this.getCurrentHotel.latitude}|color:black|viewSizeRatio:1.0&X-NCP-APIGW-API-KEY-ID=gq29vd3gaq`
          : null
			},
      getRanking () {
        return this.getRankingByHotelIdx(this.getCurrentHotel.idx)
      },
      isRequest () {
        return this.getAccess === 'app' ? this.getCurrentHotel.request_app : this.getCurrentHotel[`request_${this.getAccess}`]
      },
    },
    methods: {
      ...CommonMethods,
      getHotelData () {
        this.idx = this.$route.params.idx ? this.$route.params.idx : this.hotelIdx
        // if (!this.idx) {
        //   this.hotel = forms
        //   return
        // }
        if (this.idx > 0) {
          let loading
          this.$nextTick(() => loading = Loading.service({target: document.querySelector('#search_results')}))
          this.$axios.get(`/hotels/${this.idx}`).then(rs => {
            this.$store.dispatch('setCurrentHotel', rs.data)
            this.setChartData()

						this.$nextTick(() => loading.close())

						// this.setSectionPosition()

          }).catch(() => {
            // setTimeout(function () {
            //   this.$router.replace('/')
            // }.bind(this), 200)
          })
        }
      },
      getWishHotelTop3 () {
        this.$axios.get(`/hotels/wish/top3`).then(rs => {
          this.wishHotelTop3 = rs.data
        })
      },
			setSectionPosition () {
        this.$nextTick(() => {
          this.section[0] = this.getPosY(this.$el.querySelector('#section-1'))
          this.section[1] = this.getPosY(this.$el.querySelector('#section-2'))
          this.section[2] = this.getPosY(this.$el.querySelector('#section-3'))
          this.section[3] = this.getPosY(this.$el.querySelector('#section-4'))
        })
			},
			getPosY (el) {
        let posY = el.offsetTop
				if (el.offsetParent) {
          posY += el.offsetParent.offsetTop
				}
        return posY
			},
      setChartData () {
        let params = {
          hotel_idx: this.getCurrentHotel.idx,
          hotel_gb: this.getCurrentHotel.cate,
          access: this.getAccess,
          event: 'view',
        }
        this.$axios.put('/charts/set_data_day', params)
        this.$axios.put('/charts/set_data_week', params)
      },
      isScrolledIntoView (el, vertical = 'bottom', offset = 0) {
        // const { top, bottom } = el.getBoundingClientRect()
        // return top >= 0 && bottom <= window.innerHeight
        // console.log(window.pageYOffset)
        return el.getBoundingClientRect()[vertical] + offset <= window.innerHeight
      },
      getMonthName (month) {
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return month ? month_names_short[Number(month)-1] : ''
      },
      getMonthFromRegDt (post) {
        return post && post.reg_dt ? post.reg_dt.substr(5,2) : ''
      },
			toggleShareDialog (flag) {
        this.$store.dispatch('setOpenShareDialog', flag)
      },
      toggleGalleryDialog () {
        this.isOpenGalleryDialog = !this.isOpenGalleryDialog
      },
      onScroll (info) {
        if (this.section[3]-117 < 0) this.currentSection = 0
				else if (this.section[3]-117 <= info.position) this.currentSection = 3
        else if (this.section[2]-117 <= info.position) this.currentSection = 2
        else if (this.section[1]-117 <= info.position) this.currentSection = 1
        else this.currentSection = 0
      },
			showMapDialog () {
        this.isOpenMapDialog = true
				if (this.getCurrentHotel) {
          this.$nextTick(() => {
            const point = new naver.maps.LatLng(this.getCurrentHotel.latitude, this.getCurrentHotel.longitude);
            const mapOptions = {
              center: point
            }
            const map = new naver.maps.Map('MAP', mapOptions)
            const marker = new naver.maps.Marker({
              map: map,
              position: point
            })
            const markerLabel = new naver.maps.InfoWindow({
              content: `<div style="width:auto;text-align:center;padding:3px 10px;">${this.getCurrentHotel.name}</div>`
            })
            markerLabel.open(map, marker)
					})
				}
			},
      goSearchHotel (tag) {
        this.$router.push({
          name: 'SearchHotel',
          query: { hash_tags: tag.name }
        })
      },
      goRouteHotel (idx) {
        this.$router.push({
          name: 'HotelDetail',
          params: { idx: idx }
        })
      },
      goRoutePost (idx) {
        this.$router.push({
          name: 'ContentsDetail',
          params: { idx: idx }
        })
      },
      goRouteVideo (idx) {
        this.$router.push({
          name: 'VideoDetail',
          params: { idx: idx }
        })
      },
      bookingHotel (res) {
        if (res.bookUri) {
          let params = {
            hotel_idx: this.getCurrentHotel.idx,
            provider_idx: res.providerIndex,
            hotel_gb: this.getCurrentHotel.cate,
            access: this.getAccess,
            event: 'click',
          }
          this.$axios.put('/charts/set_log_ip', params).then(rs => {
            if (rs.data.status === 'success') {
              this.$axios.put('/charts/set_data_day', params)
              this.$axios.put('/charts/set_data_week', params).then(rs2 => {
                console.log(rs2.data.status === 'success')
                window.open(`${this.getOtaApiConfigData.otaSiteURL}/${res.bookUri}`)
              })
            } else if (rs.data.status === 'exist') {
              console.log(`exist : ${rs.data.cnt}`)
              window.open(`${this.getOtaApiConfigData.otaSiteURL}/${res.bookUri}`)
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
  .map {
    width: 100%;
    height: 100%;
  }
  .modal_btn_close {
    position: fixed;
    padding: 20px 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 30px;
  }

	.basic_info {
		background: ;
	}
	.basic_info .photo {
		width: 100%;
		height: 600px;
		position: relative;
		overflow: hidden;
	}
  .basic_info .photo .q-carousel {
    height: 100%;
  }
	.basic_info .photo .num {
		position: absolute;
		left: 50%;
		top: 90%;
		transform: translate(-50%, -50%);
		background: rgba(0,0,0,0.5);
		font-size: 13px;
		color: #fff;
		padding: 8px 15px;
	}
	.basic_info .photo .ico_photo {
		position: absolute;
		right: 30px;
		bottom: 30px;
		width: 30px;
		height: 23px;
		background: url('/statics/img/ico_photo.png');
		background-size: 30px auto;
    background-repeat: no-repeat;
    cursor: pointer;
	}
	.basic_info .items {
		position: relative;
		padding: 35px 50px;
    border-bottom: 8px solid #f2f2f2 !important;
	}
	.basic_info .items .basic {
		display: inline-block;
		width: 76%;
	}
	.basic_info .items .basic .ht_class {
		font-size: 14px;
    font-weight: 400;
		color: #666;
		line-height: 1.0em;
		letter-spacing: -0.03em;
    margin-bottom: 15px;
    background: #efefef;
    border-radius: 5.0em;
    padding: 6px 15px;
    display: inline-block;
	}
	.basic_info .items .basic .ht_name_kr {
		font-size: 28px;
		color: #222;
		font-weight: 700;
		line-height: 1.2em;
		letter-spacing: -0.03em;
	}
	.basic_info .items .basic .ht_name_en {
		font-size: 18px;
		color: #222;
		font-weight: 300;
		margin-top: 10px;
	}
	.basic_info .items .basic .ht_name_brand {
		font-size: 14px;
		color: #0e6057;
		font-weight: 400;
		margin-top: 10px;
    font-style: italic;
    opacity: 0.75;
	}
	.basic_info .items .basic .loc {
		font-size: 14px;
		color: #888;
		margin-top: 30px;
    line-height: 1.2em;
	}
	.basic_info .items .basic .loc i {
		display: inline-block;
		width: 14px;
		height: 18px;
		margin-right: 5px;
		background: url('/statics/img/ico_pin.png') no-repeat;
		background-size: 100% auto;
	}
	.basic_info .items .basic .price {
		font-size: 14px;
		color: #888;
		margin-top: 32px;
    line-height: 2.0em;
	}
	.basic_info .items .basic .price b {
		font-size: 24px;
		color: #00897a;
		font-weight: 600;
		line-height: 1.2em;
		margin-left: 15px;
	}
	.basic_info .items .basic .price b:before {
		content: "￦";
		font-size: 13px;
	}
	.basic_info .items .badges {
		display: inline-block;
		width: 24%;
		text-align: right;
	}
	.basic_info .items .badges .badge {
		display: inline-block;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: -0.03em;
		line-height: 1.4em;
		padding: 8px 0 5px;
		text-align: center;
		border-radius: 4px;
		width: 55px;
	}
	.basic_info .items .badges .badge.badge_rank {
		color: #222;
		background: #ebebeb;
    margin-bottom: 5px;
	}
	.basic_info .items .badges .badge.badge_rank b {
		font-size: 12px;
		font-weight: 600;
		position: relative;
		top: -0.5px;
	}
	.basic_info .items .badges .badge.badge_review {
		color: #00897a;
		background: #ccf9f4;
    margin-left: 5px;
	}
	.basic_info .items .badges .badge.badge_review p {
		display: inline-block;
		width: 100%;
		height: 13px;
		background: url('/statics/img/ico_pin_badge.png') no-repeat;
		background-size: 10px auto;
		background-position: center;
	}
	.basic_info .items .btn_reserv {
		position: absolute;
		bottom: 35px;
		right: 50px;
    display: inline-block;
    padding: 16px 100px;
    background: #0e6057;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
	}
	.basic_info .items .icons {
		position: absolute;
    width: 50px;
    height: 24px;
		bottom: 30px;
		right: 32.5px;
		text-align: right;
	}
	.basic_info .items .icons span {
		/*display: inline-block;*/
    display: none;
		width: 24px;
		height: 24px;
		margin-left: 25px;
	}
  .basic_info .items .icons .like {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    margin: 0;
    right: 0;
  }
  .basic_info .items .icons .on:before {
    content: "♥";
    font-size: 25px;
    line-height: 1.0em;
    color: #01e1c9;
  }
  .basic_info .items .icons .off:before {
    content: "♡";
    font-size: 25px;
    line-height: 1.0em;
    color: #ccc;
    font-weight: 900;
  }
	.basic_info .items .icons span img {
		width: 100%;
		height: 100%;
		filter: brightness(0.8);
	}
	.sub_nav_bar {
		padding: 18px 0;
		text-align: center;
		position: -webkit-sticky;
		position: sticky;
		top: 150px;
		border: solid #f2f2f2;
		border-width: 1px 0 1px 0;
		background: #fff;
		z-index: 1;
		display: block;
	}
	.sub_nav_bar span {
		font-size: 18px;
		font-weight: 700;
		margin: 0 4.5%;
    cursor: pointer;
	}
	.sub_nav_bar span.on {
		border-bottom: 2px solid #01e1c9;
	}
	.sub_nav_bar span a.current {
		border-bottom: 2px solid #01e1c9;
	}
	.sub_nav_bar_wrapper .box_basic {
		border-bottom: 8px solid #f2f2f2 !important;
	}
	.con_intro .tag {
		margin-bottom: 35px;
	}
	.con_intro .tag li {
		display: inline-block;
		font-size: 13px;
    font-weight: 500;
		color: #888;
		letter-spacing: -0.03em;
		padding: 9px 15px;
		margin: 2.5px;
		border: 1px solid #ddd;
	}
	.hotel_detail {
		position: relative;
	}
	.hotel_detail .box_basic .con_recomm {
		margin-bottom: 40px;
	}
	.hotel_detail .box_basic .con_recomm:last-child {
		margin-bottom: 0;
	}
	.hotel_detail .box_basic .scroller {
		width: 100%;
	}
	.hotel_detail .box_basic .scroller .inner {
		max-width: unset !important;
    padding: 0;
	}
	.hotel_detail .box_basic .scroller .inner .container {
    width: calc((100% - 40px) / 5);
    margin-right: 10px;
    margin-bottom: 10px;
	}
	.hotel_detail .box_basic .scroller .inner .container:nth-child(5) {
    margin-right: 0;
	}
	.hotel_detail .box_basic.box_con5 .scroller .inner .container:nth-child(6) {
    display: none;
	}
	.hotel_detail .box_basic.box_rev .scroller .inner .container {
		margin-right: 0;
    width: calc((100% - 10px) / 2);
    margin-right: 10px;
    margin-bottom: 10px;
	}
	.hotel_detail .box_basic.box_rev .scroller .inner .container:nth-child(2n) {
    margin-right: 0;
	}
	.hotel_detail .box_basic.box_rev .scroller .inner .container .img_wrap {
		width: 100%;
    height: unset;
    padding-bottom: calc(100% * 0.5625);
    display: inline-block;
	}
  .hotel_detail .box_basic.box_rev .scroller .inner .container .con_tit {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.4em;
	}
	.rating ul {
		display: flex;
		align-items: center;
		margin: 30px auto 0;
    width: 70%;
	}
	.rating ul li {
		width: 36%;
    font-size: 18px;
	}
	.rating ul li:first-child {
		text-align: right;
	}
	.rating ul li:first-child img {
		margin-top: 2px;
		height: 12px;
	}
	.rating ul li.rat_num {
		font-size: 26px;
		font-weight: 500;
		text-align: center;
		width: 28%;
	}
	.rating ul li:last-child {
		text-align: left;
		font-weight: 300;
	}
  .rating li.star {
    line-height: 1.0em;
  }
  .rating li.star i {
    display: inline-block;
    width: 20px;
    height: 18px;
    background-image: url('/statics/img/rating.png');
    background-size: 70px auto;
    background-repeat: no-repeat;
    margin-left: 3.5px;
  }
  .rating li.star i.full {
    background-position: 0 0;
  }
  .rating li.star i.half {
    background-position: -27px 0;
  }
  .rating li.star i.empty {
    background-position: -53px 0;
  }
	.con_reserv .status {
		color: #222;
		font-weight: 500;
		padding: 20px 15px;
		background: #f6f6f6;
		text-align: center;
	}
	.con_reserv .status .btn {
		color: #fff;
		font-weight: 500;
		padding: 8px 20px;
		background: #00897a;
		margin-left: 18px;
		letter-spacing: -0.05em;
	}
	.con_reserv .ota_list {
		margin-top: 20px;
	}
	.con_reserv .ota_list p {
		text-align: right;
		font-size: 14px;
		color: #888;
		margin-bottom: -5px;
	}
	.con_reserv .ota_list .ota_con {
		min-height: 60px;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		line-height: 1.2em;
		word-break: break-all;
    padding: 10px 0;
    cursor: pointer;
	}
	.con_reserv .ota_list .ota_con li:first-child {
		width: 27%;
		color: #888;
    font-size: 14px;
	}
	.con_reserv .ota_list .ota_con li:nth-child(2) {
		width: 45%;
		color: #222;
    font-size: 14px;
    line-height: 1.4em;
    max-height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	}
	.con_reserv .ota_list .ota_con li:last-child {
		width: 28%;
		font-size: 16px;
		color: #00897a;
		font-weight: 500;
		text-align: right;
	}
	.con_reserv .ota_list .ota_con li:last-child:before {
		content: "￦";
		font-size: 12px;
	}
	.con_reserv .btn_more {
		display: block;
		color: #222;
		border: 1px solid #aaa;
		text-align: center;
		padding: 15px;
		margin-top: 20px;
		position: relative;
	}
	.con_reserv .btn_more i {
		font-size: 14px;
		color: #222;
		font-weight: 900;
		transform: rotate(225deg);
		display: inline-block;
		position: absolute;
		right: 8.5%;
	}
	.con_reserv .info_txt {
    font-size: 15px;
    font-weight: 500;
		color: #222;
		margin-top: 30px;
	}
	.con_reserv .info_txt i {
		float: right;
		font-size: 30px;
    font-weight: 400;
		color: #aaa;
		line-height: 0.75em;
	}
	.con_reserv .info_con {
		font-size: 13px;
		color: #999;
		line-height: 1.8em;
		margin-top: 15px;
	}
	.con_info {
		border-bottom: 8px solid #f2f2f2 !important;
	}
	.con_info dl {
		margin-bottom: 30px;
	}
	.con_info dl:last-child {
		margin-bottom: 0;
	}
	.con_info dd {
		color: #888;
		letter-spacing: -0.02em;
		line-height: 1.8em;
		word-break: break-all;
		margin-top: -5px;
	}
	.con_info dd img {
		cursor: pointer;
	}
	.con_info dd.more {
		float: right;
		color: #222;
    cursor: pointer;
	}
	.con_info dd i {
		display: inline-block;
		width: 25%;
	}
	.con_info dd.location {
    font-size: 14px;
    font-weight: 500;
		color: #888;
		margin-top: 10px;
	}
	.share {
		text-align: center;
	}
	.share a {
		display: inline-block;
		width: 50px;
		height: 50px;
		margin: 0 5px;
	}
	.share a img {
		width: 100%;
	}
	.ctnr_s .name_kr {
		font-size: 16px;
		color: #222;
		font-weight: 500;
    line-height: 1.3em;
		margin-top: 10px;
		letter-spacing: -0.03em;
	}
	.ctnr_s .name_en {
		font-size: 13px;
		color: #777;
	}
  .skeleton {
    text-align: center;
    text-color: white;
    font-size: 8px;
    padding: 5px 10px;
  }
	.modal {
		position: absolute;
	}
	.modal .header {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: -0.03em;
		line-height: 1.2em;
		background: #fff;
		padding: 0 15%;
		text-align: center;
		position: sticky;
		top: 0;
		z-index: 1;
	}
	.modal .tab {
		text-align: center;
		padding: 17px 0;
	}
	.modal .tab li {
		list-style: none;
		display: inline-block;
		font-size: 15px;
		font-weight: 500;
		color: #999;
		margin: 0 24px;
    cursor: pointer;
	}
	.modal .tab li.on {
		color: #222;
		border-bottom: 2px solid #01e1c9;
	}
  .modal .gallery_img {
    width: 100%;
    height: auto;
    padding-bottom: 4px;
  }
</style>
