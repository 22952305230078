<script>
	import SearchHotel from '@/pc/pages/SearchHotel'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import HotelContainer from '../components/HotelContainer'
  import DisplayContainer from '../components/DisplayContainer'
  export default {
    extends: SearchHotel,
    components: {
      PageLayout,
      SubPageLayout,
      DisplayContainer,
      HotelContainer
    }
  }
</script>

<style scoped>
  .ht_list_top {
    padding: 15px 20px;
    text-align: right;
  }
  .ht_list_top .num_hotels {
    font-size: 14px;
    color: #777;
    font-weight: 500;
    line-height: 1.6em;
    float: left;
    margin-top: 10px;
  }
  .ht_list_top .num_hotels b {
    font-size: 18px;
    color: #222;
    font-weight: 500;
    margin-right: 5px;
  }
  .ht_list_top .align {
    display: inline-block;
    font-size: 13px;
    color: #777;
    font-weight: 400;
    background: #fff;
    border: 1px solid #ddd;
    padding: 8px 15px;
    width: 30vw;
    max-width: 200px;
    text-align: left;
    position: relative;
  }
  .ht_list_top .align i {
    font-size: 12px;
    color: #222;
    font-weight: 900;
    transform: rotate(-45deg);
    display: inline-block;
    position: absolute;
    margin-top: -3px;
    right: 15px;
  }
  .ht_list_top .sort_review {
		display: inline-block;
		font-size: 11px;
		color: #777;
		font-weight: 400;
    letter-spacing: -0.05em;
		border: 1px solid #ccc;
    border-radius: 2.0em;
		padding: 5px 12px 5px 30px;
		text-align: center;
		position: relative;
    cursor: pointer;
	}
	.ht_list_top .sort_review.on {
		color: #00897a;
		border: 1px solid #00897a;
	}
	.ht_list_top .sort_review:before {
    content: "";
		position: absolute;
    top: 40%;
    left: 14px;
    display: inline-block;
    width: 6px;
    height: 10px;
    border: solid #aaa;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(0, -50%);
	}
	.ht_list_top .sort_review.on:before {
    border: solid #00897a;
    border-width: 0 2px 2px 0;
	}
  .hotel_ctnr {
    background: #fff;
    position: relative;
  }
  .hotel_ctnr .photo {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
  }
  .hotel_ctnr .photo .slider {
    width: calc((66vw * 5) + (5px * 4));
  }
  .hotel_ctnr .photo .slider .ctnr {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 66vw;
    height: 38vw;
  }
  .hotel_ctnr .photo .slider .ctnr img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .hotel_ctnr .photo .slider .ctnr:last-child {
    margin-right: 0;
  }
  .hotel_ctnr .photo .slider .ctnr .img_wrap {
    width: 100%;
    height: 38vw;
  }
  .hotel_ctnr .items {
    position: relative;
    padding: 25px 32.5px;
    margin: 0 auto 15px;
    display: block;
  }
  .hotel_ctnr .items a {
    position: absolute;
    top: 0;
    left: 0;
    width: 82%;
    height: 100%;
  }
  .hotel_ctnr .items .basic {
    display: inline-block;
    width: 100%;
  }
  .hotel_ctnr .items .basic .ht_class {
    font-size: 12px;
    color: #888;
    line-height: 1.0em;
    letter-spacing: -0.03em;
    position: absolute;
    margin-top: -45px;
    background: #fff;
    padding: 8px 12px 0;
    margin-left: -10px;
  }
  .hotel_ctnr .items .basic .ht_name_kr {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    width: 90%;
  }
  .hotel_ctnr .items .basic .ht_name_en {
    font-size: 14px;
    color: #222;
    font-weight: 400;
    margin-top: 6px;
    width: 90%;
  }
  .hotel_ctnr .items .basic .loc {
    font-size: 12px;
    color: #888;
    margin-top: 14px;
  }
  .hotel_ctnr .items .basic .loc i {
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url('/statics/img/ico_pin.png') no-repeat;
    background-size: 100% auto;
  }
  .hotel_ctnr .items .basic .price {
    font-size: 12px;
    color: #888;
    margin-top: 12px;
    text-align: right;
  }
  .hotel_ctnr .items .basic .price b {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    line-height: 1.2em;
    margin-left: 15px;
  }
  .hotel_ctnr .items .basic .price b:before {
    content: "￦";
    font-size: 13px;
  }
  .hotel_ctnr .items .badges {
    display: inline-block;
    position: absolute;
    left: 32.5px;
    bottom: 25px;
  }
  .hotel_ctnr .items .badges .badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    padding: 8px 0 5px;
    text-align: center;
    border-radius: 4px;
    width: 55px;
  }
  .hotel_ctnr .items .badges .badge.badge_rank {
    color: #222;
    background: #ebebeb;
  }
  .hotel_ctnr .items .badges .badge.badge_rank b {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: -0.5px;
  }
  .hotel_ctnr .items .badges .badge.badge_review {
    color: #00897a;
    background: #ccf9f4;
    margin-left: 5px;
  }
  .hotel_ctnr .items .badges .badge.badge_review p {
    display: inline-block;
    width: 100%;
    height: 13px;
    background: url('/statics/img/ico_pin_badge.png') no-repeat;
    background-size: 10px auto;
    background-position: center;
  }
  .hotel_ctnr .items .icons {
    position: absolute;
    top: 22px;
    right: 32.5px;
    text-align: right;
  }
  .hotel_ctnr .items .icons span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 25px;
  }
  .hotel_ctnr .items .icons span img {
    width: 100%;
    height: 100%;
  }
  .hotel_list .ftr_fixed {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    position: fixed;
    bottom: 60px;
    text-align: center;
    z-index: 1002;
    display: none;
  }
  .hotel_list .ftr_fixed .filter {
    color: #fff;
    font-weight: 500;
    display: inline-block;
    background: #0e6057;
    padding: 10px 15px 10px 40px;
    box-sizing: border-box;
    position: relative;
  }
  .hotel_list .ftr_fixed .filter:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 10px;
    position: absolute;
    top: 13px;
    left: 15px;
    background-image: url('/statics/img/ico_filter.png');
    background-size: 15px 10px;
  }
  .hotel_list .ftr_fixed .share {
    /*display: inline-block;*/
    display: none;
    width: 40px;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    right: 20px;
  }
  .hotel_list .ftr_fixed .share img {
    filter: brightness(10);
    width: 20px;
    margin-top: 9px;
  }
  .lst_ad_wrap {
    /*margin-top: 5px;*/
  }


  /* filter modal */
  .modal {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
  }
  .modal .header {
    height: 60px;
    font-size: 1.15em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .modal .header .btn_back {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
  }
  .modal .header .btn_back:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: solid #888;
    border-width: 0 0 1.5px 1.5px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .modal .header .btn_back.btn_close:after {
    display: none;
  }
  .modal .header .btn_back i {
    position: absolute;
    font-size: 20px;
    color: #888;
  }
  .modal .sect {
    padding: 25px 20px;
    border-bottom: 8px solid #eee;
  }
  .modal .sect .title {
    font-size: 1.15em;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  .modal .sect.distance .title {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .modal .sect .main_txt {
    font-size: 1.1em;
    letter-spacing: -0.02em;
  }
  .modal .sect .sub_txt {
    font-size: 0.9em;
    color: #888;
  }
  .modal .sect .slider_wrap {
    padding: 10px;
  }
  /deep/ .modal .sect .slider_wrap .el-slider__bar {
    background-color: #01e1c9;
  }
  /deep/ .modal .sect .slider_wrap .el-slider__button {
    border: 2px solid #01e1c9;
  }
  .modal .sect ul {
    text-align: center;
  }
  .modal .sect .tag {
    text-align: left;
  }
  .modal .sect .tag li {
    display: inline-block;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.03em;
    padding: 9px 15px;
    margin: 2.5px;
    border: 1px solid #ddd;
  }
  .modal .sect .tag li.on {
    color: #222;
    border: 1px solid #01e1c9;
    background: #01e1c9;
  }
  .modal .sect .hotel_class li {
    display: inline-block;
    font-size: 3.6em;
    font-weight: 100;
    color: #ddd;
    line-height: 1.0em;
    margin: 0 1.2vw;
    position: relative;
  }
  .modal .sect .hotel_class li i {
    font-size: 0.9rem;
    font-weight: 500;
    color: #222;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal .sect .hotel_class li.on {
    color: #01e1c9;
  }
  .modal .sect .hotel_class li.on i {
    color: #fff;
  }
  .modal .sect .hotel_review li {
    display: inline-block;
    font-size: 1.0em;
    font-weight: 500;
    color: #222;
    line-height: 1.0em;
    margin: 0 1.2vw;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 0.2em;
  }
  .modal .sect .hotel_review li.on {
    color: #fff;
    background: #01e1c9;
    border-color: #01e1c9;
  }
  .modal .sect .opt_lst {
    display: block;
    width: 100%;
    padding: 12px 0;
    position: relative;
  }
  .modal .sect.distance .opt_lst {
    margin-top: 30px;
  }
  .modal .sect .opt_lst span {
    display: inline-block;
    width: 50%;
    font-size: 1.1em;
    letter-spacing: -0.02em;
  }
  .modal .sect .opt_lst span:first-child {
    text-align: left;
  }
  .modal .sect .opt_lst span:last-child {
    text-align: right;
  }
  .modal .opt_other {
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
  }
  .modal .opt_other:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid #aaa;
    border-width: 1.5px 1.5px 0 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .modal .opt_other .title {
    margin-bottom: 0;
  }
  .modal .btm {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
  }
  .modal .btm.btm_fixed {

  }
  .modal .btm .apply {
    font-size: 1.1em;
    font-weight: 500;
    color: #fff;
    background: #00897a;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 14px;
  }
  .mdl_opt .header i {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    transform: rotate(45deg);
    display: inline-block;
    margin-left: 5px;
  }
  .mdl_opt input[type=text] {
    width: 100%;
    height: 50px;
    background: #f1f1f1;
    border: 0;
    margin-bottom: 10px;
    line-height: 50px;
    padding-left: 45px;
    color: #888;
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
  }
  .mdl_opt input[type=text].ipt_sch {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  ::placeholder {
    font-size: 13px;
    color: #888;
  }
  .sect .btn_wrap {
    text-align: center;
    margin-bottom: 10px;
  }
  .sect .btn_wrap .btn_select {
    display: inline-block;
    width: 48%;
    border: 1px solid #ccc;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    text-align: center;
    padding: 14px;
    margin: 1%;
  }
  .sect .btn_wrap .btn_select.on {
    border: 1px solid #01e1c9;
    color: #222;
    background: #01e1c9;
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
  }
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label
  {
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 0.2em;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #01e1c9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 0.1em;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 0.2em;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #01e1c9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 0.1em;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
</style>
