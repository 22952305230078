<script>
  import Home from 'src/pc/pages/Home'
	import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import HashTag from '../components/HashTag'
  export default {
    extends: Home,
    components: {
      PageLayout,
      DisplayContainer,
      HashTag
    }
  }
</script>


<style scoped>
  .box_main {
    background: #fff;
    padding: 35px 32.5px;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    color: #222;
  }
  .box_main.home_top {
    padding-top: 20px;
  }
  .home_top .image {
    width: calc(100vw - 65px);
    height: calc(100vw * 0.85);
    position: relative;
    padding: 25px;
  }
  .home_top .image:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9));
  }
  .home_top .image .date {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 1;
  }
  .home_top .image .title_wrap {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 1;
  }
  .home_top .image .title_wrap .tit {
    font-size: 27px;
    font-weight: 700;
  }
  .home_top .image .title_wrap .s_txt {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
  .main_sch {
    padding: 18px 32.5px;
  }
  .main_sch input[type=text] {
    width: 100%;
    height: 50px;
    background: #f1f1f1;
    border: 0;
    line-height: 50px;
    padding-left: 45px;
    color: #888;
  }
  .main_sch input[type=text] {
    background-size: 18px 18px;
    background-position: 16px center;
    background-repeat: no-repeat;
  }
  .main_sch input[type=text].ipt_sch {
    background-image: url('/statics/img/ico_sch_sch.png');
  }
  .main_sch .btn_confirm {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: #0e6057;
    display: block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .home_con .curtn_lst.curtn_hot {
    margin-top: 25px;
  }
  .home_con .curtn_lst .curtn {
    display: inline-block;
    padding: 7.5px 0;
  }
  .home_con .curtn_lst .curtn .img {
    display: table-cell;
  }
  .home_con .curtn_lst .curtn .img img {
    display: table-cell;
    width: calc(46vw - 40px);
  }
  .home_con .curtn_lst .curtn .name {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.35em;
    letter-spacing: -0.05em;
  }
  .home_con .curtn_lst .curtn .name_en {
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .box_main.section-2 .inner {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .box_main.section-2 .inner .display-container {
    margin: 0;
  }
  .box_main.section-2 .inner .display-container:first-child {
    width: 100%;
  }
  .box_main.section-2 .inner .display-container:nth-child(2) {
    width: calc(50% - 2.5px);
    margin-right: 2.5px;
    float: left;
  }
  .box_main.section-2 .inner .display-container:nth-child(3) {
    width: calc(50% - 2.5px);
    margin-left: 2.5px;
    float: right;
  }
  .box_main.section-2 .inner .display-container .container {
    width: 100%;
  }
  .box_main.section-2 .inner .display-container {
    margin-top: 5px;
  }
  .box_main.section-2 .inner .display-container .img_wrap {
    
  }
  .box_main.section-2 .inner .container:first-child .img_wrap {
    margin-top: 0;
  }
  .box_main.section-2 .display-container:nth-child(2) .con_tit .title,
  .box_main.section-2 .display-container:nth-child(3) .con_tit .title {
    font-size: 13px;
    font-weight: 500;
    word-break: break-all;
  }
  .box_main.section-2 .con_tit .cate {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    display: inline-block;
  }
  .box_main.section-2 .inner .con_tit .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .box_main .scroller {
    padding-right: 32.5px;
  }
  .box_main .video_player {
    position: relative;
    height: 0;
    padding-bottom: calc((100vw - 65px) * 0.5625);
  }
  .box_main .video_player iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .box_main .btn_more {
    /*margin-top: 10px;*/
  }
  .home_con .lst_company li {
    width: calc((100vw - 95px) / 3);
    display: inline-block;
    margin: 0 7.5px 22px;
    text-align: center;
  }
  .home_con .lst_company li:nth-child(3n+1) {
    margin-left: 0;
  }
  .home_con .lst_company li:nth-child(3n+3) {
    margin-right: 0;
  }
  .home_con .lst_company li .img_logo {
    width: 100%;
    height: calc((100vw - 95px) / 3);
    border: 1px solid #e5e5e5;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home_con .lst_company li .img_logo img {
    width: 80%;
  }
  .home_con .lst_company li .com_name {
    font-size: 13px;
    color: #222;
    letter-spacing: -0.05em;
    margin-top: 8px;
  }
  .home_con .footer {
    font-size: 11px;
    font-weight: 500;
    color: #aaa;
    margin-top: -8px;
    border-bottom: 0;
  }
  .home_con .footer .menu {
    margin-bottom: 20px;
  }
  .home_con .footer .menu a {
    font-size: 13px;
    font-weight: 600;
    color: #aaa;
    text-transform: uppercase;
    margin-top: -8px;
    margin-right: 20px;
  }
  .home_con .footer ul li {
    line-height: 1.8em;
    letter-spacing: -0.03em;
  }
  .home_con .footer ul li i {
    display: inline-block;
    margin: 0 5px;
    color: #ccc;
    font-weight: 400;
    line-height: 1.8em;
  }
  .home_con .footer ul li a {
    color: #aaa;
  }
  .home_con .footer ul li:last-child {
    margin-top: 6px;
  }
  .home_con .footer ul li a:nth-child(3) {
    font-weight: 700;
  }
  .home_con .footer .channel {
    margin-top: 30px;
  }
  .home_con .footer .channel a {
    display: inline-block;
    height: 34px;
    background-size: contain;
    margin-right: 30px;
    opacity: 0.3;
  }
  .home_con .footer .channel .youtube {
    background-image: url('/statics/img/sns_youtube.png');
    width: 30px;
  }
  .home_con .footer .channel .insta {
    background-image: url('/statics/img/sns_instagram.png');
    width: 28px;
  }
  .home_con .footer .channel .post {
    background-image: url('/statics/img/sns_n_post.png');
    width: 19px;
  }
  .home_con .footer .channel .brunch {
    background-image: url('/statics/img/sns_brunch.png');
    width: 24px;
  }
</style>
