<script>
	import ContentsDetail from 'src/pc/pages/ContentsDetail'
  import VHtmlWithHotelComponent from '../components/VHtmlWithHotelComponent'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import HashTag from '../components/HashTag'
  export default {
    extends: ContentsDetail,
    components: {
      SubPageLayout,
      PageLayout,
      DisplayContainer,
      VHtmlWithHotelComponent,
			HashTag
    }
  }
</script>


<style scoped>
  .post_detail {
    margin-top: -60px;
  }
  .con_detail .img_top {
    width: 100%;
    height: 250px;
    position: relative;
  }
  .con_detail .img_top:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
  }
  .con_detail .img_top .share {
    position: fixed;
    right: 20px;
    top: 18px;
    width: 24px;
    height: 24px;
    background: url('/statics/img/ico_share.png');
    background-size: 100% auto;
    cursor: pointer;
    z-index: 1003;
  }
  .detail_top .data {
    padding: 35px 32.5px;
    background: #fff;
  }
  .detail_top .cate {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    line-height: 12px;
  }
  .detail_top .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #ccc;
    line-height: 12px;
    display: inline-block;
  }
  .detail_top .subj {
    font-size: 20px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.04em;
    margin-top: 20px;
    line-height: 1.4em;
  }
  .detail_top .date_area {
    margin-top: 18px;
  }
  .detail_top .date_area:after {
    display: block;
    clear: both;
    content: "";
  }
  .detail_top .date_area i {
    float: left;
    line-height: 26px;
  }
  .detail_top .date_area .editor_img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
  }
  .detail_top .date_area .editor_img img {
    
  }
  .detail_top .date_area .editor {
    font-size: 12px;
    font-weight: 500;
    color: #222;
    margin-left: 10px;
  }
  .detail_top .date_area .date {
    font-size: 12px;
    color: #aaa;
    margin-left: 22px;
  }
  .detail_top .hv {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .detail_top .hv:after {
    display: block;
    clear: both;
    content: "";
  }
  .detail_top .h_tags {
    float: left;
    width: 100%;
    /*max-height: 80px;*/
    overflow: hidden;
  }
  .detail_top .h_tags:after {
    display: block;
    clear: both;
    content: "";
  }
  .detail_top .h_tags a {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: -0.03em;
    border: 1px solid #ddd;
    margin: 5px 5px 0 0;
    padding: 10px 14px;
    line-height: 1.0em;
    display: inline-block;
  }
  .detail_top .view {
    float: right;
    font-size: 13px;
    color: #ccc;
    font-style: italic;
  }
  .detail_top .view i {
    font-style: italic;
  }
  .detail_con {
    background: #fff;
    padding: 0 32.5px 25px;
    border-bottom: 8px solid #f2f2f2;
  }
  .con_view {
    font-size: 15px;
    font-weight: 300;
    color: #000;
    letter-spacing: -0.02em;
    line-height: 1.8em;
    word-break: break-all;
  }
  /deep/ .con_view img {
    width: 100vw !important;
    margin-left: -32.5px;
    margin-bottom: 15px;
  }
  /deep/ .con_view p, /deep/ .con_view span {
    font-family: 'Montserrat', 'Noto Sans KR', sans-serif !important;
  }
  /deep/ .con_view strong {
    font-weight: 400;
  }
  /deep/ .con_view a {
    color: inherit;
    font-weight: 400;
    border-bottom: 1.5px solid #01e1c9;
  }
  /deep/ .con_view .hotel_ctnr a, /deep/ .con_view .box_basic {
    border-bottom: 0;
  }
  /deep/ .con_view .hotel_ctnr {
    width: 100%;
    margin-top: 80px;
    background: #fff;
    position: relative;
  }
  /deep/ .con_view .hotel_ctnr .photo {
    width: 105px;
    height: 105px;
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    overflow: hidden;
    border-radius: 100%;
  }
  /deep/ .con_view .hotel_ctnr .photo .slider {
    display: block;
    width: 100%;
    height: 100%;
  }
  /deep/ .con_view .hotel_ctnr .photo .slider .ctnr {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
  }
  /deep/ .con_view .hotel_ctnr .photo .slider .ctnr img {
    width: 100% !important;
    margin-left: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    height: 100%;
    min-height: unset;
    visibility: visible;
  }
  /deep/ .con_view .hotel_ctnr .items {
    padding: 70px 20px 25px;
    text-align: center;
    background: #fff;
    border-radius: 0.25em;
    box-shadow: 0 2.5px 7.5px rgba(0,0,0,0.2);
  }
  /deep/ .con_view .hotel_ctnr .items a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /deep/ .con_view .hotel_ctnr .items .basic p {
    width: 100%;
    font-size: 12px;
    color: #888;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .ht_name_kr {
    word-break: keep-all;
    font-size: 16px;
    color: #222;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.03em;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .ht_name_en {
    font-size: 13px;
    margin-top: 5px;
    word-break: keep-all;
    line-height: 1.6em;
    color: #222;
    font-weight: 400;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .ht_class {
    display: none;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .loc {
    margin-top: 38px;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .loc em {
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;    
    background: url(/statics/img/ico_pin.png) no-repeat;
    background-size: 100% auto;
  }
  /deep/ .con_view .hotel_ctnr .items .basic .flo_rt {
    float: none;
    text-align: inherit;
    line-height: 1.4em;
    font-weight: 400;
  }
  /deep/ .con_view .hotel_ctnr .items .badges {
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: 15px;
  }
  /deep/ .con_view .hotel_ctnr .items .badges .badge {
    width: 70px;
  }
  /deep/ .con_view .hotel_ctnr .items .price {
    display: none;
  }
  /deep/ .con_view .hotel_ctnr .items .like {
    top: 20px;
    right: 15px;
  }
  /* 콘텐츠 내 스크롤 이미지 */
  /deep/ .con_view .box_con {
    padding: 0 0 40px 0;
    display: none;
  }
  /deep/ .con_view .box_con.size_S .inner_con .container {
    width: calc(39vw);
  }
  /deep/ .con_view .box_con.size_M .inner_con .container {
    width: calc(66vw);
  }
  /deep/ .con_view .box_con.size_L .inner_con .container {
    width: calc(100vw - 65px);
  }
  /deep/ .con_view .box_con.size_S .inner_con .container .img_wrap {
    height: calc(39vw);
  }
  /deep/ .con_view .box_con.size_M .inner_con .container .img_wrap {
    height: calc(66vw);
  }
  /deep/ .con_view .box_con.size_L .inner_con .container .img_wrap {
    height: calc(100vw - 65px);
  }
  /deep/ .con_view .box_con .title {
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.0em;
    letter-spacing: -0.03em;
    display: block;
  }
  /deep/ .con_view .box_con .inner_con {
    padding: 15px 0 0 0;
  }
  /deep/ .con_view .box_con .inner_con .container {
    margin-right: 10px;
  }
  /deep/ .con_view .box_con .inner_con .container:last-child {
    margin-right: 32.5px;
  }
  /deep/ .con_view .box_con .inner_con .container img {
    width: 100% !important;
    height: 100% !important;
    margin-left: 0;
  }
  /deep/ .con_view .box_con .inner_con .container span {
    display: inline-block;
    width: 100%;
    white-space: normal;
    margin-top: 12px;
    font-size: 0.80em;
    font-weight: 400;
    color: #777;
    letter-spacing: -0.04em;
    line-height: 1.4em;
    padding-right: 5%;
  }
  .post_hotel {
    margin-top: 60px;
  }
  .post_hotel .h_ctnr {
    height: 60px;
    padding: 18px 0 18px 80px;
    border-bottom: 1px solid #ddd;
    position: relative;
    box-sizing: content-box;
    display: block;
  }
  .post_hotel .h_ctnr:last-child {
    border-bottom: 0;
  }
  .post_hotel .h_ctnr .h_img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
  }
  .post_hotel .h_ctnr .h_name {
    font-size: 15px;
    font-weight: 500;
    color: #222;
    letter-spacing: -0.02em;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .post_hotel .h_ctnr .h_loc {
    font-size: 12px;
    color: #888;
    margin-top: 8px;
    line-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .post_hotel .h_ctnr .h_loc:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url(/statics/img/ico_pin.png) no-repeat;
    background-size: 100% auto;
  }
  .box_share {
    text-align: center;
  }
  .box_share a {
    margin: 0 5px;
  }
  .box_share .share_kakao {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url('/statics/img/share_kakao.png');
    background-size: 100% auto;
  }
  .box_share .share_url {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url('/statics/img/share_url.png');
    background-size: 100% auto;
  }
  .box_editor {
    text-align: center;
  }
  .box_editor .editor .img {
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .box_editor .editor .btn {
    display: inline-block;
    border: 1px solid #ddd;
    margin-top: 15px;
    padding: 10px 20px;
    border-radius: 3.0em;
    font-size: 13px;
    line-height: 1.6em;
  }
  .box_editor .editor .btn b {
    font-weight: 700;
  }
  .prev_next {
    display: inline-block;
    width: 100vw;
    margin-left: -32.5px;
    margin-top: 35px;
  }
  .prev_next .pn_lst li {
    width: 50%;
    display: inline-block;
  }
  .prev_next .pn_lst .in_box {
    display: block;
    position: relative;
    height: 36vw;
  }
  .prev_next .pn_lst .in_box .tit {
    position: absolute;
    top: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    padding: 6px 12px;
    border-radius: 3.0em;
    border: 1px solid rgba(255,255,255,0.4);
    z-index: 1;
  }
  .prev_next .prev .in_box .tit {
    left: 20px;
  }
  .prev_next .next .in_box .tit {
    right: 20px;
  }
  .prev_next .pn_lst .in_box .txt {
    position: absolute;
    bottom: 20px;
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.02em;
    display: inline-block;
    width: calc(100% - 40px);
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .prev_next .prev .in_box .txt {
    left: 20px;
    text-align: left;
  }
  .prev_next .next .in_box .txt {
    right: 20px;
    text-align: right;
  }
  .prev_next .pn_lst .in_box .subj .img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .prev_next .pn_lst .in_box .subj .img:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
  }
  .prev_next .pn_lst .in_box .subj .no_exist .img:after {
    background: rgba(0,0,0,0.4);
  }
  .box_ftrcon {
    margin-bottom: -10px;
    padding-bottom: 35px;
    border-bottom: 0;
  }
  .box_ftrcon .container {
    display: inline-block;
    width: 100%;
    margin-bottom: 4px;
  }
  .box_ftrcon .container:last-child {
    margin-bottom: 0;
  }
  .box_ftrcon .ctnr_dark .img_wrap {
    height: 44vw;
  }
  .box_ftrcon .ctnr_dark .img_wrap:after {
    background: rgba(0,0,0,0.4);
  }
  .box_ftrcon .ctnr_dark .con_tit {
    font-size: 15px;
    font-weight: 500;
    top: unset;
    bottom: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .con_btm_kakao {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }
  .tag_sns .share {
    float: right;
    right: 40px;
    top: 40px;
    width: 24px;
    height: 24px;
    background: url('/statics/img/icon_share.png') no-repeat;
    background-size: 100%;
    cursor: pointer;
    filter: brightness(20%);
  }
  .tag_sns .share:hover {
    opacity: 0.6;
  }
  .tag_sns {
    margin-top: 80px;
  }
  .tag_sns:after {
    display: block;
    clear: both;
    content: "";
  }
  .tag_sns .h_tags {
    float: left;
    position: relative;
    width: calc(100% - 110px);
    padding: 0 3px 0 40px;
  }
  .tag_sns .h_tags:after {
    display: block;
    clear: both;
    content: "";
  }
  .tag_sns .h_tags .tit {
    position: absolute;
    left: 0;
    top: 2px;
    width: 30px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    background: #ff6e61;
    border-radius: 2px;
  }
  .tag_sns .h_tags a {
    float: left;
    margin: 2px;
    font-size: 11px;
    color: #777;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    padding: 4px 10px;
    background: #f1f1f1;
  }
  .tag_sns .h_tags a:hover {
    color: #ff6e61;
    border: 1px solid #ff6e61;
  }
  .tag_sns .sns {
    float: right;
    opacity: 1;
  }
  .tag_sns .sns li {
    float: left;
    margin-left: 5px;
  }
  .tag_sns .sns a {
    display: block;
    overflow: hidden;
    word-break: keep-all;
    width: 30px;
    height: 30px;
    font-size: 0;
    text-indent: -9999px;
    border-radius: 100%;
    transition: all 0.15s;
  }
  .tag_sns .sns a:hover {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
  }
  .tag_sns .sns .facebook {
    background: url('/statics/img/icon_facebook.png');
  }
  .tag_sns .sns .twitter {
    background: url('/statics/img/icon_twitter.png');
  }
  .tag_sns .sns .kakaostory {
    background: url('/statics/img/icon_kakaostory.png');
  }
  .info {
    margin-top: 16px;
  }
  .info li {
    line-height: 24px;
    font-size: 14px;
    color: #777;
  }
  .list_btn {
    display: block;
    text-align: center;
    width: 200px;
    height: 50px;
    line-height: 47px;
    margin: 32px auto 0;
    font-size: 18px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    transition: all 0.15s;
  }
  .list_btn:hover {
    background-color: #fafafa;
  }
  .list_btn .icon {
    display: inline-block;
    width: 15px;
    height: 14px;
    margin: 17px 10px 0 0;
    background: url('/statics/img/icon_list.png');
  }
  .ad_area {
    float: right;
    width: 340px;
  }
  .ad_area.sticky_con {
    position: sticky;
    left: 880px;
    top: 0;
  }
  .ad_area .mt30 {
    margin-top: 30px;
  }
  .ad_area .ad_lst {
    margin-top: 30px;
    padding: 20px 20px 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  .ad_area .ad_lst h4 {
    height: 22px;
    line-height: 22px;
  }
  .ad_area .ad_lst h4:before {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 2px 8px 0 0;
    text-align: center;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    background: #243745;
    border-radius: 100%;
    content: "";
  }
  .ad_area .ad_lst h4 b {
    margin-right: 10px;
    font-family: "Oswald", 'Malgun Gothic', 'Dotum', sans-serif;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    letter-spacing: -0.8px;
  }
  .ad_area .ad_lst h4 i {
    font-size: 16px;
    color: #666;
    font-style: italic;
    font-weight: 500;
    letter-spacing: -0.8px;
  }
  .ad_area .editor_pick h4:before {
    content: "P";
  }
  .ad_area .editor_pick .lst li {
    margin-top: 28px;
  }
  .ad_area .editor_pick .lst li:first-child {
    margin-top: 20px;
  }
  .ad_area .editor_pick .lst .s_tit {
    line-height: 20px;
    margin-top: 13px;
    font-size: 14px;
    color: #777;
  }
  .ad_area .editor_pick .lst .tit {
    line-height: 24px;
    margin-top: 4px;
    font-size: 18px;
    color: #333;
    letter-spacing: -0.5px;
    font-weight: 600;
  }
  .ad_area .editor_pick .lst a:hover .tit {
    text-decoration: underline;
  }
  .ad_area .popular_contents h4:before {
    content: "C";
  }
  .ad_area .popular_contents .lst {
    margin-top: 2px;
  }
  .ad_area .popular_contents .lst li {
    border-bottom: 1px solid #ddd;
  }
  .ad_area .popular_contents .lst a {
    position: relative;
    display: table;
    width: 100%;
    height: 140px;
    padding: 20px 0 20px 120px;
  }
  .ad_area .popular_contents .lst a .img {
    position: absolute;
    left: 0;
    top: 20px;
  }
  .ad_area .popular_contents .lst a .txt {
    display: table-cell;
    vertical-align: middle;
  }
  .ad_area .popular_contents .lst a .s_tit {
    line-height: 20px;
    font-size: 13px;
    color: #777;
  }
  .ad_area .popular_contents .lst a .tit {
    line-height: 20px;
    margin-top: 5px;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.3px;
    font-weight: 500;
  }
  .ad_area .popular_contents .lst a:hover .tit {
    text-decoration: underline;
  }

  /deep/ img[name^="target_resize_image[]"] {
    margin: 0 auto;
    display: block;
    max-width: 760px;
  }

  .wrong {
    background-color: #EEE;
  }
  .wrong:after {
    content: "카드를 연결 할 수 없습니다.";
    display: flex;
    align-items: center;
  }

  .share_pop {
    position: relative;
    width: unset !important;
    height: unset !important;
    max-width: 360px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 45px 25px 35px;
  }
  .share_pop .close_btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    background: url(/statics/img/close_01.png);
    background-size: 100%;
  }
  .share_pop ul li {
    position: relative;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    width: 25%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .share_pop ul li:nth-child(5), .share_pop ul li:nth-child(6), .share_pop ul li:nth-child(7), .share_pop ul li:nth-child(8) {
    margin-bottom: 0;
  }
  .share_pop ul li:hover i {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .share_pop ul li i {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-position: center;
    border-radius: 5%;
    margin-bottom: 5px;
  }
  .share_pop ul li.kakaotalk i {
    background-image:  url('/statics/img/icon_share_kakaotalk.png');
  }
  .share_pop ul li.kakaostory i {
    background-image:  url('/statics/img/icon_share_kakaostory.png');
  }
  .share_pop ul li.facebook i {
    background-image:  url('/statics/img/icon_share_facebook.png');
  }
  .share_pop ul li.twitter i {
    background-image:  url('/statics/img/icon_share_twitter.png');
  }
  .share_pop ul li.naver i {
    background-image:  url('/statics/img/icon_share_naver.png');
  }
  .share_pop ul li.band i {
    background-image:  url('/statics/img/icon_share_band.png');
  }
  .share_pop ul li.line i {
    background-image:  url('/statics/img/icon_share_line.png');
  }
  .share_pop ul li.link i {
    background-image:  url('/statics/img/icon_share_link.png');
  }
  #shareUrl {
    position: absolute;
    top: -9999px
  }
  .share_pop ul li {
    letter-spacing: -0.05em;
  }
  /* 객원에디터 서명 */
  /deep/ .contri-editor {
    background: linear-gradient(to right, #d4e9e7, #f2f2f2);
    padding: 70px 0 30px;
    margin-top: 150px;
    text-align: center;
    position: relative;
  }
  /deep/ .contri-editor ul {
    display: inline-block;
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(82px, 128px) rotate(-68deg);
  }
  /deep/ .contri-editor ul li {
    display: inline-block;
    width: 10px;
    height: 180px;
    font-size: 18px;
    font-weight: 300;
    color: #52958f;
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1.0em;
  }
  /deep/ .contri-editor ul li:nth-child(1) {
    transform: rotate(1deg);
  }
  /deep/ .contri-editor ul li:nth-child(2) {
    transform: rotate(10deg);
  }
  /deep/ .contri-editor ul li:nth-child(3) {
    transform: rotate(19deg);
  }
  /deep/ .contri-editor ul li:nth-child(4) {
    transform: rotate(26.5deg);
  }
  /deep/ .contri-editor ul li:nth-child(5) {
    transform: rotate(33deg);
  }
  /deep/ .contri-editor ul li:nth-child(6) {
    transform: rotate(39deg);
  }
  /deep/ .contri-editor ul li:nth-child(7) {
    transform: rotate(46deg);
  }
  /deep/ .contri-editor ul li:nth-child(8) {
    transform: rotate(55deg);
  }
  /deep/ .contri-editor ul li:nth-child(9) {
    transform: rotate(63deg);
  }
  /deep/ .contri-editor ul li:nth-child(10) {
    transform: rotate(69deg);
  }
  /deep/ .contri-editor ul li:nth-child(11) {
    transform: rotate(76deg);
  }
  /deep/ .contri-editor ul li:nth-child(12) {
    transform: rotate(85deg);
  }
  /deep/ .contri-editor ul li:nth-child(13) {
    transform: rotate(98deg);
  }
  /deep/ .contri-editor ul li:nth-child(14) {
    transform: rotate(107deg);
  }
  /deep/ .contri-editor ul li:nth-child(15) {
    transform: rotate(114deg);
  }
  /deep/ .contri-editor ul li:nth-child(16) {
    transform: rotate(120deg);
  }
  /deep/ .contri-editor ul li:nth-child(17) {
    transform: rotate(128deg);
  }
  /deep/ .contri-editor ul li:nth-child(18) {
    transform: rotate(136deg);
  }
  /deep/ .contri-editor .prof {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 5.0em;
    overflow: hidden;
    position: absolute;
    top: -50px;
    transform: translate(-50%, 0);
  }
  /deep/ .contri-editor .prof img {
    width: 100% !important;
    margin: 0;
  }
  /deep/ .contri-editor .nick {
    font-size: 20px !important;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
  }
  /deep/ .contri-editor .mail {
    font-size: 14px !important;
    font-weight: 300;
    color: #000;
    letter-spacing: 0.0em;
    margin-top: 3px;
  }
  .post_detail .banner_gosty {
    background: none;
    border: 0;
    padding-bottom: 50px;
  }
  .post_detail .banner_gosty .mo img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
</style>


<style>
  .con_area .fr-video {
    display: block !important;
    position: relative !important;
    height: 0 !important;
    padding-bottom: 56.25vw !important;
    margin-left: -32.5px;
    margin-right: -32.5px;
  }
  .con_area .fr-video iframe {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
  }
</style>