<script>
	import HotelDetail from 'src/pc/pages/HotelDetail'
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import AddWishList from '../components/AddWishList'
	import HashTag from '../components/HashTag'
  import Consts from 'src/_mixins/Consts'
  export default {
    extends: HotelDetail,
    components: {
      PageLayout,
      DisplayContainer,
      AddWishList,
			HashTag,
    },
    computed: {
      getAccess() {
        return 'app'
      }
    }
  }
</script>

<style scoped>
  .map {
    width: 100%;
    height: 100%;
  }
  .modal_btn_close {
    position: fixed;
    padding: 20px 20px;
    z-index: 100;
  }

  .basic_info {
    background: ;
  }
  .basic_info .photo {
    width: 100%;
    height: 280px;
    position: relative;
    overflow: hidden;
  }
  .basic_info .photo .q-carousel {
    height: 280px;
  }
  .basic_info .photo .num {
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0,0.5);
    font-size: 10px;
    color: #fff;
    padding: 8px 15px;
  }
  .basic_info .photo .ico_photo {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 22px;
    height: 17px;
    background: url('/statics/img/ico_photo.png');
    background-size: 22px auto;
  }
  .basic_info .items {
    position: relative;
    padding: 30px 32.5px;
    border-bottom: 8px solid #f2f2f2 !important;
  }
  .basic_info .items .basic {
    display: inline-block;
    width: 76%;
  }
  .basic_info .items .basic .ht_class {
    font-size: 12px;
    color: #888;
    line-height: 1.0em;
    letter-spacing: -0.03em;
    position: absolute;
    margin-top: -50px;
    background: #fff;
    padding: 8px 12px 0;
    margin-left: -10px;
  }
  .basic_info .items .basic .ht_name_kr {
    font-size: 20px;
    color: #222;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.03em;
  }
  .basic_info .items .basic .ht_name_en {
    font-size: 16px;
    color: #222;
    font-weight: 300;
    margin-top: 6px;
  }
	.basic_info .items .basic .ht_name_brand {
		font-size: 13px;
		color: #0e6057;
		font-weight: 400;
		margin-top: 8px;
    font-style: italic;
    opacity: 0.75;
	}
  .basic_info .items .basic .loc {
    font-size: 12px;
    color: #888;
    margin-top: 18px;
  }
  .basic_info .items .basic .loc i {
    display: inline-block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background: url('/statics/img/ico_pin.png') no-repeat;
    background-size: 100% auto;
  }
  .basic_info .items .basic .price {
    font-size: 12px;
    color: #888;
    margin-top: 32px;
  }
  .basic_info .items .basic .price b {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    line-height: 1.2em;
    margin-left: 15px;
  }
  .basic_info .items .basic .price b:before {
    content: "￦";
    font-size: 13px;
  }
  .basic_info .items .badges {
    display: inline-block;
    width: 24%;
    text-align: right;
  }
  .basic_info .items .badges .badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    padding: 8px 0 5px;
    text-align: center;
    border-radius: 4px;
    width: 55px;
  }
  .basic_info .items .badges .badge.badge_rank {
    color: #222;
    background: #ebebeb;
    margin-bottom: 5px;
  }
  .basic_info .items .badges .badge.badge_rank b {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: -0.5px;
  }
  .basic_info .items .badges .badge.badge_review {
    color: #00897a;
    background: #ccf9f4;
  }
  .basic_info .items .badges .badge.badge_review p {
    display: inline-block;
    width: 100%;
    height: 13px;
    background: url('/statics/img/ico_pin_badge.png') no-repeat;
    background-size: 10px auto;
    background-position: center;
  }
  .basic_info .items .btn_reserv {
    display: inline-block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #0e6057;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-top: 24px;
	}
  .basic_info .items .icons {
    position: absolute;
    width: 50px;
    height: 24px;
    bottom: 30px;
    right: 32.5px;
    text-align: right;
  }
  .basic_info .items .icons span {
    /*display: inline-block;*/
    display: none;
    width: 24px;
    height: 24px;
    margin-left: 25px;
  }
  .basic_info .items .icons .like {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    margin: 0;
    right: 0;
  }
  .basic_info .items .icons .on:before {
    content: "♥";
    font-size: 25px;
    line-height: 1.0em;
    color: #01e1c9;
  }
  .basic_info .items .icons .off:before {
    content: "♡";
    font-size: 25px;
    line-height: 1.0em;
    color: #ccc;
    font-weight: 900;
  }
  .basic_info .items .icons span img {
    width: 100%;
    height: 100%;
    filter: brightness(0.8);
  }
  .sub_nav_bar {
    padding: 17px 0;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    border: solid #f2f2f2;
    border-width: 1px 0 1px 0;
    background: #fff;
    z-index: 1;
    display: block;
  }
  .sub_nav_bar span {
    font-size: 15px;
    font-weight: 700;
    margin: 0 22px;
  }
  .sub_nav_bar span.on {
    border-bottom: 2px solid #01e1c9;
  }
  .sub_nav_bar span a.current {
    border-bottom: 2px solid #01e1c9;
  }
  .sub_nav_bar_wrapper .box_basic {
    border-bottom: 8px solid #f2f2f2 !important;
  }
  .con_intro .tag {
    margin-bottom: 35px;
  }
  .con_intro .tag li {
    display: inline-block;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.03em;
    padding: 9px 15px;
    margin: 2.5px;
    border: 1px solid #ddd;
  }
  .box_basic .con_recomm {
    margin-bottom: 35px;
  }
  .box_basic .con_recomm:last-child {
    margin-bottom: 0;
  }
  .box_basic .scroller {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
    margin-left: -32.5px;
    padding-left: 32.5px;
    padding-bottom: 15px;
    margin-bottom: -15px;
  }
  .box_basic .scroller .inner {
    max-width: unset !important;
    padding: 0;
  }
  .box_basic .scroller .inner .container {
    margin-right: 15px;
  }
  .box_basic .scroller .inner .container:last-child {
    margin-right: 32.5px;
  }
  .rating ul {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .rating ul li {
    width: 36%;
  }
  .rating ul li:first-child {
    text-align: right;
  }
  .rating ul li:first-child img {
    margin-top: 2px;
    height: 12px;
  }
  .rating ul li.rat_num {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    width: 28%;
  }
  .rating ul li:last-child {
    text-align: left;
    font-weight: 300;
  }
  .rating li.star {
    line-height: 1.0em;
  }
  .rating li.star i {
    display: inline-block;
    width: 13px;
    height: 12px;
    background-image: url('/statics/img/rating.png');
    background-size: 53px auto;
    background-repeat: no-repeat;
    margin-left: 3.5px;
  }
  .rating li.star i.full {
    background-position: 0 0;
  }
  .rating li.star i.half {
    background-position: -20px 0;
  }
  .rating li.star i.empty {
    background-position: -40px 0;
  }
  .con_reserv .status {
    color: #222;
    font-weight: 500;
    padding: 20px 15px;
    background: #f6f6f6;
    text-align: center;
  }
  .con_reserv .status .btn {
    color: #fff;
    font-weight: 500;
    padding: 10px 15px;
    background: #00897a;
    margin-left: 15px;
    letter-spacing: -0.05em;
  }
  .con_reserv .ota_list {
    margin-top: 20px;
  }
  .con_reserv .ota_list p {
    text-align: right;
    font-size: 12px;
    color: #888;
    margin-bottom: -5px;
  }
  .con_reserv .ota_list .ota_con {
    min-height: 60px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    line-height: 1.2em;
    word-break: break-all;
    padding: 10px 0;
  }
  .con_reserv .ota_list .ota_con li:first-child {
    width: 27%;
    color: #888;
    font-size: 12px;
  }
  .con_reserv .ota_list .ota_con li:nth-child(2) {
    width: 45%;
    color: #222;
    font-size: 12px;
    line-height: 1.4em;
    max-height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .con_reserv .ota_list .ota_con li:last-child {
    width: 28%;
    font-size: 13px;
    color: #222;
    font-weight: 500;
    text-align: right;
  }
  .con_reserv .ota_list .ota_con li:last-child:before {
    content: "￦";
    font-size: 12px;
  }
  .con_reserv .btn_more {
    display: block;
    color: #222;
    border: 1px solid #aaa;
    text-align: center;
    padding: 15px;
    margin-top: 20px;
    position: relative;
  }
  .con_reserv .btn_more i {
    font-size: 14px;
    color: #222;
    font-weight: 900;
    transform: rotate(225deg);
    display: inline-block;
    position: absolute;
    right: 8.5%;
  }
  .con_reserv .info_txt {
    color: #222;
    margin-top: 30px;
  }
  .con_reserv .info_txt i {
    float: right;
    font-size: 30px;
    color: #aaa;
    line-height: 0.75em;
  }
  .con_reserv .info_con {
    font-size: 12px;
    color: #888;
    line-height: 1.8em;
    margin-top: 15px;
  }
  .con_info {
    border-bottom: 8px solid #f2f2f2 !important;
  }
  .con_info dl {
    margin-bottom: 30px;
  }
  .con_info dl:last-child {
    margin-bottom: 0;
  }
  .con_info dd {
    color: #888;
    letter-spacing: -0.02em;
    line-height: 1.8em;
    word-break: break-all;
    margin-top: -5px;
  }
  .con_info dd.more {
    float: right;
    color: #222;
  }
  .con_info dd i {
    display: inline-block;
    width: 50%;
  }
  .con_info dd.location {
    color: #222;
    margin-top: 10px;
  }
  .share {
    text-align: center;
  }
  .share a {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
  }
  .share a img {
    width: 100%;
  }
  .ctnr_s .name_kr {
    font-size: 15px;
    color: #222;
    font-weight: 500;
    margin-top: 10px;
    letter-spacing: -0.03em;
  }
  .ctnr_s .name_en {
    font-size: 13px;
    color: #222;
  }
  .skeleton {
    text-align: center;
    text-color: white;
    font-size: 8px;
    padding: 5px 10px;
  }
  .modal {
    position: absolute;
  }
  .modal .header {
    font-size: 1.10em;
    font-weight: 300;
    letter-spacing: -0.03em;
    line-height: 1.2em;
    background: #fff;
    padding: 0 15%;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .modal .tab {
    text-align: center;
    padding: 17px 0;
  }
  .modal .tab li {
    list-style: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: #999;
    margin: 0 24px;
  }
  .modal .tab li.on {
    color: #222;
    border-bottom: 2px solid #01e1c9;
  }
  .modal .gallery_img {
    width: 100%;
    height: auto;
    padding-bottom: 4px;
  }
</style>
