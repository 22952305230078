<script>
	import Contents from 'src/pc/pages/Contents'
  import PageLayout from '../layouts/PageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import PostContainer from '../components/PostContainer'
  export default {
    extends: Contents,
    components: {
      PageLayout,
      DisplayContainer,
      PostContainer
    }
  }
</script>

<style scoped>
  .conmain_cate {
    background: #fff;
    padding: 25px 0 0;
    border-top: 1.5px solid #eee;
    text-align: center;
  }
  .conmain_cate a {
    font-size: 4.0vw;
    font-weight: 500;
    color: #888;
    margin: 0 4.2vw;
  }
  .conmain_cate a.on {
    font-weight: 700;
    color: #222;
  }
  .box_basic.conmain {
    padding-top: 25px;
  }
  .conmain .container .img_wrap {
    height: 400px;
  }
  .conmain .scroller .inner .container .con_tit {
    left: 28px;
    right: 28px;
    bottom: 28px;
  }
  .conmain .container .con_tit .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.6em;
    letter-spacing: -0.03em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .conmain .container .con_tit .cate {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 14px;
  }
  .conmain .container .con_tit .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid rgba(255,255,255,0.5);
    line-height: 12px;
    display: none;
  }
  .box_basic.conmain_mid {
    padding-bottom: 0;
  }
  .conmain_mid .layout_wrap {
    margin-left: -32.5px;
    width: 100vw;
  }
  .conmain_mid .layout_wrap .container {
    display: inline-block;
    position: relative;
  }
  .conmain_mid .layout_wrap .container.ctnr_l {
    width: 100%;
  }
  .conmain_mid .layout_wrap .container.ctnr_s {
    width: calc(50vw - 2px);
    margin-top: 4px;
  }
  .conmain_mid .layout_wrap .container.ctnr_s:nth-child(odd) {
    margin-left: 2px;
  }
  .conmain_mid .layout_wrap .container.ctnr_s:nth-child(even) {
    margin-right: 2px;
  }
  .conmain_mid .layout_wrap .container .img_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .conmain_mid .layout_wrap .container.ctnr_l .img_wrap {
    height: 54vw;
  }
  .conmain_mid .layout_wrap .container.ctnr_s .img_wrap {
    height: 40vw;
  }
  .conmain_mid .layout_wrap .container.ctnr_dark .img_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
  }
  .conmain_mid .layout_wrap .container.ctnr_dark .con_tit {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .conmain_mid .layout_wrap .container.ctnr_l .con_tit {
    font-size: 15px;
  }
  .conmain_mid .layout_wrap .container.ctnr_l .con_tit i {
    display: none;
  }
  .post_lst .lst_cate {
    margin-bottom: 0;
  }
  .post_lst .search_area .sch_box {
    padding: 0 32.5px 25px;
    margin-bottom: 1.5px;
    border-bottom: 8px solid #f2f2f2;
  }
  .post_lst .search_area .sch_box input[type="text"] {
    width: 100%;
    height: 36px;
    border: 1px solid #ccc;
    padding-left: 10px;
  }
  .post_lst .lst_ctnr {
    padding: 25px 32.5px;
  }
  .post_lst .lst_ctnr .img_wrap {
    height: 160px;
  }
  .post_lst .lst_ctnr .cate {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 20px;
  }
  .post_lst .lst_ctnr .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #888;
    line-height: 12px;
    display: inline-block;
  }
  .post_lst .lst_ctnr .subj {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    margin-top: 15px;
    word-break: break-all;
  }
  .post_lst .lst_ctnr .data {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    margin-top: 12px;
  }
  .post_lst .lst_ctnr .data .date {
    color: #aaa;
    margin-right: 20px;
  }
  .post_lst .lst_ctnr .data .writer i {
    color: #aaa;
  }
  .post_lst .lst_ctnr .h_tags {
    margin-top: 12px;
    height: 44px;
    overflow: hidden;
  }
  .post_lst .lst_ctnr .h_tags a {
    font-size: 12px;
    font-weight: 500;
    color: #888;
    letter-spacing: -0.03em;
    padding: 10px 14px;
    border: 1px solid #ddd;
    margin: 5px 5px 0 0;
    display: inline-block;
  }
</style>