import { render, staticRenderFns } from "./ContentsEditor.vue?vue&type=template&id=648cb8bc&scoped=true&"
import script from "./ContentsEditor.vue?vue&type=script&lang=js&"
export * from "./ContentsEditor.vue?vue&type=script&lang=js&"
import style0 from "./ContentsEditor.vue?vue&type=style&index=0&id=648cb8bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "648cb8bc",
  null
  
)

export default component.exports