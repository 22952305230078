<script>
  import AddWishList from 'src/pc/components/AddWishList'
  export default {
    extends: AddWishList
  }
</script>

<style scoped>
  .like {
    position: absolute;
    margin-top: -5px;
    right: 25px;
    display: inline-block;
    width: 35px;
    height: 35px;
    visibility: hidden;
  }
  .like.on:before {
    content: "♥";
    font-size: 25px;
    line-height: 1.0em;
    color: #01e1c9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .like:before {
    content: "♡";
    font-size: 25px;
    line-height: 1.0em;
    color: #ccc;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>